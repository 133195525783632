<template>
  <div class="page-header">
    Уведомления
  </div>
  <div class="content">
      <div v-if="$store.state.notifications.notifications.length > 0" class="notification-list">
        <div v-for="notify in $store.state.notifications.notifications" :key="notify.id" class="notify" :class="{new: notify.read_at == null}">
          <div class="notify-title">{{ notify.title }}</div>
          <div class="notify-text" v-html="notify.message" />
          <div class="notify-date">{{ notify.created_at }}</div>
          <div class="close-icon" @click="deleteNotification(notify)" />
        </div>
      </div>
    <div v-else class="notify-no-content">
      У Вас нет уведомлений
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationPage",
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    deleteNotification(notify) {
      this.$store.dispatch('NOTIFICATIONS_DELETE', notify.id);
    },
  }
}
</script>

<style scoped>
.close-icon:hover {
  opacity: 1;
}
.close-icon {
   cursor: pointer;
  opacity: .6;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.02638 10.756L4.79689 7.36501L1.56837 10.756C1.39635 10.9325 1.16437 11.0301 0.923842 11.0287C0.683318 11.0273 0.453362 10.9263 0.283217 10.7478C0.113072 10.5693 0.0171601 10.3277 0.0156392 10.0751C0.0141184 9.82257 0.107423 9.58014 0.275405 9.39938L3.50392 6.00842L0.275405 2.61848C0.18891 2.52972 0.119512 2.42366 0.0722799 2.30676C0.0250476 2.18985 0.000685404 2.06358 1.42519e-05 1.93659C-0.0006569 1.80961 0.02238 1.68387 0.0683736 1.56643C0.114367 1.44898 0.182041 1.34242 0.267592 1.25266C0.353144 1.1629 0.454531 1.09168 0.56642 1.04348C0.67831 0.995274 0.798997 0.970896 0.919936 0.971699C1.04087 0.972502 1.1602 0.998919 1.2715 1.0486C1.3828 1.09829 1.48391 1.171 1.56837 1.26188L4.79689 4.65183L8.02638 1.26188C8.1984 1.08536 8.42941 0.986704 8.66993 0.988105C8.91046 0.989507 9.14041 1.09055 9.31056 1.26906C9.4807 1.44758 9.57759 1.68917 9.57911 1.94172C9.58064 2.19427 9.48733 2.43772 9.31935 2.61848L6.08986 6.00842L9.31935 9.39938C9.48733 9.58014 9.58064 9.82257 9.57911 10.0751C9.57759 10.3277 9.4807 10.5693 9.31056 10.7478C9.14041 10.9263 8.91046 11.0273 8.66993 11.0287C8.42941 11.0301 8.1984 10.9325 8.02638 10.756Z' fill='%23CCCCCC'/%3E%3C/svg%3E%0A");
  width: 10px;
  height: 12px;
  position: absolute;
  right: 20px;
  top: 20px;
}
.notify-title {
  font-size: 20px;
  color: #00cccc;
  padding-bottom: 5px;
}
.notify-text {
  font-size: 18px;
}
.notify-date {
  font-size: 14px;
  color: #8f8f8f;
  font-weight: 600;
}
.notification-list {
  background-color: white;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0px 3px 9px rgb(0 0 0 / 13%);
}
.notify-date {
  padding-bottom: 5px;
  color: #1CC8AB;
}
.notify.new {
  border: 1px solid #00cccc;
}
.notify {
  position: relative;
  background: #f5f5f5;
  border: 1px solid transparent;
  padding: 15px;
  border-left: 5px;
  margin-right: 5px;
  border-radius: 6px;
  margin-bottom: 10px;
}
</style>