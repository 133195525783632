<template>
  <NConfigProvider :theme="theme" :theme-overrides="themeOverrides" :locale="ruRU" :date-locale="dateRuRU">
    <NDialogProvider>
      <NNotificationProvider>
        <NMessageProvider>
          <DeviceProvider>
            <Component :is="layout">
              <RouterView />
              <AlertManager />
            </Component>
          </DeviceProvider>
        </NMessageProvider>
      </NNotificationProvider>
    </NDialogProvider>
  </NConfigProvider>
</template>

<script>
import { NDialogProvider, NNotificationProvider, NMessageProvider, NConfigProvider, ruRU, dateRuRU } from 'naive-ui'
import GuestLayout from '@/layouts/GuestLayout'
import UserLayout from '@/layouts/UserLayout'
import AlertManager from '@/components/widgets/custom-components/alertManager/alertManager'
import lightThemeOverrides from '@/theme/lightThemeOverrides'
import darkThemeOverrides from '@/theme/darkThemeOverrides'
import DeviceProvider from '@/components/providers/DeviceProvider.vue'

export default {
  components: {
    GuestLayout,
    UserLayout,
    AlertManager,
    NDialogProvider,
    NNotificationProvider,
    NMessageProvider,
    NConfigProvider,
    DeviceProvider
  },
  data() {
    return {
      theme: null,
      ruRU,
      dateRuRU
    }
  },
  computed: {
    themeOverrides() {
      return this.theme === null ? lightThemeOverrides : darkThemeOverrides
    },
    layout() {
      //Вернем имя шаблона из роута или дефолтное значение
      //(шаблон для страниц, для которых мы не указали шаблон)
      return this.$route.meta.layout || GuestLayout
    }
  },
  mounted() {
    if (localStorage.getItem('user')) {
      if (!this.$store.getters.currentUser.id) {
        this.$store.dispatch('USER_GET')
      }
    }
  }
}
</script>

<style>
.disabled-by-tarif {
  pointer-events: none;
}
</style>
