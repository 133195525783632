<template>
  <div class="card card-body overflow-auto mt-4">
    <h2 class="text-center">Статистические показатели</h2>
    <h4 class="card-title mr-5 mb-4">Показатели по разделам ВКК</h4>

    <!-- <ParametersCharts
      :is-loading="isLoading"
      :charts-list="groupedParametersChartsList"
      :button-click="(chart) => openModal(chart)"
      grouped
    /> -->
    <div>
      <div v-if="isLoading" class="text-center pt-5">
        <p>Загрузка...</p>
        <NSpin size="large" />
      </div>

      <div v-if="!isLoading && groupedParametersChartsList?.length === 0" class="text-center pt-3">
        <p>Нет данных</p>
      </div>

      <div v-if="!isLoading && groupedParametersChartsList?.length">
        <div v-for="chart in groupedParametersChartsList" :key="chart.id" ref="chart" class="mb-5">
          <!-- <div v-if="enableDataEnter" class="flex justify-content-end position-relative">
            <NButton class="position-absolute" style="z-index: 10" size="small" @click="() => buttonClick(chart)">
              Внести данные
            </NButton>
          </div> -->

          <h6 class="text-center mb-3">
            <!-- <RouterLink :to="`/process/${chart.process_id}?indicators=${chart.id}`"> -->
            <span>{{ `${chart.name}${getSuffix(chart.input_type)} ` }}</span>
            <span class="text-decoration-underline">{{ `${chart.term_name} ` }}</span>
            <!-- </RouterLink> -->
          </h6>

          <div class="flex flex-column">
            <div style="width: 100%">
              <apexchart height="280px" :options="chart.options" :series="chart.series" />
            </div>
            <div style="width: 100%" class="flex pt-3 pl-3">
              <!-- <div class="column">
                <div class="column-row">{{ chart.target_criteria }}</div>
                <div v-for="q in quarter" :key="q" class="column-row">{{ q }}</div>
              </div>
              <div v-for="obj in chart.series" :key="obj" class="column">
                <div style="font-weight: 700;" class="column-row">{{ obj.name }}</div>
                <div 
                  v-for="value in obj.data" 
                  :key="value" 
                  :class="{ 'accent-by-target-criteria': isAccentByTargetCriteria(chart.target_criteria, value) }"
                  class="flex column-row" 
                >
                  {{ value }}
                </div>
              </div> -->
              <table style="border-collapse: collapse; border: 1px solid #ccc" border="1">
                <thead>
                  <tr style="border-bottom: 1px solid #ccc; border-top: 1px solid #ccc">
                    <th style="font-size: 16px; padding: 8px">{{ chart.target_criteria }}</th>
                    <th v-for="obj in chart.series" :key="obj" style="font-size: 16px; padding: 8px">{{ obj.name }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="q in quarter" :key="q" style="border-bottom: 1px solid #ccc">
                    <td style="font-size: 16px; padding: 8px">{{ q }}</td>
                    <td
                      v-for="obj in chart.series"
                      :key="obj"
                      style="font-size: 16px; padding: 8px"
                      :style="{
                        color: isAccentByTargetCriteria(chart.target_criteria, obj.data[quarter.indexOf(q)])
                          ? 'tomato'
                          : ''
                      }"
                    >
                      {{ obj.data[quarter.indexOf(q)] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NSelect } from 'naive-ui'
import moment from 'moment'
import api from '@/api'
import { mergeWith, groupBy, cloneDeep } from 'lodash'

import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import DonutChart from '@/components/widgets/custom-components/DonutChart/DonutChart'
import NextSelect from '@/components/widgets/simple-components/select/NextSelect'
import NextIcon from '@/components/widgets/simple-components/icon'
import NextTable from '@/components/widgets/simple-components/table/NextTable'
import ParametersCharts from '@/components/common/ParametersCharts'

// Список кварталов начиная с 2024
const START_YEAR = 2024
const CURRENT_YEAR = new Date().getFullYear()

const quarter = []
for (let year = START_YEAR; year <= CURRENT_YEAR; year++) {
  for (let q = 1; q <= 4; q++) {
    quarter.push(`${q} кв. ${year}`)
  }
}

const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
]

export default {
  name: 'ParametersWidget',
  components: { NextTable, NextIcon, NextSelect, DonutChart, NextAlert, SpinLoader, ParametersCharts, NSelect },
  props: {
    department: {
      type: Object
    },
    subdivision: {
      type: Object
    }
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      parametersChartsList: [],
      signedProcessesList: [],
      signedProcessesIdsList: [],
      medtypes: [],
      medtype: undefined,
      options: {
        tooltip: {
          style: {
            fontSize: 18
          }
        },
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        title: {
          align: 'center'
        },
        xaxis: {
          categories: months
        },
        yaxis: {
          // forceNiceScale: true,
          min: 0,
          labels: {
            formatter: function (val) {
              const stringified = String(val)
              if (stringified.includes('.') && stringified.split('.')[1].length > 1) {
                return val.toFixed(0)
              }
              if (val) {
                return val.toFixed(0)
              }
              return val
            }
          }
        },
        colors: ['#31AF92', '#0284c7', '#f4a261', '#474E93'],
        grid: {
          padding: {
            left: 20,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '30%',
            distributed: false
          }
        },
        stroke: {
          width: [0]
        }
      },
      quarter
    }
  },
  computed: {
    groupedParametersChartsList() {
      return this.groupChartsByNameAndTerm(this.parametersChartsListFilteredByDepartment)
    },
    parametersChartsListFilteredByDepartment() {
      function hasIntersection(arr1, arr2) {
        const set = new Set(arr1)
        return arr2.some((num) => set.has(num))
      }

      if (this.subdivision) {
        const membersIds = this.subdivision.members.map((member) => member.id)

        return this.parametersChartsList.filter((chart) => hasIntersection(chart.commissionListId, membersIds))
      }

      if (this.department) {
        const membersIds = this.department.members.map((member) => member.id)

        return this.parametersChartsList.filter((chart) => hasIntersection(chart.commissionListId, membersIds))
      }

      return this.parametersChartsList
    }
  },
  watch: {
    medtype() {
      this.parametersChartsList = []
      this.fetchProcesses()
    }
  },
  mounted() {
    this.fetchProcesses()
    this.fetchMedtypes()
  },
  methods: {
    isAccentByTargetCriteria(target_criteria, value) {
      const operand = target_criteria[0]
      const target = target_criteria.replace(operand, '')

      if (operand === '<' && value >= target) {
        return true
      }

      if (operand === '>' && value <= target) {
        return true
      }

      if (operand === '=' && value != target) {
        return true
      }

      return false
    },
    getSuffix(input_type) {
      return input_type === 2 ? ', %' : ''
    },
    groupChartsByNameAndTerm(chartsList) {
      const groupedCharts = groupBy(chartsList, (chart) => `${chart.name}-${chart.term_name}`)

      return Object.values(groupedCharts).map((group) => {
        const baseChart = cloneDeep(group[0])
        baseChart.medtypes = group.map((chart) => chart.medtype)
        baseChart.series = group.map((chart) => ({
          name: chart.medtype?.text,
          type: chart.series[0]?.type,
          data: chart.series[0]?.data
        }))

        baseChart.options = mergeWith(baseChart.options, {
          legend: { show: true }
        })

        return baseChart
      })
    },
    async fetchMedtypes() {
      const medtypesResponse = await this.$api.get('/organization/get-organization-medtypes')
      if (medtypesResponse.data.ok === 1 && medtypesResponse.data.data.length) {
        this.medtypes = medtypesResponse.data.data.map((medtype) => ({
          ...medtype,
          value: medtype.id,
          label: medtype.text
        }))
      }
    },
    async fetchAndNormalizeIndicators() {
      let promisesForEachProcessId = []

      this.signedProcessesIdsList.forEach((process_id) => {
        let year = 2024

        for (year; year <= CURRENT_YEAR; year++) {
          promisesForEachProcessId.push(this.$api.post('/process/get-indicators', { process_id, year }))
        }
      })

      let responseIndicatorsForEachProcessId = await Promise.all(promisesForEachProcessId)

      responseIndicatorsForEachProcessId = responseIndicatorsForEachProcessId
        .map((responseObject) => responseObject.data.data)
        .filter((responseData) => responseData && responseData.length)

      let indicators = responseIndicatorsForEachProcessId
        .reduce((accum, arrayOfIndicators) => {
          return [...accum, ...arrayOfIndicators]
        }, [])
        .filter((indicator) => indicator.view_mode === 2)

      const uniqProcessIds = [...new Set(indicators.map(({ process_id }) => process_id))]
      const commissionForProcessId = new Map()

      const fetchCommission = async (payload) => {
        try {
          const response = await api.post('/process/get-commission', payload)
          return response.data.data
        } catch (err) {
          console.log(err)
        }
      }

      for (const process_id of uniqProcessIds) {
        const commissionList = await fetchCommission({ process_id })

        if (commissionList.length) {
          commissionForProcessId.set(
            process_id,
            commissionList.map((user) => user.id)
          )
        }
      }

      indicators = indicators.map((indicator) => {
        if (commissionForProcessId.has(indicator.process_id)) {
          return {
            ...indicator,
            commissionListId: commissionForProcessId.get(indicator.process_id)
          }
        }

        return indicator
      })

      return indicators || []
    },
    async getParameters() {
      this.isLoading = true

      // Запрос параметров
      let {
        data: { data: parameters, ok }
      } = await this.$api.post('/process/get-parameters', {
        process_id: this.signedProcessesIdsList,
        active: 1
      })

      // Приводим min и max к числовому типу
      parameters = parameters.map((parameter) => ({
        ...parameter,
        min: Number(parameter.min),
        max: Number(parameter.max)
      }))

      if (ok === 1) {
        // Получаем индикаторы
        const indicators = await this.fetchAndNormalizeIndicators()

        // Функция для дополнения массива до 12 элементов
        const autoFillArray = (array = []) => {
          const arr = [...array]
          while (arr.length < 12) {
            arr.push(null)
          }
          return arr
        }

        const charts = []

        // Обрабатываем каждый параметр
        parameters.forEach((parameter) => {
          // Находим все индикаторы, соответствующие данному параметру
          const matchedIndicators = indicators.filter(
            (indicator) =>
              parameter.process_id === indicator.process_id && indicator.process_indicator_id === parameter.id
          )

          // Если ни одного индикатора не найдено — пропускаем параметр
          if (!matchedIndicators.length) return

          // Получаем данные процесса (например, medtype) для параметра
          const process = this.signedProcessesList.find((proc) => proc.id === parameter.process_id)
          const medtype = process ? process.medtype : {}

          // Инициализируем объект для объединённого индикатора.
          // Для остальных полей (например, view_mode) будем брать значение из первого найденного индикатора.

          const combinedIndicator = {
            view_mode: matchedIndicators[0].view_mode,
            commissionListId: matchedIndicators[0].commissionListId
          }

          // Объединяем значения из каждого найденного индикатора в один массив
          let combinedValues = []

          matchedIndicators.forEach((indicator) => {
            let values = []

            // Если режим отображения равен 3 — извлекаем значения из calendar_values, сортируя ключи
            if (indicator.view_mode === 3 && indicator.calendar_values) {
              values = Object.keys(indicator.calendar_values)
                .sort()
                .map((key) => indicator.calendar_values[key])
            }
            // Если режим отображения равен 2 — берём значения как есть
            else if (indicator.view_mode === 2) {
              values = indicator.values || []
            }
            // В остальных случаях: если длина values равна 12 — используем их, иначе заполняем недостающие элементы null
            else {
              if (indicator.values && indicator.values.length === 12) {
                values = indicator.values
              } else {
                values = autoFillArray(indicator.values)
              }
            }

            // Объединяем полученный массив с общим
            combinedValues = combinedValues.concat(values)
          })

          // Присваиваем объединённый массив значений в объект индикатора
          combinedIndicator.values = combinedValues
          // Если требуется объединять и calendar_values, можно добавить дополнительную логику.
          // Здесь оставляем calendar_values пустым либо можно взять из первого индикатора.
          combinedIndicator.calendar_values = {}

          // если в combinedValues меньше записей чем количество кварталов
          // добавить пустые значения для корректного рендера таблицы кварталов
          if (combinedIndicator.view_mode === 2 && combinedValues.length < quarter.length) {
            quarter.forEach((q, index) => {
              if (combinedValues[index] === undefined) {
                combinedValues[index] = null
              }
            })
          }

          // Формируем итоговый объект диаграммы для параметра
          const chart = {
            ...parameter,
            values: combinedValues,
            medtype: {
              ...medtype,
              values: combinedValues
            },
            options: this.getCustomOptions({ ...parameter, medtype, ...combinedIndicator }),
            series: this.getCustomSeries({ ...parameter, values: combinedValues }, combinedIndicator),
            view_mode: combinedIndicator.view_mode,
            calendar_values: combinedIndicator.calendar_values,
            commissionListId: combinedIndicator.commissionListId
          }

          charts.push(chart)
        })

        // Фильтруем итоговые объекты:
        // 1. Оставляем те, у которых values не пустой и не состоит только из null
        // 2. При необходимости оставляем только с view_mode === 2 (например, для квартального отчёта)
        const filledParameters = charts
          .filter((chart) => chart.values && chart.values.length && !chart.values.every((value) => value === null))
          .filter((chart) => chart.view_mode === 2)

        this.parametersChartsList = filledParameters
      }
    },
    async fetchProcesses() {
      try {
        this.isLoading = true

        const result = await this.$api.get('process/get-signed-processes', {
          params: {
            medtype: this.medtype,
            limit: 99999
          }
        })

        if (result.data.ok === 1) {
          this.signedProcessesIdsList = result.data.data.map((process) => process.id)
          this.signedProcessesList = result.data.data

          await this.getParameters()
        } else {
          // this.error = result.data.message
        }
      } catch (e) {
        // this.error = e.message
      } finally {
        this.isLoading = false
      }
    },
    async fetchDailyIndicators(indicators) {
      const promises = indicators.map(async (indicator) => {
        const params = {
          process_id: indicator.process_id,
          year: indicator.year,
          indicator_id: indicator.process_indicator_id
        }

        const res = await this.$api.post('/process/get-daily-indicators', params)

        return {
          ...indicator,
          calendar_values: res.data.data
        }
      })

      const values = await Promise.all(promises)
      return values
    },
    getCustomOptions(chart) {
      const options = {
        ...this.options,
        title: {
          ...this.options.title
        },
        yaxis: {
          ...this.options.yaxis,
          min: chart.min
        }
      }

      // tickAmount по 10 ставится только если хоть одно значение >= 10, иначе значения в оси дублируются
      if (chart.values?.some((value) => value >= 10)) {
        options.yaxis.tickAmount = 10
      }

      // max задаётся только для процентных значений
      if (chart.input_type === 2) {
        options.yaxis.max = chart.max

        if (chart.max % 10 > 0) {
          options.yaxis.max = Math.ceil(chart.max / 10) * 10
          options.yaxis.tickAmount = (Math.ceil(chart.max / 10) * 10) / 10
        }

        options.yaxis.forceNiceScale = false
      }

      if (chart.view_mode === 2) {
        options.xaxis = {
          ...options.xaxis,
          categories: quarter
        }
      }

      if (chart.view_mode === 3 && Object.keys(chart.calendar_values).length) {
        options.xaxis = {
          ...options.xaxis,
          categories: Object.keys(chart?.calendar_values)
            .sort()
            .map((date) => moment(date).format('DD.MM.YYYY'))
        }
      }

      return options
    },
    getCustomSeries(chart, matchedIndicator) {
      // const trendLineParams = this.calculateTrendLine(chart.values)
      // const trendLineData = this.generateTrendLinePoints(chart.values, trendLineParams, chart.min, chart.max)
      let trendLineParams
      let trendLineData

      if (matchedIndicator?.view_mode === 3) {
        const values = Object.keys(matchedIndicator.calendar_values)
          .sort()
          .map((key) => matchedIndicator.calendar_values[key])
          .map((v) => (v === '' || v === null ? null : Number(v)))

        trendLineParams = this.calculateTrendLine(values)
        trendLineData = this.generateTrendLinePoints(values, trendLineParams, chart.min, chart.max)
      } else {
        trendLineParams = this.calculateTrendLine(chart.values)
        trendLineData = this.generateTrendLinePoints(chart.values, trendLineParams, chart.min, chart.max)
      }

      const series = [
        {
          type: 'column',
          name: chart.name,
          data: chart.values
        }
      ]

      if (chart.values.length > 1 && !chart.values.every((v) => v === null)) {
        series.push({
          type: 'line',
          name: 'Тренд',
          data: trendLineData
        })
      }

      return series
    },
    calculateTrendLine(data) {
      let xSum = 0,
        ySum = 0,
        xySum = 0,
        xxSum = 0
      let validCount = 0 // Счетчик для подсчета количества неигнорируемых элементов

      for (let i = 0; i < data.length; i++) {
        if (data[i] !== null) {
          // Проверяем, не равен ли текущий элемент null
          xSum += validCount // Используем validCount вместо i для расчета сумм
          ySum += data[i]
          xySum += validCount * data[i]
          xxSum += validCount * validCount
          validCount++ // Увеличиваем счетчик для каждого неигнорируемого элемента
        }
      }

      if (validCount === 0) {
        // Если все значения были null, возвращаем m и b как NaN или другое значимое значение
        return { m: NaN, b: NaN }
      }

      const m = (validCount * xySum - xSum * ySum) / (validCount * xxSum - xSum * xSum)
      const b = (ySum - m * xSum) / validCount

      return { m, b }
    },
    generateTrendLinePoints(data, { m, b }, min, max) {
      let trendLine = data.map((_, index) => Number((m * index + b).toFixed(5)))

      // Если max undefined, используем текущие минимальные и максимальные значения трендовой линии
      if (!max) {
        max = Math.max(...trendLine)
      }

      const scaledValues = this.scaleValuesWithinRange(trendLine, min, max)

      return scaledValues
    },
    scaleValuesWithinRange(values, min, max) {
      const originalMin = Math.min(...values)
      const originalMax = Math.max(...values)

      // Если все значения уже в пределах min и max, ничего делать не нужно
      if (originalMin >= min && originalMax <= max) {
        return values
      }

      // Масштабируем значения
      const scaledValues = values.map((value) => {
        // Пропорционально адаптируем значение к новому диапазону
        const scaledValue = ((value - originalMin) / (originalMax - originalMin)) * (max - min) + min
        return scaledValue
      })

      return scaledValues
    }
  }
}
</script>

<style scoped lang="scss">
.column {
  // border-bottom: 1px solid #ccc;
  // border-top: 1px solid #ccc;

  &:nth-child(1) {
    .column-row {
      border-left: 1px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.column-row {
  height: 32px;
  min-width: 120px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;

  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;

  &:nth-child(1) {
    height: 40px;
    border-top: 1px solid #ccc;
    justify-content: center;
  }
}

.accent-by-target-criteria {
  color: tomato;
  font-weight: 700;
}
</style>
