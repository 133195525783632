<template>
  <div v-if="dataIsReady">
    <div class="d-flex flex-wrap mb-3">
      <OrganizationInformationItem
        v-if="!isEdit"
        icon="white-avatar-icon"
        :header="chairmanTitle"
        :text="data?.chairman?.name || 'Не назначен'"
      />
      <div v-else class="settings-item">
        <span class="settings-item-label">{{ chairmanTitle }}</span>
        <NSelect
          v-model:value="inspectionFormValue.chairman_id"
          style="width: 280px"
          :options="responsibleUsers"
          size="large"
          clearable
          filterable
        />
      </div>

      <div v-if="showDeputy">
        <OrganizationInformationItem
          v-if="!isEdit"
          icon="white-avatar-icon"
          header="Заместитель"
          :text="data?.deputy?.name || 'Не назначен'"
        />
        <div v-else class="settings-item">
          <span class="settings-item-label">Заместитель</span>
          <NSelect
            v-model:value="inspectionFormValue.deputy_id"
            style="width: 280px"
            :options="responsibleUsers"
            size="large"
            clearable
            filterable
          />
        </div>
      </div>

      <OrganizationInformationItem
        v-if="!isEdit"
        icon="white-avatar-icon"
        header="Секретарь"
        :text="data?.secretary?.name || 'Не назначен'"
      />
      <div v-else class="settings-item">
        <span class="settings-item-label">Секретарь</span>
        <NSelect
          v-model:value="inspectionFormValue.secretary_id"
          style="width: 280px"
          :options="responsibleUsers"
          size="large"
          clearable
          filterable
        />
      </div>

      <OrganizationInformationItem
        v-if="!isEdit"
        icon="white-avatar-icon"
        :header="membersTitle"
        :text="normalizedMembersString"
      />
      <div v-else class="settings-item">
        <span class="settings-item-label">{{ membersTitle }}</span>
        <NSelect
          v-model:value="inspectionFormValue.members"
          style="width: 280px"
          :options="responsibleUsers"
          size="large"
          clearable
          multiple
          filterable
        />
      </div>

      <OrganizationInformationItem
        v-if="!isEdit"
        icon="white-clock-icon"
        header="Дата заседания"
        :text="normalizedDatetime"
      />
      <div v-else class="settings-item">
        <span class="settings-item-label">Дата заседания</span>
        <NDatePicker
          v-model:value="inspectionFormValue.datetime"
          style="width: 280px"
          format="dd.MM.yyyy"
          aria-required
          type="date"
          size="large"
        />
      </div>

      <OrganizationInformationItem
        v-if="!isEdit"
        icon="white-search-icon"
        header="Очередность"
        :text="data.queue?.name || '—'"
      />
      <div v-else class="settings-item">
        <span class="settings-item-label">Очередность</span>
        <NSelect
          v-model:value="inspectionFormValue.queue_id"
          style="width: 280px"
          :options="queueList"
          size="large"
          clearable
        />
      </div>

      <OrganizationInformationItem
        v-if="!isEdit"
        icon="white-search-icon"
        header="Наименование врачебной комиссии"
        :text="data?.name || '—'"
      />
      <div v-else class="settings-item">
        <span class="settings-item-label">Наименование врачебной комиссии</span>
        <NInput v-model:value="inspectionFormValue.name" style="width: 280px" size="large" />
      </div>

      <OrganizationInformationItem header="Статус" icon="white-checkmark-icon" :text="data.status?.name || '—'" />
    </div>
    <div v-if="isEdit" class="flex justify-content-end mb-5">
      <NButton type="primary" size="large" @click="onSaveInspectionChanges">Сохранить изменения</NButton>
    </div>

    <div style="padding-top: 20px">
      <NH3 style="text-align: center">Перечень рассматриваемых вопросов</NH3>

      <TransitionGroup name="list">
        <div v-for="(issue, index) in data.issues" :key="index" class="issues">
          <div class="issues-index">{{ index + 1 }}</div>
          <div class="issues-input">
            <div class="mb-4">
              <p style="margin-bottom: 5px; font-size: 14px">Рассматриваемый вопрос</p>
              <p style="font-size: 15px">
                {{ issue?.name }}
              </p>
            </div>
            <div>
              <p style="margin-bottom: 5px; font-size: 14px">Принятое решение</p>
              <p style="font-size: 15px">{{ issue.solution }}</p>
            </div>

            <div>
              <p style="margin-bottom: 5px; font-size: 14px">Результаты голосования</p>
              <p style="font-size: 15px">{{ issue.voting_results }}</p>
            </div>
          </div>
          <div class="issues-select">
            <div class="mb-5">
              <p style="margin-bottom: 5px; font-size: 14px">Ответственный</p>
              <p style="font-size: 15px">{{ issue?.responsible?.name }}</p>
            </div>

            <NButton size="small" class="mr-1" quaternary @click="editIssue(issue)">Редактировать</NButton>
            <NButton size="small" quaternary type="error" @click="deleteIssue(issue.id)">Удалить</NButton>
          </div>
        </div>
      </TransitionGroup>

      <NButton type="primary" class="issues-button-add" @click="showModal">Добавить</NButton>
    </div>

    <NModal v-model:show="show" transform-origin="center" :on-after-leave="onModalHide">
      <NCard style="width: 900px" :title="modalTitle" :bordered="false" size="huge" role="dialog" aria-modal="true">
        <div>
          <div class="mb-3">
            <p class="mb-2">Рассматриваемый вопрос</p>
            <NInput
              v-model:value="editedIssue.name"
              size="large"
              type="textarea"
              class="mb-2"
              :autosize="{ minRows: 3, maxRows: 3 }"
            />
          </div>
          <div class="mb-3">
            <p class="mb-2">Принятое решение</p>
            <NInput
              v-model:value="editedIssue.solution"
              size="large"
              type="textarea"
              class="mb-2"
              :autosize="{ minRows: 2, maxRows: 2 }"
            />
          </div>
          <div class="mb-3">
            <p class="mb-2">Ответственный</p>
            <NSelect
              v-model:value="editedIssue.responsible_id"
              style="width: 280px; margin-bottom: 15px"
              :options="responsibleUsers"
              size="large"
              clearable
              filterable
            />
          </div>
          <div v-if="showVotingResults" class="mb-3">
            <p class="mb-2">Результаты голосования</p>
            <NInput
              v-model:value="editedIssue.voting_results"
              size="large"
              type="textarea"
              class="mb-2"
              :autosize="{ minRows: 2, maxRows: 2 }"
            />
          </div>
        </div>
        <template #footer>
          <NButton class="mr-2" @click="submitModal">Сохранить</NButton>
          <NButton @click="show = false">Отмена</NButton>
        </template>
      </NCard>
    </NModal>
  </div>
</template>

<script>
import { NSelect, NInput, NButton, NH3, useMessage, NModal, NCard, NDatePicker } from 'naive-ui'
import moment from 'moment'
import OrganizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'

export default {
  components: { OrganizationInformationItem, NSelect, NInput, NButton, NH3, NModal, NCard, NDatePicker },
  props: {
    data: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean
    }
  },
  emits: ['onInsprectionEditSuccess'],
  data() {
    return {
      show: false,
      isEditModal: false,
      message: useMessage(),
      responsibleUsers: [],
      queueList: [],
      newIssueTemplate: {
        responsibleId: 0,
        name: '',
        solution: ''
      },
      editedIssue: {
        responsible_id: undefined,
        name: '',
        solution: '',
        voting_results: ''
      },
      inspectionFormValue: null
    }
  },
  computed: {
    dataIsReady() {
      return Boolean(Object.keys(this.data).length)
    },
    normalizedMembersString() {
      return this.data.members.map((user) => user.name).join(', ')
    },
    normalizedMembersList() {
      return this.data.members.map((user) => ({
        ...user,
        value: Number(user.id),
        label: user.name
      }))
    },
    normalizedDatetime() {
      return moment(this.data?.datetime).format('DD.MM.YYYY')
    },
    modalTitle() {
      return `${this.isEditModal ? 'Редактирование' : 'Создание'} вопроса на рассмотрение`
    },
    showDeputy() {
      return ['1', '2'].includes(this.data?.type?.id)
    },
    chairmanTitle() {
      if (['1', '2'].includes(this.data?.type?.id)) {
        return 'Председатель комиссии'
      } else {
        return 'Руководитель рабочей группы'
      }
    },
    membersTitle() {
      if (['1', '2'].includes(this.data?.type?.id)) {
        return 'Члены комиссии'
      } else {
        return 'Участники заседания'
      }
    },
    showVotingResults() {
      console.log(this.data?.type?.id)
      return ['1'].includes(this.data?.type?.id)
    }
  },
  watch: {
    isEdit() {
      if (this.isEdit && !this.inspectionFormValue) {
        let formValue = {
          number: this.data.number,
          status_id: Number(this.data.status_id),
          type_id: Number(this.data.type_id),
          organization_id: Number(this.data.organization_id),
          datetime: new Date(this.data.datetime.split(' ')[0]).getTime(),
          queue_id: Number(this.data.queue_id),
          chairman_id: Number(this.data.chairman_id),
          secretary_id: Number(this.data.secretary_id),
          members: this.data.members.map((user) => Number(user.id))
        }

        // если не рабочая группа (3)
        if (['1', '2'].includes(this.data?.type?.id)) {
          formValue.deputy_id = Number(this.data.deputy_id)
        }

        if (['1'].includes(this.data?.type?.id)) {
          formValue.name = this.data.name
        }

        this.inspectionFormValue = formValue
      }
    },
    data() {
      if (this.data.id && this.responsibleUsers.length === 0) {
        this.getResponsibleUsers()
      }
      if (this.data.id && this.queueList.length === 0) {
        this.getQueueList()
      }
    }
  },
  mounted() {},
  methods: {
    async getResponsibleUsers() {
      const inspectionType = this.data?.type?.id
      const filterConditionMap = {
        1: (user) => user.med_commission == 1,
        2: (user) => user.vkk == 1,
        3: (user) => user
      }
      const filterCb = filterConditionMap[inspectionType]

      try {
        const res = await this.$api.get('responsible/get')

        if (res.data.ok === 1) {
          this.responsibleUsers = res.data.data
            .filter(filterCb)
            .map((user) => ({
              ...user,
              value: user.id,
              label: user.name
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        }
      } catch (error) {
        console.warn('getResponsibleUsers', error)
      }
    },
    showModal() {
      this.show = true
      this.isEditModal = false
      this.editedIssue = {
        responsible_id: undefined,
        name: '',
        solution: '',
        voting_results: ''
      }
    },
    submitModal() {
      if (!this.editedIssue.name || !this.editedIssue.responsible_id) {
        this.message.warning('Поля, обязательные к заполнению: "Рассматриваемый вопрос", "Ответственный"')
      } else {
        const ACTION_NAME = this.isEditModal ? 'INSPECTION_EDIT_ISSUE' : 'INSPECTION_ADD_ISSUE'
        const payload = {
          ...this.editedIssue,
          meeting_id: this.data.id,
          issue_id: this.editedIssue.id
        }

        this.$store.dispatch(ACTION_NAME, payload).then((resp) => {
          if (resp?.status === 200 && 'id' in resp?.data) {
            this.show = false
            this.message.success('Успешно')
            this.$store.dispatch('INSPECTION_GET_BY_ID', this.data.id)
          }
        })
      }
    },
    deleteIssue(issue_id) {
      this.$store
        .dispatch('INSPECTION_DELETE_ISSUE', {
          issue_id,
          meeting_id: this.data.id
        })
        .then((resp) => {
          if (resp.status === 200) {
            this.message.success('Рассматриваемый вопрос удален')
            this.$store.dispatch('INSPECTION_GET_BY_ID', this.data.id)
          }
        })
    },
    editIssue(issue) {
      this.show = true
      this.isEditModal = true
      this.editedIssue = {
        meeting_id: issue.meeting_id,
        responsible_id: issue.responsible_id,
        responsible: issue.responsible,
        name: issue.name,
        id: issue.id,
        solution: issue.solution
      }
    },
    onModalHide() {
      if (this.isEditModal) {
        this.isEditModal = false
      }
    },
    async onSaveInspectionChanges() {
      const payload = {
        ...this.inspectionFormValue,
        meeting_id: this.data.id,
        datetime: moment(this.inspectionFormValue.datetime).format('YYYY-MM-DD')
      }

      const resp = await this.$store.dispatch('INSPECTION_EDIT', payload)

      if (resp.status === 200) {
        this.message.success('Успешно')
        this.$emit('onInsprectionEditSuccess')
      }
    },
    async getQueueList() {
      try {
        const res = await this.$api.get('meetings/queue')
        if (res.status === 200) {
          this.queueList = res.data.map((queue) => ({
            ...queue,
            value: queue.id,
            label: queue.name
          }))
        }
      } catch (error) {
        console.warn('getQueueList', error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(40px);
}

.settings-item {
  min-width: 200px;
  padding-right: 20px;
  margin-bottom: 20px;
}

.settings-item-label {
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 9px;
  display: block;
}
.issues {
  display: flex;
  gap: 15px;
  margin-bottom: 40px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
  padding: 25px;
  border-radius: 4px;
}

.issues-index {
  font-weight: bold;
}

.issues-input {
  flex: 3;
  padding-left: 20px;
  padding-right: 40px;
}

.issues-select {
  flex: 1;
}

.issues-button-add {
  margin-left: 25px;
}

.information-item {
  padding-right: 1.5rem;
  margin-bottom: 2rem;
  width: auto;

  &:last-child {
    padding-right: 0;
  }
}
</style>
