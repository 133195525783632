<template>
  <div class="">
    <div class="mb-5 flex align-items-end">
      <div class="col-6">
        <!-- <NextSelect
          v-model="responsibleId"
          request="responsible/get"
          view-prop="name"
          label="Ответственный"
          placeholder="Ответственный"
          sortable
          @change="(id, user) => (responsibleUser = user)"
        /> -->
        <div class="mb-2">Ответственный</div>
        <EntrySelect
          v-model:value="responsibleId"
          request="responsible/get"
          sortable
          filterable
          label-field="name"
          @change="changeResponsibleUserId"
        />

        <div class="pt-2">
          <NButton strong type="primary" :loading="isLoading" :disabled="!responsibleId" @click="saveResponsible">
            Сохранить
          </NButton>
        </div>
      </div>
      <div class="col-6 commission">
        <div class="commission-title">Рабочая группа</div>
        <NSelect v-model:value="commissionMembersIds" multiple filterable :options="options" max-tag-count="2" />
        <div class="pt-2">
          <NButton strong type="primary" @click="saveCommission">Сохранить</NButton>
        </div>
      </div>
    </div>

    <div class="pl-3">
      <div v-for="indicator in indicators" :key="indicator.name + indicator.id" class="mb-2">
        <NSwitch
          class="mr-3"
          :value="Boolean(indicator.is_active)"
          @update:value="(value) => handleChange(indicator, value)"
        />
        <span :class="!Boolean(indicator.is_active) ? 'text-black-50' : ''">{{ indicator.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { NSelect, NButton, NSwitch } from 'naive-ui'
// import NextSelect from '@/components/widgets/simple-components/select/NextSelect'
import EntrySelect from '@/components/common/EntitySelect'

export default {
  components: { NSelect, NButton, NSwitch, EntrySelect },
  props: {
    process: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      responsibleId: this.process?.responsible_id,
      responsibleUser: null,
      options: [],
      commissionMembersIds: [],
      indicators: []
    }
  },
  computed: {
    ...mapGetters(['commissionMembers'])
  },
  mounted() {
    this.commissionMembersIds = this.commissionMembers.map((user) => user.id)
    this.fetchCommissionMembersList()
    this.fetchIndicators()
  },
  methods: {
    ...mapActions({
      fetchCommissionMembers: 'PROCESS_GET_COMMISSION_MEMBERS'
    }),
    async handleChange(indicator) {
      const {
        data: { ok, message }
      } = await this.$api.post('/process/set-indicator-config', {
        process_id: this.process.id,
        indicator_id: Number(indicator.id),
        is_active: Number(!indicator.is_active)
      })

      console.log('data', ok, message)
      if (ok === 1) {
        this.$store.dispatch('ALERT_CREATE', { text: message, type: 'success' })
        this.fetchIndicators()
      }
    },
    async fetchIndicators() {
      const {
        data: { data: indicators, ok }
      } = await this.$api.post('/process/get-indicators-config', {
        process_id: this.process.id
      })

      if (ok === 1) {
        this.indicators = indicators
      }
    },
    async fetchCommissionMembersList() {
      const url = '/process/get-available-commission-members'
      const body = {
        process_id: this.$router.currentRoute.value.params.id
      }

      const result = await this.$api.post(url, body)

      if (result.data.ok === 1) {
        const options = result.data.data
          .map((el) => ({
            value: el.id,
            label: el.name
          }))
          .sort((a, b) => a.label.localeCompare(b.label))

        this.options = options
      }
    },
    async saveResponsible() {
      try {
        this.isLoading = true

        await this.$api.post('/process/set-responsible', {
          process_id: this.process.id,
          responsible_id: this.responsibleId
        })

        this.$parent.setResponsibleId(this.responsibleId)

        this.$store.dispatch('ALERT_CREATE', { text: 'Ответственный добавлен', type: 'success' })
      } catch (e) {
        console.log(e)
        this.$store.dispatch('ALERT_CREATE', { text: e?.response?.data?.message || e.message, type: 'error' })
      } finally {
        this.isLoading = false
      }
    },
    async saveCommission() {
      try {
        await this.$api.post('/process/set-commission', {
          process_id: this.process.id,
          commission_members: this.commissionMembersIds
        })
        this.fetchCommissionMembers({
          process_id: this.$router.currentRoute.value.params.id
        })

        this.$store.dispatch('ALERT_CREATE', { text: 'Рабочая группа сохранена', type: 'success' })
      } catch (e) {
        console.log(e)
        this.$store.dispatch('ALERT_CREATE', { text: e?.response?.data?.message || e.message, type: 'error' })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style>
.commission .commission-title {
  font-size: 14px;
  margin-bottom: 8px;
}

.n-base-selection {
  min-height: 40px;
}
</style>
