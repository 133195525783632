<template>
  <div class="notify-settings">
    <div class="notify-header">Окно настройки уведомлений пользователя:</div>
    <div v-if="!loading" class="content">
      <div class="settings-block">
        <div class="settings-item">
          <div class="settings-text flex">
            <organizationInformationItem
              class="mr-0"
              header="Ваша электронная почта"
              icon="white-email-icon"
              :text="userEmail"
            />
            <NButton size="small" class="mr-4" @click="showEditUserEmail = !showEditUserEmail">
              <template #icon>
                <Edit v-if="!showEditUserEmail" />
                <X v-else />
              </template>
            </NButton>

            <div v-if="showEditUserEmail" class="flex align-items-start">
              <NInput
                v-model:value="emailForNotificationsVal"
                size="medium"
                class="mr-2"
                placeholder="Введите новый email"
              />
              <NButton size="small" @click="saveUserEmail">Сохранить</NButton>
            </div>
          </div>
          <div class="settings-message subheader" />
          <!-- <div class="settings-email subheader">На email</div> -->
          <div class="settings-filter subheader">Принимать уведомления от</div>
        </div>
      </div>
      <template v-for="filteredItem in items" :key="filteredItem.group">
        <div class="settings-block">
          <div class="settings-header">
            {{ filteredItem.group }}
          </div>
          <template v-for="item in filteredItem.items" :key="item.id">
            <div class="settings-item">
              <div class="settings-text">{{ item.name }}</div>
              <div class="settings-message">
                <span class="mr-2">в ЛК</span>
                <checkbox
                  class="mr-4"
                  :start-active="item.notify_in_dashboard == 0 ? false : true"
                  @change-handler="changeSettings(item, 'dashboard')"
                />

                <span class="mr-2">на email</span>
                <checkbox
                  :start-active="item.notify_by_email == 0 ? false : true"
                  @change-handler="changeSettings(item, 'email')"
                />
              </div>
              <div class="settings-filter">
                <dropdownListWithHeader
                  :key="item.filter"
                  :header="''"
                  :list="filters"
                  :start-item="filters[item.filter - 1]"
                  :search="false"
                  @select-handler="
                    (val) => {
                      changeFilter(item, val)
                    }
                  "
                />
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <SpinLoader v-if="loading" class="py-5" size="2xl" />
  </div>
</template>

<script>
import { ref } from 'vue'
import { NButton, NIcon, NInput, useNotification } from 'naive-ui'

import { Edit } from '@vicons/tabler'
import { X } from '@vicons/tabler'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import checkbox from '@/components/widgets/simple-components/inputs/checkbox'
import organizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import { computed } from 'vue'

export default {
  name: 'NotifySettings',
  components: {
    SpinLoader,
    checkbox,
    dropdownListWithHeader,
    organizationInformationItem,
    NButton,
    NIcon,
    Edit,
    NInput,
    X
  },
  data() {
    const notify = useNotification()

    const currentUser = ref(JSON.parse(localStorage.user))
    const userEmail = computed(() => currentUser.value.email_for_notifications || currentUser.value.email)

    return {
      timeoutRequest: '',
      filters: [
        // { id: 0, text: 'Все' },
        { id: 1, text: 'По организации' },
        { id: 2, text: 'Только личные' }
      ],
      currentFilter: { id: 0, text: 'Все' },
      loading: true,
      settings: [],
      items: [],
      currentUser,
      userEmail,
      showEditUserEmail: false,
      emailForNotificationsVal: '',
      notify
    }
  },
  computed: {},
  watch: {
    settings() {
      let iter = 0

      // this.settings.sort((a, b) => {
      //   if (a.group > b.group) {
      //     return 1
      //   } else if (a.group < b.group) {
      //     return -1
      //   }
      //   return 0
      // })

      this.settings.map((item) => {
        if (!this.items.find((index) => index.group === item.group)) {
          this.items.push({ group: item.group, items: [] })
          iter++
        }
        this.items[iter - 1].items.push(item)
      })
    }
  },
  beforeMount() {
    this.getNotifySettings()
  },
  methods: {
    changeFilter(item, val) {
      item.filter = val.id
      clearTimeout(this.timeoutRequest)
      this.timeoutRequest = setTimeout(() => {
        this.setSettings()
      }, 1000)
    },
    changeSettings(item, type) {
      if (type === 'dashboard') {
        item.notify_in_dashboard = item.notify_in_dashboard == 1 ? 0 : 1
      }
      if (type === 'email') {
        item.notify_by_email = item.notify_by_email == 1 ? 0 : 1
      }
      clearTimeout(this.timeoutRequest)
      this.timeoutRequest = setTimeout(() => {
        this.setSettings()
      }, 1000)
    },
    setSettings() {
      this.$api.post('users/set-notifications-config', this.settings)
    },
    convertDefaultNotificationType(data) {
      const converted = data.map((el) => {
        if (Number(el.filter) === 0) {
          return {
            ...el,
            filter: 2 // По-умолчанию "Только личные"
          }
        }
        return el
      })

      return converted
    },
    getNotifySettings() {
      this.$api.get('users/get-notifications-config').then((res) => {
        let data = res.data.data

        const hasNotificationsTypeAll = data.some((el) => Number(el.filter) === 0)
        if (hasNotificationsTypeAll) {
          data = this.convertDefaultNotificationType(data)

          this.settings = data
          this.loading = false
          this.setSettings()
          return
        }

        this.settings = data
        this.loading = false
      })
    },
    saveUserEmail() {
      const userData = {
        ...this.currentUser,
        email_for_notifications: this.emailForNotificationsVal
      }

      this.$api
        .post('responsible/set?id=' + this.currentUser.id, userData)
        .then((res) => {
          if (res.data.ok === 1) {
            const payload = (this.currentUser = {
              ...this.currentUser,
              ...res.data.data
            })

            this.$store.commit('update_current_user', payload)

            this.currentUser = this.$store.getters.currentUser
            this.showEditUserEmail = false

            this.notify.success({
              title: 'Успешно',
              duration: 3000
            })
          }
        })
        .catch((err) => {
          this.notify.error({
            title: 'Не удалось получить список',
            content: String(err),
            duration: 3000
          })
        })
    }
  }
}
</script>

<style scoped>
.settings-filter {
  width: 240px;
  flex: none;
}
.settings-block {
  border-bottom: 1px solid #cccccc;
  padding: 20px 40px;
}
.notify-header {
  font-weight: 400;
  font-size: 23px;
  padding-left: 40px;
  padding-top: 20px;
  line-height: 31px;
  color: #333333;
}
.subheader {
  font-size: 16px;
  font-weight: 600;
}
.settings-item {
  width: 100%;
  align-items: center;
  padding-bottom: 5px;
  display: flex;
}
.settings-header {
  font-size: 18px;
  padding-bottom: 20px;
  font-weight: 600;
}
.settings-text {
  width: 100%;
}
.settings-email {
  width: 200px;
  flex: none;
  align-items: center;
  justify-content: center;
  display: flex;
}
.settings-message {
  width: 300px;
  flex: none;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
