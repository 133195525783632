<template>
  <div>
    <div class="d-flex flex-wrap mb-3">
      <OrganizationInformationItem icon="white-number-icon" header="ID риска">
        <router-link :to="'/risks/' + data.risk_id">{{ data.risk_id || '—' }}</router-link>
      </OrganizationInformationItem>

      <OrganizationInformationItem
        icon="white-clock-icon"
        header="Дата регистрации"
        :text="data.registration_date ? moment(data.registration_date).format('DD.MM.YYYY') : '—'"
      />

      <div class="flex mr-5">
        <OrganizationInformationItem
          class="mr-1 pr-0"
          icon="white-clock-icon"
          header="Дата устранения"
          :text="data.elimination_date ? moment(data.elimination_date).format('DD.MM.YYYY') : '—'"
        />

        <NPopover v-if="showEliminationDateEdit" trigger="click" placement="bottom">
          <template #trigger>
            <NButton quaternary size="small" type="primary" class="p-2">
              <template #icon>
                <NIcon><Edit /></NIcon>
              </template>
            </NButton>
          </template>

          <NDatePicker v-model:value="elimination_date" panel type="date" actions="" />

          <template #footer>
            <div class="flex justify-content-end">
              <NButton size="small" type="primary" @click="onChangeEliminationDate">Сохранить</NButton>
            </div>
          </template>
        </NPopover>
      </div>

      <OrganizationInformationItem
        header="Кто зарегистрировал"
        icon="white-avatar-icon"
        :text="data.registrator.name"
      />

      <OrganizationInformationItem header="Ответственный" icon="white-avatar-icon" :text="data.responsible.name" />

      <OrganizationInformationItem header="Статус" icon="white-checkmark-icon" :text="data.statusName || '—'" />

      <OrganizationInformationItem
        header="Индекс реагирования"
        :class-name="data.reaction_index == 0 ? 'black' : 'red'"
        :text="data.reaction_index == 0 ? 'Пациент не пострадал' : 'Пациент пострадал'"
      />
    </div>
    <div class="border-2 border-primary mb-4 px-4">
      <TableInfo :table-data="primaryTableData" />
    </div>

    <div class="mb-3 font-weight-bold d-flex">
      <span class="mr-4">Извещения в контролирующий орган</span>
      <NCheckbox size="large" :checked="data.is_notice == 1" />
    </div>

    <TableInfo :table-data="tableData" />
  </div>
</template>

<script>
import OrganizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import TableInfo from '@/components/widgets/simple-components/table-info/TableInfo'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import { NCheckbox, NButton, NIcon, NPopover, NDatePicker } from 'naive-ui'
import moment from 'moment'
import { Edit } from '@vicons/tabler'
import { mapStores } from 'pinia'
import { useIncidentsStore } from '@/store/incidents'

export default {
  name: 'MainTab',
  components: {
    NextAlert,
    TableInfo,
    OrganizationInformationItem,
    NCheckbox,
    NButton,
    NIcon,
    Edit,
    NPopover,
    NDatePicker
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: this.data.action,
      elimination_date: null
    }
  },
  computed: {
    ...mapStores(useIncidentsStore),
    moment: () => moment,
    tableData() {
      const data = [
        {
          title: 'Дата отправки Извещения',
          value: this.data?.sending_date_rkn ? moment(this.data?.sending_date_rkn).format('DD.MM.YYYY') : ''
        },
        {
          title: 'Подразделение',
          value: this.data?.department?.name
        },
        {
          title: 'Отдел',
          value: this.data?.subdivision?.name
        },
        {
          title: 'Направление',
          value: this.data?.medType?.text
          // url: this.getProcessUrl()
        },
        {
          title: 'Раздел',
          value: this.data?.process?.text
        },
        {
          title: 'Вид нежелательного события',
          value: this.data?.actionCategory?.name
        },
        {
          title: 'Группа нежелательных событий',
          value: this.data?.group?.name
        }
      ]

      return data
    },
    primaryTableData() {
      return [
        {
          title: 'Место нежелательного события',
          value: this.data?.place,
          bolder: true
        },
        {
          title: 'Описание нежелательного события',
          value: this.data?.description,
          bolder: true
        },
        {
          title: 'Последствия нежелательного события',
          value: this.data?.consequence,
          bolder: true
        },
        {
          title: 'Принятые меры по устранению нежелательного события',
          value: this.data?.measures,
          bolder: true
        }
      ]
    },
    showEliminationDateEdit(vm) {
      return vm.data.status !== 3
    }
  },
  watch: {
    data(v) {
      // const process_id = this.process_id
      // this.action = {
      //   ...v?.action,
      //   process: this.action.process,
      //   action_category: this.action.action_category
      // }
      // if (v.action.process_id && process_id !== v.action.process_id) {
      //   this.fetchProcess()
      // }
      // if (v.action.action_category_id) {
      //   this.fetchActionCategory()
      // }
    }
  },
  mounted() {
    // if (this.action.process_id) {
    //   this.fetchProcess()
    // }
    // if (this.action.action_category_id) {
    //   this.fetchActionCategory()
    // }

    this.initEliminationDate()
  },
  methods: {
    initEliminationDate() {
      if (this.data.elimination_date) {
        this.elimination_date = Number(moment(this.data.elimination_date).format('x'))
      }
    },
    onChangeEliminationDate() {
      const { id } = this.$route.params
      const payload = {
        elimination_date: moment(this.elimination_date).format('YYYY-MM-DD')
      }

      this.incidentsStore.update(id, payload)
    }
    // async fetchProcess() {
    //   const res = await this.$api.get(`process/get?id=${this.action.process_id}`)
    //   this.action.process = res.data.data
    // },
    // async fetchActionCategory() {
    //   const res = await this.$api.get(`refbook/get?id=${this.action.action_category_id}`)
    //   this.action.action_category = res.data.data[0]
    // },
    // getProcessUrl() {
    //   const id = this.data.process?.id || this.action.process_id
    //   return id ? `/process/${id}` : null
    // }
  }
}
</script>

<style lang="scss" scoped>
.information-item {
  padding-right: 1.5rem;
  margin-bottom: 2rem;
  width: auto;

  &:last-child {
    padding-right: 0;
  }
}
</style>
