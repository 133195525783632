<script setup>
import { onMounted, ref, computed, watch, defineOptions } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import moment from 'moment'
import debounce from 'lodash/debounce'

import { useIncidentsStore } from '@/store/incidents'
import NextTable from '@/components/widgets/simple-components/table/NextTable'
import EntrySelect from '@/components/common/EntitySelect'

const incidentsStore = useIncidentsStore()
const incidentsStoreRefs = storeToRefs(incidentsStore)
const route = useRoute()
const sort = ref('')

const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1)
const dateStart = ref(moment(firstDayOfYear).format('YYYY-MM-DD'))
const dateEnd = ref('')

const medTypeId = ref('')
const processId = ref('')
const actionCategoryId = ref('')

const medTypeDataReady = ref(false)
const processDataReady = ref(false)
const actionCategoryDataReady = ref(false)
const isRestoring = ref(false)

const saveFilters = () => {
  const filters = {
    dateStart: dateStart.value,
    dateEnd: dateEnd.value,
    medTypeId: medTypeId.value,
    processId: processId.value,
    actionCategoryId: actionCategoryId.value
  }
  localStorage.setItem('incidentFilters', JSON.stringify(filters))
}

const restoreFilters = async () => {
  const savedFilters = JSON.parse(localStorage.getItem('incidentFilters'))
  if (!savedFilters) return

  isRestoring.value = true // Отключаем watch во время восстановления

  dateStart.value = savedFilters.dateStart || moment(firstDayOfYear).format('YYYY-MM-DD')
  dateEnd.value = savedFilters.dateEnd || ''

  medTypeDataReady.value = false
  processDataReady.value = false
  actionCategoryDataReady.value = false

  if (savedFilters.medTypeId) {
    medTypeId.value = savedFilters.medTypeId
    await waitForData(() => medTypeDataReady.value)
  }

  if (savedFilters.processId && medTypeId.value) {
    processId.value = savedFilters.processId
    await waitForData(() => processDataReady.value)
  }

  if (savedFilters.actionCategoryId && processId.value) {
    actionCategoryId.value = savedFilters.actionCategoryId
    await waitForData(() => actionCategoryDataReady.value)
  }

  isRestoring.value = false // Восстановление завершено
}

// Вспомогательная функция для ожидания готовности данных
const waitForData = (condition) => {
  return new Promise((resolve) => {
    const check = () => {
      if (condition()) {
        resolve()
      } else {
        setTimeout(check, 200)
      }
    }
    check()
  })
}

const onMedTypeFetched = () => {
  medTypeDataReady.value = true
}

const onProcessFetched = () => {
  processDataReady.value = true
}

const onActionCategoryFetched = () => {
  actionCategoryDataReady.value = true
}

const fetchDataDebounced = debounce(() => {
  console.log('Fetching data with:', { date_start: dateStart.value, date_end: dateEnd.value })
  incidentsStore.fetch({
    date_start: dateStart.value,
    date_end: dateEnd.value
  })
}, 300)

const fetchData = () => {
  fetchDataDebounced()
}

watch([dateStart, dateEnd, medTypeId, processId, actionCategoryId], () => {
  saveFilters()
  if (!isRestoring.value) {
    fetchData() // Вызываем только если не в процессе восстановления
  }
})

const columns = [
  { key: 'id', title: '№', width: '80px', sortable: true, sortOrder: 'DESC', fontSize: '0.7rem', padding: '10px' },
  {
    key: 'registration_date',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Дата регистрации',
    render: (row, item) => moment(item).format('DD.MM.YYYY')
  },
  {
    key: 'department',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Подразделение'
  },
  {
    key: 'place',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Место'
  },
  {
    key: 'medType',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Направление',
    render: (row, item) => item?.text
  },
  {
    key: 'process',
    fontSize: '0.7rem',
    padding: '10px',
    width: '200px',
    title: 'Раздел ВКК',
    isMultiline: true,
    render: (row, item) => item?.text
  },
  {
    key: 'actionCategory',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Вид нежелательного события',
    isMultiline: true,
    render: (row, item) => item?.name
  },
  {
    key: 'description',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Описание',
    isMultiline: true
  },
  {
    key: 'consequence',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Последствия',
    isMultiline: true
  },
  {
    key: 'measures',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Принятые меры',
    isMultiline: true
  },
  {
    key: 'registrator',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Кто зарегистрировал',
    render: (row, item) => item?.name
  },
  {
    key: 'responsible',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Ответственный',
    render: (row, item) => item?.name
  },
  {
    key: 'statusName',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Статус'
  },
  {
    key: 'elimination_date',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Дата устранения',
    render: (row, item) => (item ? moment(item).format('DD.MM.YYYY') : '')
  },
  {
    key: 'reaction_index',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Индекс реагирования'
  },
  {
    key: 'sending_date_rkn',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Дата извещения',
    render: (row, item) => (item ? moment(item).format('DD.MM.YYYY') : '')
  }
]

const tableData = computed(() => {
  const { value } = incidentsStoreRefs.list
  let arr = [...value]

  if (sort.value === 'ASC') {
    arr.sort((a, b) => Number(a.id) - Number(b.id))
  }

  if (medTypeId.value) {
    arr = arr.filter((risk) => risk.medType.id == medTypeId.value)
  }

  if (processId.value) {
    arr = arr.filter((risk) => risk.process.id == processId.value)
  }

  if (actionCategoryId.value) {
    arr = arr.filter((risk) => risk.actionCategory.id == actionCategoryId.value)
  }

  return arr
})

// const incidentsTypes = [
//   {
//     id: 1,
//     value: 1,
//     name: 'Новое',
//     label: 'Новое',
//     key: 'new'
//   },
//   {
//     id: 2,
//     value: 2,
//     name: 'В работе',
//     label: 'В работе',
//     key: 'in_process'
//   },
//   {
//     id: 3,
//     value: 3,
//     name: 'Закрыто',
//     label: 'Закрыто',
//     key: 'closed'
//   }
// ]

const initFilters = () => {
  dateStart.value = moment(firstDayOfYear).format('YYYY-MM-DD')
  dateEnd.value = ''
  medTypeId.value = ''
  processId.value = ''
  actionCategoryId.value = ''
}

onMounted(async () => {
  const fromPath = route.meta.fromPath
  if (fromPath && fromPath.startsWith('/incidents/')) {
    await restoreFilters()
  } else {
    initFilters()
  }
  fetchData()
})

defineOptions({
  beforeRouteEnter(to, from, next) {
    to.meta.fromPath = from.path
    next()
  }
})

const changeStartDate = (e) => {
  dateStart.value = e.target.value
  // fetchData()
}

const changeEndDate = (e) => {
  dateEnd.value = e.target.value
  // fetchData()
}

const changeSort = (s) => {
  sort.value = s.order
}
</script>

<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header__left">
        <div class="page-header__heading">Журнал учета нежелательных событий</div>
      </div>
    </div>
    <div class="content">
      <div class="d-flex justify-content-between mb-2">
        <div class="d-flex flex-wrap gap-3">
          <div class="dropdown">
            <p class="mb-1">Период</p>
            <div class="d-flex align-items-center">
              <input class="date-input" type="date" :value="dateStart" @change="changeStartDate" />
              <div class="filter-delimiter">-</div>
              <input class="date-input" type="date" :value="dateEnd" @change="changeEndDate" />
            </div>
          </div>
          <!-- <div class="dropdown">
            <p>Статус</p>
            <NSelect
              size="large"
              clearable
              style="width: 280px"
              :options="incidentsTypes"
              :on-update:value="incidentsTypeSelect"
            />
          </div> -->
          <div class="pl-5">
            Количество НС: <br />
            <span style="font-size: 26px">{{ tableData.length }}</span>
          </div>
        </div>
        <!-- <RouterLink :to="`/incidents/create`">
          <NButton type="primary"> Регистрация НС </NButton>
        </RouterLink> -->
      </div>

      <div class="d-flex gap-3 mb-3">
        <div style="width: 23%">
          <p class="mb-1" style="font-size: 14px">Направление</p>
          <EntrySelect
            v-model:value="medTypeId"
            clearable
            request="/organization/get-organization-medtypes"
            multirow
            @fetched="onMedTypeFetched"
          />
        </div>

        <div style="width: 38%">
          <p class="mb-1" style="font-size: 14px">Раздел ВКК</p>
          <EntrySelect
            v-model:value="processId"
            label-field="process.text"
            value-field="process_id"
            clearable
            :disabled="!medTypeId"
            :request="medTypeId ? `/organization/get-processes-by-med-id?id=${medTypeId}` : null"
            multirow
            @fetched="onProcessFetched"
          />
        </div>

        <div style="width: 38%">
          <p class="mb-1" style="font-size: 14px">Наименование НС</p>
          <EntrySelect
            v-model:value="actionCategoryId"
            label-field="name"
            clearable
            :disabled="!medTypeId || !processId"
            :request="`refbook/get?subject=action-category&process_id=${processId}&object_id=2`"
            multirow
            @fetched="onActionCategoryFetched"
          />
        </div>
      </div>

      <NextTable
        class="table-responsive-xl"
        :columns="columns"
        :table-data="tableData"
        :loading="isLoading"
        striped
        style="min-width: 2050px"
        @change:sort="changeSort"
      >
        <template #id="{ value }">
          <RouterLink :to="`/incidents/${value}`">{{ value }}</RouterLink>
        </template>

        <template #registration_date="{ value, row }">
          <!-- <RouterLink :to="`/incidents/${row.id}`" class="!text-gray-800">
            {{ value }}
          </RouterLink> -->
          <span>{{ value }}</span>
        </template>

        <template #department="{ value }">
          <span>{{ value?.name || '—' }}</span>
        </template>

        <template #place="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #med_type_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #process_id="{ value }">
          <span>
            {{ value }}
          </span>
        </template>

        <template #action_category_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #description_id="{ value, row }">
          <span>{{ value }}</span>
        </template>

        <template #consequence_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #measures_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #registrator="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #responsible_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #status="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #elimination_date="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #reaction_index="{ value, row }">
          <div class="flex justify-content-center">
            <div v-if="value == 0" style="background: #717476; width: 20px; height: 20px; border-radius: 100%" />
            <div v-else style="background: #dc3545; width: 20px; height: 20px; border-radius: 100%" />
            <div class="pl-2">{{ row.reaction_index_text }}</div>
          </div>
        </template>

        <template #sending_date_rzn="{ value }">
          <span>{{ value }}</span>
        </template>
      </NextTable>

      <!-- <NButton class="mt-4" type="primary" @click="loadMore">Загрузить больше НС</NButton> -->
      <!-- <div v-if="count / perPage > 1" class="pagination-container">
        <Paginate
          :key="perPage"
          :page-count="Math.ceil(count / perPage)"
          :page-range="5"
          :margin-pages="2"
          :next-text="''"
          :prev-text="''"
          :click-handler="fetch"
        />
      </div> -->
      <!-- <NextAlert v-else type="error" title="Произошла ошибка" :text="error" /> -->
    </div>
  </div>
</template>

<style scoped>
.date-input {
  font-size: 16px;
  line-height: 11px;
  padding: 9px 15px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
}

span.red {
  color: red;
}

.multiline-cut {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  white-space: pre-wrap;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
