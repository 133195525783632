<template>
  <div class="content-block">
    <div class="content-list">
      <div class="list">
        <div class="list-head">
          <div class="date-column list-column sort-item" @click="changeSort('date')">
Дата
            <span class="sort-icon" :class="[{active: sort.type === 'date'}, sort.direction]" />
          </div>
          <div class="assurance-column list-column sort-item" @click="changeSort('assurance')">
Вид проверки
            <span class="sort-icon" :class="[{active: sort.type === 'assurance'}, sort.direction]" />
          </div>
          <div class="status-column list-column sort-item" @click="changeSort('status')">
Результат
            <span class="sort-icon" :class="[{active: sort.type === 'status'}, sort.direction]" />
          </div>
          <div class="commentary-column list-column">Комментарий</div>
          <div class="checklist-column list-column sort-item" @click="changeSort('checklist')">
Отчет
            <span class="sort-icon" :class="[{active: sort.type === 'checklist'}, sort.direction]" />
          </div>
        </div>
        <div class="list-content">
          <historyListItem v-for="(item, key) in history" :key="key" :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import historyListItem from "@/components/widgets/simple-components/items/historyListItem";

export default {
  name: 'HistoryTab',
  components: {
    historyListItem
  },
  data() {
    return {
      history: [],
      isLoading: false,
      sort: {
        type: 'date',
        direction: 'desc',
      }
    }
  },
  mounted() {
    this.updateInformation();
  },
  methods: {
    changeSort(type) {
      if(type === this.sort.type) {
        this.sort.direction = this.sort.direction === 'desc'? 'asc': 'desc'
      } else {
        this.sort.direction = 'desc';
      }
      this.sort.type = type;
      this.updateInformation();
    },
    async updateInformation() {
      this.isLoading = true
      const res = await this.$api.get(`/requirements/get-history?id=${this.$route.params.id}` + '&sort_type='
          + this.sort.type + '&sort_direction=' + this.sort.direction)

      if (res.data.ok === 1) {
        this.history = res.data.data
      }

      this.isLoading = false
    },
  }
}
</script>
<style scoped>
.sort-item {
  cursor: pointer;
}
.sort-icon.desc {
  transform: rotate(180deg);
  background-position-y: -3px;
}
.sort-icon.active {
  opacity: 1;
}
.sort-icon {
  opacity: 0;
  display: inline-block;
  background-size: contain;
  background-position-y: 4px;
  margin-left: 5px;
  width: 17px;
  height: 17px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' viewBox='0 0 32 32'%3E%3Cpath d='M 16 8.59375 L 15.28125 9.28125 L 5.28125 19.28125 L 3.59375 21 L 28.40625 21 L 26.71875 19.28125 L 16.71875 9.28125 Z M 16 11.4375 L 23.5625 19 L 8.4375 19 Z' fill='white'/%3E%3C/svg%3E");
}
.list-head {
  font-size: 14px;
  width: 100%;
  display: flex;
  background: linear-gradient(180deg, #37C489 0%, #00CCCC 100%);
  border-radius: 3px 3px 0px 0px;
  color: white;
}
.list-column {
  border-right: 1px solid white;
  padding: 10px 10px;
}
.assurance-column {
  width: 160px;
  flex: none;
}
.date-column {
  width: 165px;
  flex: none;
}
.checklist-column {
  white-space: nowrap;
  width: 160px;
  flex: none;
}
.commentary-column {
  width: 100%;
}
.status-column {
  width: 115px;
  flex: none;
}

.list {
  min-width: 900px;
}
</style>