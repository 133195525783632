<template>
  <transition name="fade">
    <div v-if="$store.getters.alerts.length > 0" class="warning-widget">
      <transition v-for="warning in $store.getters.alerts" :key="warning.id" name="fade">
        <div class="warning-container" :class="warning.type + '-type'">
          <div class="warning-container__text">{{ warning.text }}</div>
          <div class="warning-container__close pointer" @click="$store.dispatch('ALERT_DELETE', warning)" />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AlertManager"
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.warning-widget .warning-container__close {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1 1L6.25 6.25M11.5 11.5L6.25 6.25M6.25 6.25L11.5 1L1 11.5' stroke='%23626465'/%3E%3C/svg%3E");
  width: 11px;
  height: 11px;
  margin-right: 30px;
  flex: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.warning-widget {
  position: fixed;
  right: 0;
  top: 50px;
  z-index: 10000000;
}
.warning-container .warning-container__text {
  font-weight: 400;
  font-size: 14px;
  color: #343434;
}
.warning-container.warning-type {
  box-shadow: 0px 8px 20px rgba(255, 229, 60, 0.15);
}
.warning-container.error-type {
  box-shadow: 0px 8px 20px rgb(223 52 43 / 10%);
}
.warning-container.success-type {
  box-shadow: 0px 8px 20px rgba(60, 122, 255, 0.15);
}
.warning-container {
  border-left: 7px solid transparent;
  margin-bottom: 10px;
  padding-left: 25px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-top: 24px;
  padding-bottom: 24px;
  background: #FFFFFF;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 390px;
}
.warning-container.success-type {
  border-color: #1CC8AB;
}
.warning-container.error-type {
  border-color: #ef3e34;
}
.warning-container.warning-type {
  border-left-color: #ffcd65;
}


</style>