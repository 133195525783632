<template>
  <div v-if="process" class="page">
    <div class="page-header">
      <div class="page-header__left">
        <div class="page-header__text">
          <div class="page-header__heading">Раздел №{{ process.number }}</div>
        </div>
        <div class="page-header__subheading">{{ process.text }}</div>
      </div>
    </div>

    <NAlert v-if="!hasPermissionByMedtype" class="mb-5" :bordered="false" title="Доступен просмотр" type="info">
      Для получения полного доступа необходимо сменить тариф
    </NAlert>

    <div :class="{ 'disabled-by-tarif': !hasPermissionByMedtype }">
      <div class="process-info d-flex">
        <OrganizationInformationItem :text="process.medtype" header="Направление" icon="white-plus-icon" class="mr-4" />
        <OrganizationInformationItem
          class="w-88"
          :text="responsibleUser?.name || '–'"
          header="Ответственный"
          icon="white-avatar-icon"
        />
        <OrganizationInformationItem
          class="w-100"
          :text="commissionMembersNames || '–'"
          header="Рабочая группа"
          icon="white-avatar-icon"
        />
      </div>
      <div class="content">
        <div class="tabs">
          <template v-for="{ key, title, hide } in tabs">
            <div v-if="!hide" :key="key" class="tab" :class="{ active: currentTab === key }" @click="currentTab = key">
              {{ title }}
            </div>
          </template>
        </div>
        <div class="tab-content overflow-auto">
          <VkkTab v-if="currentTab === 'vkk'" :process="process" />
          <DocumentsTab v-if="currentTab === 'documents'" :process="process" />
          <ParametersTab v-if="currentTab === 'indicators'" :process="process" />
          <SettingsTab v-if="currentTab === 'settings' && (currentUser.vkk || userIsSuperAdmin)" :process="process" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters, mapActions } from 'vuex'
import { NAlert } from 'naive-ui'
import OrganizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import VkkTab from '@/components/pages/ProcessPage/processTabs/vkkTab'
import DocumentsTab from '@/components/pages/ProcessPage/processTabs/documentsTab'
import SettingsTab from '@/components/pages/ProcessPage/processTabs/settingsTab'
import ParametersTab from '@/components/pages/ProcessPage/processTabs/ParametersTab'
import useTarifExtension from '@/composables/useTarifExtension'

export default {
  name: 'ProcessPage',
  components: { OrganizationInformationItem, VkkTab, DocumentsTab, SettingsTab, ParametersTab, NAlert },
  setup() {
    const { hasPermission } = useTarifExtension()
    return {
      hasPermission
    }
  },
  data() {
    return {
      process: false,
      currentTab: 'vkk',
      responsibleUser: null
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'commissionMembers']),
    userIsSuperAdmin: () => JSON.parse(localStorage.user).role_id === 1,
    tabs(vm) {
      return [
        { key: 'vkk', title: 'Требования' },
        { key: 'documents', title: 'Документы' },
        { key: 'indicators', title: 'Показатели' },
        { key: 'settings', title: 'Настройки', hide: !(this.currentUser?.vkk || vm.userIsSuperAdmin) }
      ]
    },
    commissionMembersNames() {
      return this.commissionMembers.map((user) => user.name).join(', ')
    },
    hasPermissionByMedtype() {
      console.log('this.process', this.process)

      if (this.hasPermission && this.process) {
        if (['Организация ВКК и БМД'].includes(this.process.medtype) && this.hasPermission(12)) {
          return true
        }
        if (['Стоматология', 'Поликлиника'].includes(this.process.medtype) && this.hasPermission(13)) {
          return true
        }
        if (['Клиника/подразделение ВРТ'].includes(this.process.medtype) && this.hasPermission(35)) {
          return true
        }
        if (
          ['Стационар', 'Медицинская лаборатория', 'Центр диализа', 'Скорая медицинская помощь'].includes(
            this.process.medtype
          ) &&
          this.hasPermission(14)
        ) {
          return true
        }
        if (['Чек-листы (дополнительные)'].includes(this.process.medtype) && this.hasPermission(41)) {
          return true
        }
      }
      return false
    }
  },
  mounted() {
    if (this.$route.fullPath.includes('indicators')) {
      this.currentTab = 'indicators'
    }

    this.fetch()
    this.fetchCommissionMembers({
      process_id: this.$router.currentRoute.value.params.id
    })
  },
  methods: {
    ...mapActions({
      fetchCommissionMembers: 'PROCESS_GET_COMMISSION_MEMBERS'
    }),
    async fetch() {
      const result = await this.$api.get('/process/get', {
        params: {
          id: this.$router.currentRoute.value.params.id
        }
      })
      if (result.data.ok === 1) {
        this.process = result.data.data

        this.getResponsibleUsers()
      }
    },
    async getResponsibleUsers() {
      const res = await api.get('responsible/get')

      if (res.data.ok === 1) {
        this.responsibleUsers = res.data.data
        this.responsibleUser = res.data.data.find((item) => item.id === this.process.responsible_id)
      }
    },
    setResponsibleId(userId) {
      const user = this.responsibleUsers.find((u) => u.id === userId)
      this.responsibleUser = user
    }
  }
}
</script>

<style scoped>
.page-header {
  padding-bottom: 15px;
}

.page-subheader {
  padding-bottom: 30px;
}

.process-info {
  padding-bottom: 35px;
}

.tab-content {
  min-height: 500px;
}
</style>
