<template>
  <table class="table next-table">
    <thead v-if="columns" class="next-table__thead">
      <tr>
        <NextTableTh
          v-for="col in columns"
          :key="col.key"
          :col="col"
          @change:sort="(sort) => $emit('change:sort', sort)"
        />
      </tr>
    </thead>
    <tbody class="next-table__body">
      <tr v-if="loading">
        <td :colspan="columns.length">
          <SpinLoader class="py-4" size="xl" />
        </td>
      </tr>
      <tr v-else-if="!tableData || tableData.length === 0">
        <td class="text-center py-4 text-secondary" :colspan="columns.length">Нет данных</td>
      </tr>
      <template v-else>
        <tr v-for="(row, i) in tableData" :key="`row_${i}`" :class="row.rowClass">
          <td
            v-for="{ key, center, render = (row, v) => v, isMultiline } in columns"
            :key="`row_${i}_${key}`"
            :class="{ 'bg-gray-100': striped && i % 2 === 1 }"
          >
            <slot :key="key" :name="key" :row="row" :value="render(row, row[key])">
              <span
                :class="{
                  'text-center': center,
                  'cursor-pointer': isMultiline,
                  'multiline-cut': isMultiline && !expandedRows[row.id]?.[key],
                }"
                @click="isMultiline ? toggleExpand(row.id, key) : null"
              >
              {{ render(row, row[key]) ?? placeholder }}
              </span>
            </slot>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { ref } from 'vue'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextTableTh from './NextTableTh'

export default {
  name: 'NextTable',
  components: { SpinLoader, NextTableTh },
  props: {
    columns: Object,
    tableData: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: '—'
    },
    emptyText: {
      type: String,
      default: 'Нет данных'
    },
    loading: Boolean,
    striped: Boolean,
  },
  emits: ['change:sort'],
  setup() {
    const expandedRows = ref({});

    const toggleExpand = (rowId, key) => {
      if (!expandedRows.value[rowId]) {
        expandedRows.value[rowId] = {};
      }
      expandedRows.value[rowId][key] = !expandedRows.value[rowId][key];
    };

    return { expandedRows, toggleExpand };
  },
}
</script>

<style scoped>
.multiline-cut {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  white-space: pre-wrap;
}
</style>