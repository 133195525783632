<template>
  <table
    role="presentation"
    width="100%"
    cellspacing="0"
    cellpadding="0"
    border="0"
    style="font-family: Arial, sans-serif; margin: 0; padding: 0"
  >
    <tr>
      <td align="center">
        <table
          role="presentation"
          width="600"
          cellspacing="0"
          cellpadding="0"
          border="0"
          style="background: #ffffff; padding: 20px; border-radius: 8px; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)"
        >
          <!-- Заголовок письма -->
          <tr>
            <td
              style="
                background: #21bd9a;
                color: white;
                padding: 15px;
                font-size: 20px;
                font-weight: bold;
                text-align: center;
              "
            >
              Уведомление системы ВКК+БМД
            </td>
          </tr>

          <!-- Основной текст -->
          <tr>
            <td style="padding: 20px; font-size: 16px; color: #333">
              <p>Здравствуйте!</p>
              <p>Уведомляем Вас о следующем событии:</p>
              <p>
                <strong>Администрация (или ФИО) добавил новый документ:</strong>
                <a href="#" style="color: #3b82f6; text-decoration: none">Название документа</a>
              </p>
              <p><strong>2023-12-26 05:03:51</strong></p>
              <p>С уважением,<br>Ваша команда «ВКК+БМД»!</p>
            </td>
          </tr>

          <!-- Подвал письма -->
          <tr>
            <td style="background: #e3f5ef; padding: 15px; font-size: 14px; color: #555">
              <p>
                Вы получили данное письмо, т.к. являетесь зарегистрированным пользователем системы
                <strong>«ВКК+БМД»</strong>. Если Вы получили письмо по ошибке, напишите об этом по адресу:
                <a href="mailto:mailer@vkkmed.ru" style="color: #3b82f6; text-decoration: none">mailer@vkkmed.ru</a>
              </p>
              <p>Это письмо отправлено с помощью системы <strong>«ВКК+БМД»</strong>.</p>
              <p>
                Подробнее на сайте:
                <a href="https://vkkinform.ru/" style="color: #3b82f6; text-decoration: none">https://vkkinform.ru/</a>
              </p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

  <div style="height: 150px" />

  <table
    role="presentation"
    width="100%"
    cellspacing="0"
    cellpadding="0"
    border="0"
    style="font-family: Arial, sans-serif; margin: 0; padding: 0"
  >
    <tr>
      <td align="center">
        <table
          role="presentation"
          width="600"
          cellspacing="0"
          cellpadding="0"
          border="0"
          style="background: #ffffff; padding: 20px; border-radius: 8px; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)"
        >
          <!-- Блок для администратора -->
          <tr>
            <td
              style="
                background: #21bd9a;
                color: white;
                padding: 15px;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
              "
            >
              Уведомление системы ВКК+БМД
            </td>
          </tr>
          <tr>
            <td style="padding: 20px; font-size: 16px; color: #333">
              <p style="color: tomato">
                (УДОЛИТЬ!!1) Для всех пользователей Системы (по выбранному направлению в раздел
                «Организация»)(УДОЛИТЬ!!1)
              </p>
              <p>
                Администрация добавил новый документ: <br>
                <a href="#" style="color: #3b82f6; text-decoration: none; font-weight: bold">Предложения (Практические рекомендации) по организации внутреннего контроля качества...</a>
              </p>
              <p><strong>2023-12-26 05:03:51</strong></p>
            </td>
          </tr>

          <!-- Блок для сотрудника организации -->
          <tr>
            <td
              style="
                background: #21bd9a;
                color: white;
                padding: 15px;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
              "
            >
              Уведомление системы ВКК+БМД
            </td>
          </tr>
          <tr>
            <td style="padding: 20px; font-size: 16px; color: #333">
              <p style="color: tomato">(УДОЛИТЬ!!1) Для всех пользователей конкретной Организации(УДОЛИТЬ!!1)</p>
              <p>
                ФИО добавил новый документ:<br>
                <a href="#" style="color: #3b82f6; text-decoration: none; font-weight: bold">Предложения (Практические рекомендации) по организации внутреннего контроля качества...</a>
              </p>
              <p><strong>2023-12-26 05:03:51</strong></p>
            </td>
          </tr>

          <!-- Подвал письма -->
          <tr>
            <td style="background: #e3f5ef; padding: 15px; font-size: 14px; color: #555">
              <p>
                Вы получили данное письмо, т.к. являетесь зарегистрированным пользователем системы
                <strong>«ВКК+БМД»</strong>. Если Вы получили письмо по ошибке, напишите об этом по адресу:
                <a href="mailto:mailer@vkkmed.ru" style="color: #3b82f6; text-decoration: none">mailer@vkkmed.ru</a>
              </p>
              <p>Это письмо отправлено с помощью системы <strong>«ВКК+БМД»</strong>.</p>
              <p>
                Подробнее на сайте:
                <a href="https://vkkinform.ru/" style="color: #3b82f6; text-decoration: none">https://vkkinform.ru/</a>
              </p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

  <div style="height: 150px" />

  <table
    width="100%"
    cellpadding="0"
    cellspacing="0"
    border="0"
    style="margin: 0; padding: 0; font-family: Arial, sans-serif"
  >
    <tr>
      <td align="center" style="padding: 20px 0">
        <table
          width="600"
          cellpadding="0"
          cellspacing="0"
          border="0"
          style="background-color: #ffffff; border: 1px solid #dddddd"
        >
          <tr>
            <td
              style="
                background: #21bd9a;
                color: white;
                padding: 15px;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
              "
            >
              Уведомление системы ВКК+БМД
            </td>
          </tr>
          <tr>
            <td style="padding: 20px">
              <!-- <h1 style="font-size: 24px; color: #333333;">Уведомление системы ВКК+БМД</h1> -->
              <p style="font-size: 16px; color: #555555">Здравствуйте!</p>
              <p style="font-size: 16px; color: #555555">Уведомляем Вас о следующем событии:</p>
              <ul style="list-style-type: none; padding: 0">
                <li style="font-size: 16px; color: #555555; margin-bottom: 10px">
                  <strong>Триггер (НС статус Новое)</strong> Зарегистрировано новое Нежелательное событие: {№НС +
                  ссылка} + {Вид нежелательного события}<br>
                  {год-месяц-число 00:00:00}
                </li>
                <li style="font-size: 16px; color: #555555; margin-bottom: 10px">
                  <strong>Триггер (НС статус Взято в работу)</strong> Взято в работу Нежелательное событие: {№НС +
                  ссылка} + {Вид нежелательного события}<br>
                  {год-месяц-число 00:00:00}
                </li>
                <li style="font-size: 16px; color: #555555; margin-bottom: 10px">
                  <strong>Триггер (НС статус Закрыто)</strong> Закрыто Нежелательное событие: {№НС + ссылка} + {Вид
                  нежелательного события}<br>
                  {год-месяц-число 00:00:00}
                </li>
              </ul>
            </td>
          </tr>

          <!-- Подвал письма -->
          <tr>
            <td style="background: #e3f5ef; padding: 15px; font-size: 14px; color: #555">
              <p>
                Вы получили данное письмо, т.к. являетесь зарегистрированным пользователем системы
                <strong>«ВКК+БМД»</strong>. Если Вы получили письмо по ошибке, напишите об этом по адресу:
                <a href="mailto:mailer@vkkmed.ru" style="color: #3b82f6; text-decoration: none">mailer@vkkmed.ru</a>
              </p>
              <p>Это письмо отправлено с помощью системы <strong>«ВКК+БМД»</strong>.</p>
              <p>
                Подробнее на сайте:
                <a href="https://vkkinform.ru/" style="color: #3b82f6; text-decoration: none">https://vkkinform.ru/</a>
              </p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

  <div style="height: 150px" />

  <table
    width="100%"
    cellpadding="0"
    cellspacing="0"
    border="0"
    style="margin: 0; padding: 0; font-family: Arial, sans-serif"
  >
    <tr>
      <td align="center" style="padding: 20px 0">
        <table
          width="600"
          cellpadding="0"
          cellspacing="0"
          border="0"
          style="background-color: #ffffff; border: 1px solid #dddddd"
        >
          <tr>
            <td
              style="
                background: #21bd9a;
                color: white;
                padding: 15px;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
              "
            >
              Уведомление системы ВКК+БМД
            </td>
          </tr>
          <tr>
            <td style="padding: 20px">
              <p style="font-size: 16px; color: #555555">Здравствуйте!</p>
              <p style="font-size: 16px; color: #555555">Уведомляем Вас о следующем событии:</p>
              <ul style="list-style-type: none; padding: 0">
                <li style="font-size: 16px; color: #555555; margin-bottom: 10px">
                  <strong>Триггер (НС статус Новое)</strong> Зарегистрировано новое Нежелательное событие: {№НС +
                  ссылка} + {Вид нежелательного события}<br>
                  {год-месяц-число 00:00:00}
                </li>
                <li style="font-size: 16px; color: #555555; margin-bottom: 10px">
                  <strong>Триггер (НС статус Взято в работу)</strong> Взято в работу Нежелательное событие: {№НС +
                  ссылка} + {Вид нежелательного события}<br>
                  {год-месяц-число 00:00:00}
                </li>
                <li style="font-size: 16px; color: #555555; margin-bottom: 10px">
                  <strong>Триггер (НС статус Закрыто)</strong> Закрыто Нежелательное событие: {№НС + ссылка} + {Вид
                  нежелательного события}<br>
                  {год-месяц-число 00:00:00}
                </li>
              </ul>
            </td>
          </tr>

          <!-- Подвал письма -->
          <tr>
            <td style="background: #e3f5ef; padding: 15px; font-size: 14px; color: #555">
              <p>
                Вы получили данное письмо, т.к. являетесь зарегистрированным пользователем системы
                <strong>«ВКК+БМД»</strong>. Если Вы получили письмо по ошибке, напишите об этом по адресу:
                <a href="mailto:mailer@vkkmed.ru" style="color: #3b82f6; text-decoration: none">mailer@vkkmed.ru</a>
              </p>
              <p>Это письмо отправлено с помощью системы <strong>«ВКК+БМД»</strong>.</p>
              <p>
                Подробнее на сайте:
                <a href="https://vkkinform.ru/" style="color: #3b82f6; text-decoration: none">https://vkkinform.ru/</a>
              </p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <div style="height: 150px" />
</template>
