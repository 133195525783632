<template>
  <div class="container container-center content">
    <div class="login-container">
      <div class="logo-container">
        <whiteLogo />
        <h5 v-if="isDev">(Обучающая версия)</h5>
      </div>
      <LoginForm />
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/widgets/custom-components/forms/LoginForm'
import whiteLogo from '@/components/widgets/simple-components/logo/whiteLogo'
export default {
  name: 'LoginPage',
  components: {
    LoginForm,
    whiteLogo
  },
  data() {
    const isDev = location.host === 'dev-front.vkkmed.ru'

    return {
      isDev,
      email: '',
      password: '',
      errorMessage: ''
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile')
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  padding-top: 60px;
  padding-bottom: 60px;
}
.container {
  z-index: 1;
}
.logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;

  h5 {
    color: #fff;
  }
}
.login-form {
  display: flex;
  flex-direction: column;
}
.login-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 470px;
}
</style>
