<template>
  <div class="page-content">
    <SpinLoader v-if="isLoading" class="py-5" size="2xl" />
    <template v-else>
      <NextAlert v-if="isError" class="mb-4" type="error" :text="isError" />
      <Documents
        type="requirement"
        :items="templates"
        :document-id="requirement.id"
        hide-heading
      />
    </template>
  </div>
</template>

<script>
import Documents from '@/components/widgets/custom-components/tabs/organization-tabs/Documents'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'

export default {
  name: 'DocumentsTab',
  components: { NextAlert, SpinLoader, Documents },
  props: {
    requirement: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      isError: false,
      templates: []
    }
  },
  mounted() {
    this.fetchTemplates()
  },
  methods: {
    async fetchTemplates() {
      try {
        this.isLoading = true
        this.isError = false

        const res = await this.$api.get(`/requirements/get-files?id=${this.$route.params.id}`)

        this.templates = res.data.data
      } catch (e) {
        this.isError = e.message
      } finally {
        this.isLoading = false
      }
    },
    fetchAll() {
      this.isLoading = true
      this.isError = false

      return Promise
        .all([
          this.$api.get(`/requirement/get-files?id=${this.$route.params.id}`)
        ])
        .then((res) => {
          console.log(res)
        })
        .catch((e) => {
          this.isError = e.message
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
.page-content {
  min-width: 830px;
}
</style>