<template>
  <div class="page-header">Профиль: {{ $store.getters.currentUser.name }}</div>
  <div class="tabs">
    <div
        v-for="(item, key) in tabs"
        :key="key"
        class="tab"
        :class="{ active: activeTab === item.id}"
        @click="openTab(item.id)"
    >
      {{ item.text }}
    </div>
  </div>
  <div class="tab-content">
    <profileInfo v-if="activeTab === 'main'" />
    <notifySettings v-if="activeTab === 'settings'" />
  </div>
</template>

<script>
import profileInfo from "@/components/pages/ProfilePage/components/profileInfo";
import notifySettings from "@/components/pages/ProfilePage/components/notifySettings";
export default {
  name: "ProfileMain",
  components: {profileInfo, notifySettings},
  data() {
    return {
      activeTab: 'main',
      tabs: [
        { id: 'main', text: 'Личные данные' },
        { id: 'settings', text: 'Настройки' },
      ]
    }
  },
  methods: {
    openTab(id) {
      this.activeTab = id
    }
  }
}
</script>

<style scoped>

</style>