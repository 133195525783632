import api from '@/api'

const endpoint = 'meetings/'
// let checklistStatuses = []
// checklistStatuses[1] = 'На проверке'
// checklistStatuses[2] = 'Проверка завершена'
// checklistStatuses[3] = 'Подписан'

const checklist = {
  state: {
    isLoading: false,
    inspection: {},
    inspectionMembers: [],
    inspectionList: [],
    inspectionStatuses: [],
    inspectionTypes: [],
    inspectionQueueList: [],
    inspectionIssuesList: [],
    inspectionFiles: []
  },
  getters: {
    inspection(state) {
      return {
        ...state.inspection,
        members: state.inspectionMembers,
        issues: state.inspectionIssuesList.map((issue) => ({
          ...issue,
          responsible: state.inspectionMembers.find((member) => issue.responsible_id == member.id)
        }))
      }
    },
    inspectionMembers(state) {
      return state.inspectionMembers
    },
    inspectionList(state) {
      return state.inspectionList
    },
    inspectionStatuses(state) {
      return state.inspectionStatuses
    },
    inspectionTypes(state) {
      return state.inspectionTypes
    },
    inspectionQueueList(state) {
      return state.inspectionQueueList
    },
    inspectionFiles(state) {
      return state.inspectionFiles
    }
  },
  mutations: {
    set_inspection_list(state, payload) {
      state.inspectionList = payload
    },
    set_inspection_statuses(state, payload) {
      state.inspectionStatuses = payload
    },
    set_inspection_types(state, payload) {
      state.inspectionTypes = payload
    },
    set_inspection_queue_list(state, payload) {
      state.inspectionQueueList = payload
    },
    set_is_loading(state, payload) {
      state.isLoading = payload
    },
    set_inspection(state, payload) {
      state.inspection = payload
    },
    set_inspection_members(state, payload) {
      state.inspectionMembers = payload
    },
    add_inspection_issues_list(state, payload) {
      state.inspectionIssuesList = payload
    },
    set_inspection_files(state, payload) {
      state.inspectionFiles = payload
    }
  },
  actions: {
    INSPECTION_DOCUMENTS_GET_ALL(context, payload) {
      return api.get(
        endpoint +
          'get-documents?id=' +
          payload.id +
          '&limit=' +
          payload.limit +
          '&offset=' +
          payload.offset +
          '&category_id=' +
          payload.category_id +
          '&sort_type=' +
          payload.sort.type +
          '&sort_direction=' +
          payload.sort.direction
      )
    },
    INSPECTION_GET_LIST(context, payload) {
      const url = `${endpoint}index`

      const params = {
        status_id: payload.status_id,
        type_id: payload.type_id
      }

      context.commit('set_is_loading', true)

      try {
        api
          .get(url, {
            params
          })
          .then((res) => {
            if (res.status === 200) {
              context.commit('set_inspection_list', res.data)
            }
          })
          .finally(() => {
            context.commit('set_is_loading', false)
          })
      } catch (error) {
        console.warn(`INSPECTION_GET_LIST: ${error}`)
      }
    },
    INSPECTION_GET_STATUSES(context, payload) {
      const url = `${endpoint}statuses`

      try {
        api.get(url).then((res) => {
          if (res.status === 200) {
            context.commit('set_inspection_statuses', res.data)
          }
        })
      } catch (error) {
        console.warn(`INSPECTION_GET_STATUSES: ${error}`)
      }
    },
    INSPECTION_GET_TYPES(context, payload) {
      const url = `${endpoint}types`

      try {
        api.get(url).then((res) => {
          if (res.status === 200) {
            context.commit('set_inspection_types', res.data)
          }
        })
      } catch (error) {
        console.warn(`INSPECTION_GET_TYPES: ${error}`)
      }
    },
    INSPECTION_SET_QUEUE_LIST(context, payload) {
      const url = `${endpoint}queue`
      try {
        api.get(url).then((res) => {
          if (res.status === 200) {
            context.commit('set_inspection_queue_list', res.data)
          }
        })
      } catch (error) {
        console.warn(`INSPECTION_SET_QUEUE_LIST: ${error}`)
      }
    },
    INSPECTION_CREATE(context, payload) {
      try {
        return api.post(endpoint, payload)
      } catch (error) {
        console.warn(`INSPECTION_SET_QUEUE_LIST: ${error}`)
      }
    },
    INSPECTION_GET_BY_ID(context, payload) {
      const url = `${endpoint}${payload}`

      try {
        api.get(url).then((res) => {
          if (res.status === 200) {
            context.commit('set_inspection', res.data)
          }
        })
      } catch (error) {
        console.warn(`INSPECTION_GET_BY_ID: ${error}`)
      }

      context.dispatch('INSPECTION_GET_MEMBERS', payload)
      context.dispatch('INSPECTION_GET_ISSUES_LIST', payload)
    },
    INSPECTION_GET_MEMBERS(context, payload) {
      const url = `${endpoint}members?meeting_id=${payload}`

      try {
        api.get(url).then((res) => {
          if (res.status === 200) {
            context.commit('set_inspection_members', res.data)
          }
        })
      } catch (error) {
        console.warn(`INSPECTION_GET_BY_ID: ${error}`)
      }
    },
    INSPECTION_ADD_ISSUE(context, payload) {
      const url = `${endpoint}issues?meeting_id=${payload.meeting_id}`
      try {
        return api.post(url, payload)
      } catch (error) {
        console.warn(`INSPECTION_ADD_ISSUE: ${error}`)
      }
    },
    INSPECTION_EDIT_ISSUE(context, payload) {
      const url = `${endpoint}issues?meeting_id=${payload.meeting_id}`
      const data = {
        issue_id: payload.issue_id,
        name: payload.name,
        solution: payload.solution,
        responsible_id: payload.responsible_id,
        voting_results: payload.voting_results
      }

      try {
        return api.patch(url, data)
      } catch (error) {
        console.warn(`INSPECTION_EDIT_ISSUE: ${error}`)
      }
    },
    INSPECTION_GET_ISSUES_LIST(context, payload) {
      const url = `${endpoint}issues?meeting_id=${payload}`

      try {
        api.get(url).then((res) => {
          if (res.status === 200) {
            context.commit('add_inspection_issues_list', res.data)
          }
        })
      } catch (error) {
        console.warn(`INSPECTION_GET_ISSUES_LIST: ${error}`)
      }
    },
    INSPECTION_DELETE_ISSUE(context, payload) {
      const url = `${endpoint}issues?meeting_id=${payload.meeting_id}`
      try {
        return api.delete(url, {
          data: {
            issue_id: payload.issue_id
          }
        })
      } catch (error) {
        console.warn(`INSPECTION_DELETE_ISSUE: ${error}`)
      }
    },
    INSPECTION_EDIT(context, payload) {
      const url = `${endpoint}${payload.meeting_id}/edit`

      try {
        return api.patch(url, payload)
      } catch (error) {
        console.warn(`INSPECTION_EDIT: ${error}`)
      }
    },
    INSPECTION_SET_COMPLETE(context) {
      const { inspection } = context.getters

      const payload = {
        status_id: 3, // завершен
        number: inspection.number,
        type_id: inspection.type_id,
        organization_id: inspection.organization_id,
        datetime: inspection.datetime,
        queue_id: inspection.queue_id,
        chairman_id: inspection.chairman_id,
        deputy_id: inspection.deputy_id,
        secretary_id: inspection.secretary_id,
        members: inspection.members,
        meeting_id: inspection.id
      }

      return context.dispatch('INSPECTION_EDIT', payload)
    },
    INSPECTION_DELETE(context, payload) {
      const url = `${endpoint}${payload}`
      return api.delete(url)
    },
    INSPECTION_DOCUMENTS_UPLOAD_FILE(context, payload) {
      const url = `${endpoint}${payload.meeting_id}/upload`

      return api.post(url, payload.formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    INSPECTION_GET_DOCUMENTS(context, payload) {
      return api.get(
        endpoint +
          'get-documents?id=' +
          payload.id +
          '&limit=' +
          payload.limit +
          '&offset=' +
          payload.offset +
          '&category_id=' +
          payload.category_id +
          '&sort_type=' +
          payload.sort.type +
          '&sort_direction=' +
          payload.sort.direction
      )
    },
    // async INSPECTION_GET_DOCUMENTS(context, payload) {
    //   const url = `${endpoint}${payload.meeting_id}/files`

    //   const response = await api.get(url)

    //   try {
    //     if (response.status === 200 && response.data.ok === 1) {
    //       const { documentsCategory } = context.getters

    //       const normalizedData = response.data.data.map((doc) => ({
    //         ...doc,
    //         uploaded_at: doc.uploaded_at * 1000,
    //         category: documentsCategory.find((docCategory) => docCategory.id === doc.category_id)
    //       }))
    //       context.commit('set_inspection_files', normalizedData)
    //     }
    //   } catch (error) {
    //     console.warn(`INSPECTION_GET_DOCUMENTS: ${error}`)
    //   }
    // },
    async INSPECTION_DELETE_FILE(context, payload) {
      const url = `${endpoint}${payload.meeting_id}/files/${payload.file.id}/delete`

      try {
        return api.delete(url)
      } catch (error) {
        console.warn(`INSPECTION_DELETE_FILE: ${error}`)
      }
    },
    async INSPECTION_EDIT_FILE(context, payload) {
      const url = `${endpoint}${payload.meeting_id}/files/${payload.file_id}/edit`
      const data = {
        name: payload.name,
        category_id: payload.category_id
      }

      return api.patch(url, data)
    }
  }
}
export default checklist
