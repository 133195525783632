<template>
  <div class="content-block">
    <div v-if="hideHeading" class="content-header">
      {{ pageHeader }}
    </div>
    <div class="documents-controls">
      <dropdownListWithHeader
        v-if="documentTypes.length > 0"
        class="document-types"
        header="Категории документов"
        :search="false"
        :list="documentTypes"
        :start-item="documentType"
        @selectHandler="changeType"
      />
      <nextButton type="plus" color="green" :text="buttonText" @clickHandler="openDocumentsUpload" />
    </div>
    <div class="content-info">
      <organizationDocumentsList
        :sort="sort"
        :document-id="documentId"
        :type="type"
        :items="list"
        @changeSort="changeSort"
        @deleteDocument="deleteDocument"
      />
      <Paginate
        v-if="count / perPage > 1"
        :key="perPage"
        :page-count="Math.ceil(count / perPage)"
        :page-range="5"
        :margin-pages="2"
        :next-text="''"
        :prev-text="''"
        :click-handler="changePage"
      />
    </div>
    <modalWindow v-if="uploadOpened" header="Загрузка документа" @closeHandler="uploadOpened = false">
      <uploadDocuments
        :id="documentId"
        :type="type"
        :is-loading="isLoading"
        @uploadHandler="uploadHandler"
        @closeHandler="uploadOpened = false"
      />
    </modalWindow>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate-next'
import modalWindow from '@/components/widgets/custom-components/modalWindow/modalWindow'
import uploadDocuments from '@/components/widgets/custom-components/modalWindow/modalComponents/uploadDocuments'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import organizationDocumentsList from '@/components/widgets/custom-components/lists/organizationDocumentsList'
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import paginationMixin from '@/components/mixins/paginationMixin'

export default {
  name: 'DocumentsTab',
  components: { modalWindow, uploadDocuments, nextButton, organizationDocumentsList, dropdownListWithHeader, Paginate },
  mixins: [paginationMixin],
  props: {
    type: {
      type: String,
      required: true
    },
    documentId: {
      required: true
    },
    hideHeading: Boolean,
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      buttonText: 'Добавить файл',
      uploadOpened: false,
      documentTypes: [],
      sort: {
        type: 'date',
        direction: 'desc'
      },
      list: [],
      documentType: { id: 0, text: 'Все' },
      isLoading: false
    }
  },
  computed: {
    pageHeader() {
      if (this.type === 'organization') {
        return ' Документы организации:'
      } else if (this.type === 'process') {
        return ' Документы раздела:'
      } else if (this.type === 'requirement') {
        return ' Документы требования:'
      } else if (this.type === 'action') {
        return ' Документы мероприятия:'
      } else if (this.type === 'control') {
        return ' Документы плана мероприятия:'
      } else {
        return ' Документы:'
      }
    }
  },
  beforeMount() {
    if (this.type === 'organization') {
      this.buttonText = 'Добавить документ для организации'
    } else if (this.type === 'process') {
      if (this.documentId === '') {
        this.buttonText = 'Добавить документ для разделов'
      } else {
        this.buttonText = 'Добавить документ для раздела'
      }
    } else if (this.type === 'requirement') {
      this.buttonText = 'Добавить документ для требования'
    } else if (this.type === 'action') {
      this.buttonText = 'Добавить документ для мероприятия'
    } else if (this.type === 'control') {
      this.buttonText = 'Добавить документ для плана проверки'
    } else {
      this.buttonText = 'Добавить документ'
    }
  },
  mounted() {
    this.$api.get('documents/get-types').then((res) => {
      if (res.data.ok === 1) {
        this.documentTypes.push({ id: 0, text: 'Все' })
        this.documentTypes = [...this.documentTypes, ...res.data.data]
      }
    })
    this.changePage(1)
  },
  methods: {
    changeSort(sort) {
      this.sort = sort
      this.changePage(this.page)
    },
    deleteDocument() {
      this.changePage(this.page)
    },
    async uploadHandler(val) {
      this.isLoading = true

      val.append('type', this.type)
      let result = await this.$store.dispatch('DOCUMENTS_UPLOAD_FILE', val)
      if (result.data.ok === 1) {
        this.changePage(this.page)
        this.uploadOpened = false
      }

      this.isLoading = false
    },
    changePage(val) {
      this.page = val
      let action = ''
      switch (this.type) {
        case 'organization':
          action = 'ORGANIZATION_DOCUMENTS_GET_ALL'
          break
        case 'process':
          action = 'PROCESS_DOCUMENTS_GET_ALL'
          break
        case 'requirement':
          action = 'REQUIREMENT_DOCUMENTS_GET_ALL'
          break
        case 'action':
          action = 'ACTION_DOCUMENTS_GET_ALL'
          break
        case 'control':
          action = 'CONTROL_GET_DOCUMENTS'
          break
        case 'meetings':
          action = 'INSPECTION_DOCUMENTS_GET_ALL'
          break
        case 'inevent':
          action = 'INEVENT_DOCUMENTS_GET_ALL'
          break
        case 'risk_registry':
          action = 'RISKS_DOCUMENTS_GET_ALL'
          break
      }
      this.$store
        .dispatch(action, {
          id: this.documentId,
          offset: this.perPage * (this.page - 1),
          limit: this.perPage,
          category_id: this.documentType.id,
          sort: this.sort
        })
        .then((res) => {
          if (res.data.ok === 1) {
            this.count = res.data.count
            if (this.page === 1 || !this.page) {
              const additionalItems =
                this.documentType.id === 0
                  ? this.items
                  : this.items.filter((item) => item.category_id == this.documentType.id)
              this.list = [...additionalItems, ...res.data.data]
            } else {
              this.list = res.data.data
            }
          }
        })
    },
    changeType(val) {
      this.documentType = val
      this.changePage(1)
    },
    openDocumentsUpload() {
      this.uploadOpened = !this.uploadOpened
    }
  }
}
</script>

<style scoped>
.document-types {
  width: 320px;
}

.documents-controls {
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  gap: 20px;
}

.documents-controls .green {
  width: 100%;
  max-width: 320px;
}

@media (min-width: 320px) {
  .documents-controls .green {
    font-size: 14px;
  }
}

@media (min-width: 480px) {
  .documents-controls .green {
    font-size: 16px;
  }
}
</style>
