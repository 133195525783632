<script setup>
import { onMounted } from 'vue'
import { NForm, NFormItem, NInput, NDatePicker } from 'naive-ui'
import EntrySelect from '@/components/common/EntitySelect'
import { toTimestamp } from '@/helper'
import { serialize } from '@/utils/url'
import { isEmpty } from 'lodash'
import { REQUIRED_FIELD_MESSAGE } from '@/constants'
import { computed, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  isEdit: Boolean
})

const route = useRoute()

const checklist = ref(props.data?.checklist)
const isCreatedFromChecklist = ref(!isEmpty(checklist.value) || !!route?.query?.checklist)
const isCreatedFromRisk = ref(Boolean(route?.query?.risk_id))
const isCreatedFromInevent = ref(Boolean(route?.query?.inevent_id))

const actionCategoryIsEmpty = ref(true)
const formRef = ref(null)

const formData = reactive({
  id: null,
  organization_requirement_id: Number(route.query.requirement) || null,
  checklist_id: Number(route.query.checklist) || null,
  object_id: 1,
  act_date: null,
  act_number: null,
  assurance_id: Number(route.query.assurance_id) || null,
  auditor_id: Number(route.query.assurance_user_id) || null,
  responsible_id: Number(route.query.responsible_id) || null,
  process_id: Number(route.query.process_id) || null,
  type_id: 1,
  status_id: route.query.checklist ? 1 : 2,
  text: null,
  start_date: null,
  plan_date: null,
  finish_date: null,
  department: null,
  capture: null,
  med_type_id: Number(route.query.med_type_id) || null,
  action_category_id: null,
  ...(props.data?.action ? createImportObject(props.data?.action) : {})
})

const rules = computed(() =>
  [
    'text',
    'object_id',
    'assurance_id',
    'type_id',
    'start_date',
    'plan_date',
    'auditor_id',
    'responsible_id',
    'med_type_id',
    'process_id'
  ].reduce(
    (acc, key) =>
      Object.assign(acc, {
        [key]: {
          required: true,
          message: REQUIRED_FIELD_MESSAGE,
          trigger: ['input', 'blur'],
          type: key === 'text' ? undefined : 'number'
        }
      }),
    {
      capture: {
        required: !isCreatedFromChecklist.value,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur']
      },
      med_type_id: {
        required: !isCreatedFromChecklist.value && formData.status_id === 4,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur'],
        type: 'number'
      },
      process_id: {
        required: !isCreatedFromChecklist.value && formData.status_id === 4,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur'],
        type: 'number'
      },
      action_category_id: {
        required: !isCreatedFromChecklist.value && formData.status_id === 4 && !actionCategoryIsEmpty.value,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur'],
        type: 'number'
      }
    }
  )
)

watch(
  () => props.data,
  (newVal, old) => {
    // Создание из риска
    if (isCreatedFromRisk.value && !old.object_id && newVal.object_id) {
      formData.risk_id = route?.query?.risk_id
      formData.object_id = newVal.object_id
      formData.med_type_id = newVal.med_type_id
      formData.process_id = newVal.process_id
      formData.action_category_id = newVal.action_category_id
    }

    // Создание из НС
    if (isCreatedFromInevent.value && !old.object_id && newVal.object_id) {
      formData.inevent_id = route?.query?.inevent_id
      formData.object_id = newVal.object_id
      formData.med_type_id = newVal.med_type_id
      formData.process_id = newVal.process_id
      formData.action_category_id = newVal.action_category_id
    }
  }
)

watch(
  () => formData.med_type_id,
  () => {
    if (!isCreatedFromRisk.value && !isCreatedFromInevent.value) {
      formData.process_id = null
      formData.action_category_id = null
    }
  }
)

watch(
  () => formData.process_id,
  () => {
    if (!isCreatedFromRisk.value && !isCreatedFromInevent.value) {
      formData.action_category_id = null
    }
  }
)

const actionCategoryRequest = computed(() => {
  const data = {
    subject: 'action-category',
    process_id: formData.process_id,
    object_id: formData.object_id
  }

  // для Риска(4) и для НС(2) получаем список как для НС
  if ([2, 4].includes(data.object_id)) {
    data.object_id = 2
  }

  return `refbook/get?${serialize(data, true)}`
})

function createExportObject(values) {
  return { ...values, act_date: toTimestamp(values.act_date) }
}
function createImportObject(values) {
  return {
    ...values,
    plan_date: Number(values.plan_date),
    start_date: Number(values.start_date),
    act_date: Number(values.act_date)
  }
}

function actionCategoryFetched(options) {
  actionCategoryIsEmpty.value = isEmpty(options)
}

defineExpose({
  validate(cb) {
    formRef.value?.validate((errors) => {
      cb?.(errors, createExportObject(formData))
    })
  }
})
</script>

<template>
  <NForm
    ref="formRef"
    class="action-create-n-form"
    :model="formData"
    :rules="rules"
    label-placement="left"
    label-width="220px"
  >
    <NFormItem label="Наименование мероприятия" path="text">
      <NInput v-model:value="formData.text" type="textarea" placeholder="Наименование мероприятия" />
    </NFormItem>
    <NFormItem v-if="!isCreatedFromChecklist" label="Описание" path="capture">
      <NInput v-model:value="formData.capture" type="textarea" placeholder="Описание" />
    </NFormItem>

    <NFormItem label="Объект учёта" path="object_id">
      <EntrySelect
        v-model:value="formData.object_id"
        request="action/get-objects"
        placeholder="Объект учёта"
        :disabled="
          ((isCreatedFromRisk || isCreatedFromInevent) && formData.object_id) ||
          (isEdit && (formData.risk_id || formData.inevent_id))
        "
      />
    </NFormItem>
    <NFormItem label="Вид проверки" path="assurance_id">
      <EntrySelect v-model:value="formData.assurance_id" request="action/get-assurances" placeholder="Вид проверки" />
    </NFormItem>
    <NFormItem label="Тип мероприятия" path="type_id">
      <EntrySelect v-model:value="formData.type_id" request="action/get-types" placeholder="Тип мероприятия" />
    </NFormItem>
    <NFormItem label="Дата начала" path="start_date">
      <NDatePicker v-model:value="formData.start_date" format="dd.MM.yyyy" placeholder="Дата начала" />
    </NFormItem>
    <NFormItem label="Дата закрытия" path="plan_date">
      <NDatePicker v-model:value="formData.plan_date" format="dd.MM.yyyy" placeholder="Дата закрытия" />
    </NFormItem>
    <NFormItem label="Аудитор/Автор" path="auditor_id">
      <EntrySelect
        v-model:value="formData.auditor_id"
        request="responsible/get"
        label-field="name"
        placeholder="Аудитор/Автор"
        sortable
        filterable
      />
    </NFormItem>
    <NFormItem label="Ответственный" path="responsible_id">
      <EntrySelect
        v-model:value="formData.responsible_id"
        request="responsible/get"
        label-field="name"
        placeholder="Ответственный"
        sortable
        filterable
      />
    </NFormItem>
    <NFormItem label="Направление" path="med_type_id">
      <EntrySelect
        v-model:value="formData.med_type_id"
        request="/organization/get-organization-medtypes"
        placeholder="Направление"
        :clearable="formData.status_id !== 4"
        :disabled="
          isCreatedFromChecklist ||
          ((isCreatedFromRisk || isCreatedFromInevent) && formData.med_type_id) ||
          (isEdit && (formData.risk_id || formData.inevent_id))
        "
      />
    </NFormItem>
    <NFormItem label="Раздел" path="process_id">
      <EntrySelect
        v-model:value="formData.process_id"
        placeholder="Раздел"
        label-field="process.text"
        value-field="process_id"
        :disabled="
          isCreatedFromChecklist ||
          ((isCreatedFromRisk || isCreatedFromInevent) && formData.process_id) ||
          (isEdit && (formData.risk_id || formData.inevent_id))
        "
        :request="formData.med_type_id ? `/organization/get-processes-by-med-id?id=${formData.med_type_id}` : null"
        :clearable="formData.status_id !== 4"
        multirow
      />
    </NFormItem>
    <template v-if="!isCreatedFromChecklist">
      <NFormItem label="Событие" path="action_category_id">
        <EntrySelect
          v-model:value="formData.action_category_id"
          placeholder="Событие"
          label-field="name"
          :request="actionCategoryRequest"
          :clearable="formData.status_id !== 4"
          multirow
          :disabled="
            ((isCreatedFromRisk || isCreatedFromInevent) && formData.action_category_id) ||
            (isEdit && (formData.risk_id || formData.inevent_id))
          "
          @fetched="actionCategoryFetched"
        />
      </NFormItem>
    </template>
  </NForm>
</template>

<style scoped lang="scss">
@media (max-width: 800px) {
  .content {
    padding: 30px 15px;
  }
}
</style>

<style lang="scss">
.action-create-n-form {
  .n-form-item {
    display: flex;
    flex-direction: column;
  }

  .n-form-item .n-form-item-blank {
    width: 100%;
  }

  .n-form-item-label {
    display: flex;
    width: auto !important;
    margin-bottom: 0;
  }
}
</style>
