import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useMessage, useNotification } from 'naive-ui'
import api from '@/api'

export const useOrganizationStore = defineStore('organization', () => {
  const endpoint = 'organization/'

  const notify = useNotification()
  const message = useMessage()

  const isLoading = ref(false)
  const isDeletingDocument = ref(false)
  const isError = ref(false)
  const error = ref(null)

  const organization = ref(JSON.parse(localStorage.getItem('user'))?.organization || null)
  const documents = ref([])
  const departments = ref([])
  const currentDepartment = ref({})
  const currentSubdivision = ref({})

  const getDocuments = async (params) => {
    try {
      isLoading.value = true
      isError.value = false
      error.value = null

      const res = await api.get(endpoint + 'get-documents', { params })

      if (res.data.ok === 1) {
        documents.value = res.data.data
        return res
      }

      throw new Error(res.data.message)
    } catch (e) {
      isError.value = true
      error.value = e?.response?.data?.message || e.message
      throw e
    } finally {
      isLoading.value = false
    }
  }

  const deleteDocument = async (payload) => {
    try {
      isDeletingDocument.value = true

      const res = await api.post(endpoint + 'delete-document-by-id', payload)

      if (res.data.ok === 1) {
        documents.value = documents.value.filter((item) => item.id !== payload.document_id)
      }

      message.success('Документ удален')

      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось удалить документ',
        duration: 2500
      })
    } finally {
      isDeletingDocument.value = false
    }
  }

  const createDepartment = async (payload) => {
    try {
      const response = await api.post(`${endpoint}create-department`, payload)

      return response
    } catch (error) {
      notify.error({
        title: 'Произошла ошибка!',
        duration: 2500
      })
    }
  }

  const getDepartments = async () => {
    try {
      const response = await api.get(`${endpoint}get-departments`)

      if (response.status === 200) {
        departments.value = response.data

        currentDepartment.value = {
          ...currentDepartment.value,
          ...response.data.find((newDepartment) => newDepartment.id == currentDepartment.value.id)
        }
      }
    } catch (error) {
      notify.error({
        title: 'Произошла ошибка при получении подразделений',
        duration: 2500
      })
    }
  }

  const getDepartmentById = async (id) => {
    try {
      const response = await api.get(`${endpoint}get-department?department_id=${id}`)

      if (response.status === 200) {
        currentDepartment.value = {
          ...departments.value.find((item) => item.id === id), // примешиваем organizationSubdivisions
          ...response.data
        }
      }

      return response
    } catch (error) {
      notify.error({
        title: 'Произошла ошибка при получении подразделения',
        duration: 2500
      })
    }
  }

  const getSubdivisionById = async (id) => {
    try {
      const response = await api.get(`${endpoint}get-subdivision?subdivision_id=${id}`)

      if (response.status === 200) {
        currentSubdivision.value = response.data
      }

      return response
    } catch (error) {
      notify.error({
        title: 'Произошла ошибка при получении подразделения',
        duration: 2500
      })
    }
  }

  const updateDepartment = async (payload) => {
    try {
      const response = await api.patch(`${endpoint}edit-department`, payload)

      if (response.status === 200) {
        getDepartments()
        getDepartmentById(payload.id)
        message.success('Подразделение сохранено')
      }

      return response
    } catch (error) {
      notify.error({
        title: 'Произошла ошибка при получении подразделения',
        duration: 2500
      })
    }
  }

  const updateSubdivision = async (payload) => {
    try {
      const response = await api.patch(`${endpoint}edit-subdivision`, payload)

      if (response.status === 200) {
        message.success('Отдел сохранен')
        getDepartments().then(() => {
          getDepartmentById(currentDepartment.value.id)
          getSubdivisionById(payload.id)
        })
      }

      return response
    } catch (error) {
      notify.error({
        title: 'Произошла ошибка',
        duration: 2500
      })
    }
  }

  const createSubdivision = async (payload) => {
    try {
      const response = await api.post(`${endpoint}create-subdivision`, payload)

      return response
    } catch (error) {
      notify.error({
        title: 'Произошла ошибка!',
        duration: 2500
      })
    }
  }

  const deleteDepartment = async (payload) => {
    try {
      const response = await api.post(`${endpoint}delete-department`, payload)

      return response
    } catch (error) {
      notify.error({
        title: 'Произошла ошибка!',
        duration: 2500
      })
    }
  }

  const deleteSubdivision = async (payload) => {
    try {
      const response = await api.post(`${endpoint}delete-subdivision`, payload)

      return response
    } catch (error) {
      notify.error({
        title: 'Произошла ошибка!',
        duration: 2500
      })
    }
  }

  const clearDepartment = () => {
    currentDepartment.value = {}
  }

  const clearSubdivision = () => {
    currentSubdivision.value = {}
  }

  return {
    isLoading,
    isError,
    error,
    isDeletingDocument,
    organization,
    documents,
    departments,
    currentDepartment,
    currentSubdivision,
    // actions
    getDocuments,
    deleteDocument,
    createDepartment,
    getDepartments,
    getDepartmentById,
    updateDepartment,
    createSubdivision,
    deleteSubdivision,
    deleteDepartment,
    getSubdivisionById,
    updateSubdivision,
    clearDepartment,
    clearSubdivision
  }
})
