<template>
  <div class="item">
    <router-link
      v-if="item.status_id !== 3 && item.status_id !== 2"
      class="number-row"
      :to="'/checklist/' + item.number"
    >
      {{ item.number }}
    </router-link>
    <router-link v-else class="number-row" :to="'/checklist/report/' + item.id">{{ item.number }}</router-link>
    <div class="status-row">{{ item.status.text }}</div>
    <div class="auditor-row">{{ item.assurance_user }}</div>
    <div
      class="type-row"
      :class="{ 'cursor-pointer': enableMultiline }"
      @click="enableMultiline ? toggleExpand('assurance', item.id) : null"
    >
      <span :class="{ 'multiline-cut': enableMultiline && !expandedRows[item.id]?.assurance }">
        {{ item.assurance.text }}
      </span>
    </div>
    <div
      class="type-row"
      :class="{ 'cursor-pointer': enableMultiline }"
      @click="enableMultiline ? toggleExpand('process', item.id) : null"
    >
      <span :class="{ 'multiline-cut': enableMultiline && !expandedRows[item.id]?.process }">
        {{ item.process.text }}
      </span>
    </div>
    <div
      class="type-row"
      :class="{ 'cursor-pointer': enableMultiline }"
      @click="enableMultiline ? toggleExpand('medtype', item.id) : null"
    >
      <span :class="{ 'multiline-cut': enableMultiline && !expandedRows[item.id]?.medtype }">
        {{ item.medtype.text }}
      </span>
    </div>
    <div class="date-row">{{ $helper.makeDate(item.plan_date * 1000 || item.create_date) }}</div>
    <div class="date-row">{{ $helper.makeDate(item.finish_date) }}</div>
    <div class="date-row">{{ $helper.makeDate(item.sign_date) }}</div>
    <div class="document-row">
      <div v-if="item.finish_date" class="document-btn mr-3" @click="generateReport">Отчёт</div>
      <div v-if="item.sign_date" class="document-btn" @click="generateProtocol">Протокол</div>
    </div>
    <NextNotice v-if="isGenProtocol || isGenReport" :status="status" :text="noticeText" />
  </div>
</template>

<script>
import { ref } from 'vue'
import Pdf from '@/utils/pdf'
import NextNotice from '@/components/widgets/simple-components/notice/NextNotice'

export default {
  name: 'ChecklistItem',
  components: { NextNotice },
  props: {
    item: {
      type: Object,
      require: true
    },
    enableMultiline: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const expandedRows = ref({})

    const toggleExpand = (key, rowId) => {
      if (!expandedRows.value[rowId]) {
        expandedRows.value[rowId] = {}
      }
      expandedRows.value[rowId][key] = !expandedRows.value[rowId][key]
    }

    return { expandedRows, toggleExpand }
  },
  data() {
    return {
      isGenProtocol: false,
      isGenReport: false,
      isError: false
    }
  },
  computed: {
    status() {
      return this.isGenProtocol || this.isGenReport ? 'loading' : this.isError ? 'error' : 'success'
    },
    noticeText() {
      return this.isGenProtocol
        ? 'Открытие протокола. Подождите...'
        : this.isGenReport
        ? 'Открытие отчёта. Подождите...'
        : this.isError
    }
  },
  methods: {
    async getChecklist(number) {
      try {
        const res = await this.$api.get(`checklists/get-by-number?number=${number}`)

        if (res.data.ok === 1) {
          return res.data.data
        } else {
          this.isError = res.data.message
        }
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async getSnapshot() {
      try {
        const res = await this.$api.get(`checklists/get-snapshot?id=${this.item.id}`)

        if (res.data.ok === 1) {
          return JSON.parse(res.data.data.data)
        } else {
          this.isError = res.data.message
        }
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async getResponsibleUsers() {
      try {
        const res = await this.$api.get('responsible/get')

        if (res.data.ok === 1) {
          return res.data.data
        } else {
          this.isError = res.data.message
        }
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async generateProtocol() {
      this.isGenProtocol = true
      this.isError = false

      try {
        const {
          data: { data: checklist }
        } = await this.$api.get(`checklists/get-proto?number=${this.item.number}`)

        const doc = new Pdf()

        const tableData = checklist.requirements?.filter((item) => item.status_id === 3 || item?.action?.type_id === 2)

        await doc.generate({
          template: {
            name: 'CheckListProtocolTemplate',
            props: {
              sourceData: {
                ...checklist,
                responsibleUsers: checklist?.vkk_members || []
              },
              tableData,
              pageNumber: 1,
              initialEmpty: Boolean(tableData.length === 0)
            }
          }
        })
      } catch (e) {
        this.isError = 'Произошла ошибка. Протокол не создан.'
      } finally {
        this.isGenProtocol = false
      }
    },
    async generateReport() {
      this.isGenReport = true
      this.isError = false

      try {
        let reportData = await this.getSnapshot()
        const doc = new Pdf()

        await doc.generate({
          template: {
            name: 'CheckListReportTemplate',
            props: {
              sourceData: reportData,
              tableData: reportData.requirements?.filter((item) => item.status_id === 3),
              pageNumber: 1
            }
          }
        })
      } catch (e) {
        this.isError = 'Произошла ошибка. Отчёт не создан.'
      } finally {
        this.isGenReport = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  border: 1px solid #cccccc;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
  display: flex;
  width: 100%;
  line-height: 1.5;
}

.number-row {
  border-right: 1px solid #cccccc;
  width: 7%;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 12px;
  flex: none;
}

.status-row {
  width: 10%;
  padding-top: 9px;
  padding-bottom: 9px;
  flex: none;
  padding-left: 12px;
  padding-right: 12px;
  border-right: 1px solid #cccccc;
}

.auditor-row {
  width: 10%;
  flex: none;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-right: 12px;
  border-right: 1px solid #cccccc;
}

.type-row {
  width: 12%;
  flex: none;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-right: 12px;
  border-right: 1px solid #cccccc;
}

.date-row {
  width: 10%;
  flex: none;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
  border-right: 1px solid #cccccc;
}

.document-row {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 5px;
  width: 13%;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-right: 12px;
  flex: none;
}

.document-btn {
  color: #37c4a3;
  //text-transform: uppercase;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.multiline-cut {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
