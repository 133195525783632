<script setup>
import { ref, provide, onMounted, onBeforeUnmount, nextTick, watchEffect } from 'vue'

// Определяем реактивное свойство isMobile
const isMobile = ref(window.innerWidth <= 768)

// Функция обновления isMobile при изменении размера окна
const updateDeviceType = async () => {
  isMobile.value = window.innerWidth <= 768
  await nextTick() // Ждём обновления DOM и реактивности Vue
}

// Подписка на изменение размера окна
onMounted(() => {
  window.addEventListener('resize', updateDeviceType)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateDeviceType)
})

// Передаем `isMobile` через provide
provide('isMobile', isMobile)
</script>

<template>
  <slot />
</template>
