import api from '@/api'

const endpoint = 'auth/'

const AuthService = {
  login(user) {
    return api
      .post(endpoint + 'login', {
        email: user.email,
        password: user.password
      })
      .then(({ data }) => {
        if (data.user) {
          localStorage.setItem('user', JSON.stringify(data.user))
          api.defaults.headers.common['Authorization'] = data.user.auth_key || null
          api.defaults.headers.common['User'] = data.user.id || null
        }
        return data
      })
  },
  logout() {
    localStorage.removeItem('user')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('incidentFilters')
    localStorage.removeItem('redirectedForBaseTarif')
    localStorage.removeItem('redirectedForStartTarif')
  },
  registerConfirm(token) {
    return api.post(endpoint + 'registration-confirm', {
      token: token
    })
  },
  register(user) {
    return api.post(endpoint + 'registration', {
      name: user.name,
      email: user.email,
      password: user.password,
      organization: user.organization,
      phone: user.phone
    })
  },
  invite(user) {
    return api.post(endpoint + 'invite', {
      name: user.name,
      email: user.email,
      password: user.password,
      invite_link: user.invite_link,
      phone: user.phone
    })
  }
}
export default AuthService
