<script setup>
import { onMounted, ref, watch, computed } from 'vue'
import { NDivider } from 'naive-ui';
import moment from 'moment';

import useIncidentsReport from '@/composables/useIncidentsReport'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextTable from '@/components/widgets/simple-components/table/NextTable'

const props = defineProps({
  range: {
    type: Array,
    default: () => ['', ''],
  },
  department: {
    type: Object,
  },
  subdivision: {
    type: Object,
  },
})

const params = {
  date_from: '2024-01-01',
  date_to: '2024-12-31',
};

const incidentsReport = useIncidentsReport()

const isLoadingIncidents = ref(true)
const incidentsReportData = ref(null)
const incidentsGroupedByProcess = ref(null)

onMounted(() => {
  fetchData()
})

const fetchData = async () => {
  isLoadingIncidents.value = true

  incidentsReportData.value = await incidentsReport.fetch(params)
  incidentsGroupedByProcess.value = incidentsReport.getGroupedByProcessText()

  isLoadingIncidents.value = false
}

const incidentsByDepartment = computed(() => {
  if (props.department) {
    console.log('_____', incidentsReportData.value);
    console.log('_____', props.department);
    
    const filteredByDepartment = incidentsReportData.value.filter((incident) => incident.department === props.department.label)

    if (props.subdivision) {
      return filteredByDepartment.map((department) => ({
        ...department,
        items: department.items.filter((departmentIncident) => departmentIncident.subdivision_id === props.subdivision.id)
      }))
    }
    
    return filteredByDepartment
  }

  return incidentsReportData.value
})

watch(
  () => props.range,
  (newRange) => {
    if (newRange[0] && newRange[1]) {
      params.date_from = moment(new Date(newRange[0]).getTime()).format('YYYY-MM-DD');
      params.date_to = moment(new Date(newRange[1]).getTime()).format('YYYY-MM-DD');
      fetchData();
    }
  },
  { immediate: false }
)
</script>

<template>
  <div v-if="isLoadingIncidents" class="flex">
    <SpinLoader style="display: inline-block;" class="w-8" />
    <p class="h5">Загрузка данных раздела Нежелательные события...</p>
  </div>

  <div v-if="incidentsReportData" class="mb-5">
    <NDivider><h2 style="font-size: 28px; text-align: center;">Отчёт по Нежелательным событиям</h2></NDivider>

    <div class="mb-5" style="max-width: 1000px;">
      <NextTable
        :columns="[
          { key: 'department', title: 'Подразделение', },
          { key: 'countNew', title: 'Новые', },
          { key: 'countInProcess', title: 'В работе', },
          { key: 'countClosed', title: 'Закрыто', },
          { key: 'count', title: 'Всего', },
        ]" 
        :table-data="incidentsByDepartment"
        cellpadding="8"
        border="1"
        style="border-collapse: collapse;"
      >
        <template #department="{ value }">
          {{ value }}
        </template>
        <template #countNew="{ row }">
          {{ row.items.reduce((acc, el) => acc + el.countNew, 0) }}
        </template>
        <template #countInProcess="{ row }">
          {{ row.items.reduce((acc, el) => acc + el.countInProcess, 0) }}
        </template>
        <template #countClosed="{ row }">
          {{ row.items.reduce((acc, el) => acc + el.countClosed, 0) }}
        </template>
        <template #count="{ row }">
          {{ row.items.reduce((acc, el) => acc + el.count, 0) }}
        </template>
      </NextTable>

      <div class="flex" style="margin-top: 10px;">
        <div class="px-2 py-1" style="font-size: 16px; text-align: left;">ИТОГО</div>
        <div class="px-2 py-1" style="font-size: 16px;">
          Новые: {{ incidentsByDepartment.reduce((acc, incident) => acc + incident.items.reduce((acc, el) => acc + el.countNew, 0), 0) }}
        </div>
        <div class="px-2 py-1" style="font-size: 16px;">
          В работе: {{ incidentsByDepartment.reduce((acc, incident) => acc + incident.items.reduce((acc, el) => acc + el.countInProcess, 0), 0) }}
        </div>
        <div class="px-2 py-1" style="font-size: 16px;">
          Закрыто: {{ incidentsByDepartment.reduce((acc, incident) => acc + incident.items.reduce((acc, el) => acc + el.countClosed, 0), 0) }}
        </div>
        <div class="px-2 py-1" style="font-size: 16px;">
          Всего: {{ incidentsByDepartment.reduce((acc, incident) => acc + incident.items.reduce((acc, el) => acc + el.count, 0), 0) }}
        </div>
      </div>
    </div>

    <div v-for="row in incidentsByDepartment" :key="row" class="mb-4">
      <h4 class="mb-3">
        Подразделение "{{ row.department }}".
      </h4>
      <div class="flex gap-5 mb-3" style="margin-bottom: 20px;">
        <div class="mb-0">Новые: {{ row.items.reduce((acc, el) => acc + el.countNew, 0) }}</div>
        <div class="mb-0">В работе: {{ row.items.reduce((acc, el) => acc + el.countInProcess, 0) }}</div>
        <div class="mb-0">Закрыто: {{ row.items.reduce((acc, el) => acc + el.countClosed, 0) }}</div>
        <div class="mb-0"><span style="color: #1fc580;">Всего: {{ row.items.reduce((acc, el) => acc + el.count, 0) }}</span></div>
      </div>

      <NextTable
        :columns="[
          { key: 'medType', title: 'Направление', },
          { key: 'process', title: 'Раздел ВКК', },
          { key: 'actionCategory', title: 'Вид нежелательного события', },
          { key: 'count', title: 'Количество', },
        ]" 
        :table-data="row.items"
        cellpadding="8"
        border="1"
        style="border-collapse: collapse;"
      >
        <template #medType="{ value }">
          {{ value?.text }}
        </template>
        <template #process="{ value }">
          {{ value.text }}
        </template>
        <template #actionCategory="{ value }">
          {{ value.name }}
        </template>
        <template #count="{ value }">
          {{ value }}
        </template>
      </NextTable>
    </div>

    <div class="pt-4">
      <h4>Сводная таблица по нежелательным событиям</h4>

      <table style="border-collapse: collapse;" cellpadding="8" border="1">
        <thead>
          <tr>
            <th style="font-size: 16px; width: 5%; padding: 8px;">№</th>
            <th style="font-size: 16px; width: 35%; padding: 8px;">Раздел</th>
            <th style="font-size: 16px; width: 20%; padding: 8px;">Направление</th>
            <th style="font-size: 16px; width: 10%; padding: 8px;">Новые</th>
            <th style="font-size: 16px; width: 10%; padding: 8px;">В работе</th>
            <th style="font-size: 16px; width: 10%; padding: 8px;">Закрыто</th>
            <th style="font-size: 16px; width: 10%; padding: 8px;">Всего</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="[process, data] in Object.entries(incidentsGroupedByProcess)" :key="data">
            <td style="font-size: 16px; padding: 8px; text-align: left;">{{ data.processNumber }}</td>
            <td style="font-size: 16px; padding: 8px; text-align: left;">{{ process }}</td>
            <td style="font-size: 16px; padding: 8px; text-align: left;">{{ data.medType }}</td>
            <td style="font-size: 16px; padding: 8px; text-align: left;">{{ data.new }}</td>
            <td style="font-size: 16px; padding: 8px; text-align: left;">{{ data.inProcess }}</td>
            <td style="font-size: 16px; padding: 8px; text-align: left;">{{ data.closed }}</td>
            <td style="font-size: 16px; padding: 8px; text-align: left;">{{ data.total }}</td>
          </tr>
        </tbody>
      </table>

      <!-- <div class="flex" style="border-bottom: 1px solid #ccc; border-top: 1px solid #ccc; ">
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 5%;">№</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 35%;">Раздел</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 20%;">Направление</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 10%;">Новые</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 10%;">В работе</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 10%;">Закрыто</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 10%;">Всего</span>
      </div>
      <div v-for="[process, data] in Object.entries(incidentsGroupedByProcess)" :key="data" style="border-bottom: 1px solid #ccc; border-right: 1px solid #ccc; ">
        <div class="flex">
          <span class="cell text-left" style="font-size: 16px; width: 5%;">{{ data.processNumber }}</span>
          <span class="cell text-left" style="font-size: 16px; width: 35%;">{{ process }}</span>
          <span class="cell text-left" style="font-size: 16px; width: 20%;">{{ data.medType }}</span>
          <span class="cell text-left" style="font-size: 16px; width: 10%;">{{ data.new }}</span>
          <span class="cell text-left" style="font-size: 16px; width: 10%;">{{ data.inProcess }}</span>
          <span class="cell text-left" style="font-size: 16px; width: 10%;">{{ data.closed }}</span>
          <span class="cell text-left" style="font-size: 16px; width: 10%;">{{ data.total }}</span>
        </div>
      </div> -->
    </div>
  </div>
</template>


<style scoped lang="scss">
.h-cell {
  font-weight: bold;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-right: 1px solid #ccc;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}

.cell {
  font-size: 13px;
  text-align: center;
  border-right: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 4px 8px;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}
</style>