<script setup>
import { computed, reactive, ref, watch, onMounted, h } from 'vue'
import { NForm, NFormItem, NInput, NDatePicker, NCheckbox, NSelect, NRadioGroup, NRadioButton } from 'naive-ui'
import EntrySelect from '@/components/common/EntitySelect'
import { serialize } from '@/utils/url'
import { isEmpty } from 'lodash'
import { REQUIRED_FIELD_MESSAGE } from '@/constants'
import { useRoute } from 'vue-router'
import api from '@/api'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  isEdit: Boolean
})

// const route = useRoute()

// const checklist = ref(props.data?.checklist)
// const isCreatedFromChecklist = ref(!isEmpty(checklist.value) || !!route?.query?.checklist)
// const categoryIsEmpty = ref(true)
const formRef = ref(null)
const formData = reactive({
  name: '',
  type: '',
  owner: '',
  probability: '',
  severity: '',
  department_id: '',
  level: '',
  activities: '',
  activities_plan: '',
  responsible_id: '',
  frequency: '',
  causes: '',
  description: '',
  consequence: '',
  subdivision_id: '',
  object_id: 2, // "Нежелательное событие"
  auditor_id: null,
  med_type_id: null,
  action_category_id: null
})

const probabilityList = [
  {
    value: 'Низкая',
    label: 'Низкая'
  },
  {
    value: 'Средняя',
    label: 'Средняя'
  },
  {
    value: 'Высокая',
    label: 'Высокая'
  }
]

const severityList = [
  {
    value: 'Низкая',
    label: 'Низкая'
  },
  {
    value: 'Средняя',
    label: 'Средняя'
  },
  {
    value: 'Высокая',
    label: 'Высокая'
  }
]

const riskTypesList = [
  { id: 1, value: 'Операционный', label: 'Операционный' },
  { id: 2, value: 'Репутационный', label: 'Репутационный' },
  { id: 3, value: 'Коррупционный', label: 'Коррупционный' },
  { id: 4, value: 'Стратегический (внешняя среда)', label: 'Стратегический (внешняя среда)' },
  { id: 5, value: 'Стратегический (возможности)', label: 'Стратегический (возможности)' },
  { id: 6, value: 'Технический', label: 'Технический' },
  { id: 7, value: 'Финансовый', label: 'Финансовый' },
  { id: 8, value: 'Регулятивный', label: 'Регулятивный' },
  { id: 9, value: 'Юридический', label: 'Юридический' }
]

const riskActivitiesList = [
  { id: 1, value: 'Минимизация', label: 'Минимизация' },
  { id: 2, value: 'Устранение', label: 'Устранение' },
  { id: 3, value: 'Ограничение', label: 'Ограничение' }
]

const subdivisionList = ref([])
const departmentList = computed(() => {
  if (formData.department_id) {
    const list = subdivisionList.value
      .find((subdiv) => subdiv.id === formData.department_id)
      ?.organizationSubdivisions.map((el) => ({
        ...el,
        label: el.name,
        value: el.id
      }))

    return list
  }

  return []
})

const responsibleUsersList = ref([])
const processVkkOptions = ref([])

onMounted(() => {
  fetchDepartments()
  initFormData()
  fetchUsers()
})

const fetchUsers = async () => {
  const { data } = await api.get('responsible/get')
  if (data.data.length) {
    responsibleUsersList.value = data.data
  }
}

const fetchDepartments = async () => {
  const response = await api.get('/organization/get-departments')
  if (response.status === 200) {
    subdivisionList.value = response.data.map((el) => {
      return {
        ...el,
        label: el.name,
        value: el.id
      }
    })
  }
}

const initFormData = () => {
  if (props.isEdit) {
    formData.subdivision_id = props.data.subdivision?.id ? Number(props.data.subdivision?.id) : null
    formData.department_id = props.data.department?.id ? Number(props.data.department?.id) : null
    formData.type = props.data.type
    formData.owner = props.data.owner
    formData.description = props.data.description
    formData.causes = props.data.causes
    formData.consequence = props.data.consequence
    formData.probability = props.data.probability
    formData.severity = props.data.severity
    formData.level = props.data.level
    formData.activities = props.data.activities
    formData.activities_plan = props.data.activities_plan
    formData.responsible_id = Number(props.data.responsible_id) //
    formData.frequency = props.data.frequency
    formData.med_type_id = props.data.med_type_id ? Number(props.data.med_type_id) : ''
    formData.process_id = Number(props.data.process_id)
    formData.action_category_id = props.data.actionCategory ? Number(props.data.actionCategory?.id) : undefined
  }
}

const rules = computed(() =>
  ['registration_date', 'med_type_id', 'responsible_id', 'action_category_id', 'process_id', 'auditor_id'].reduce(
    (acc, key) =>
      Object.assign(acc, {
        [key]: {
          required: true,
          message: REQUIRED_FIELD_MESSAGE,
          type: key === 'text' ? undefined : 'number'
        }
      }),
    {
      med_type_id: {
        required: false,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur'],
        type: 'number'
      },
      process_id: {
        required: false,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur'],
        type: 'number'
      },
      action_category_id: {
        required: false,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur'],
        type: 'number'
      },
      description: {
        required: true,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur']
      },
      causes: {
        required: true,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur']
      },
      consequence: {
        required: true,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur']
      },
      probability: {
        required: true,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur']
      },
      severity: {
        required: true,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur']
      },
      owner: {
        required: true,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur']
      }
    }
  )
)

const actionCategoryRequest = computed(() => {
  if (props.isEdit) {
    if (formData.process_id && formData.object_id) {
      return `refbook/get?${serialize(
        {
          subject: 'action-category',
          process_id: formData.process_id,
          object_id: formData.object_id
          // object_id: 4 // "Риски"
        },
        true
      )}`
    }

    return ''
  }

  return `refbook/get?${serialize(
    {
      subject: 'action-category',
      process_id: formData.process_id,
      object_id: formData.object_id
      // object_id: 4 // "Риски"
    },
    true
  )}`
})

watch(formData, ({ probability, severity }) => {
  if (probability && severity) {
    const matrix = ['Незначительный риск', 'Приемлемый риск', 'Терпимый риск', 'Значительный риск', 'Недопустимый риск']

    switch (probability) {
      case 'Низкая':
        if (severity === 'Низкая') {
          formData.level = matrix[0]
        }
        if (severity === 'Средняя') {
          formData.level = matrix[1]
        }
        if (severity === 'Высокая') {
          formData.level = matrix[2]
        }
        break
      case 'Средняя':
        if (severity === 'Низкая') {
          formData.level = matrix[1]
        }
        if (severity === 'Средняя') {
          formData.level = matrix[2]
        }
        if (severity === 'Высокая') {
          formData.level = matrix[3]
        }
        break
      case 'Высокая':
        if (severity === 'Низкая') {
          formData.level = matrix[2]
        }
        if (severity === 'Средняя') {
          formData.level = matrix[3]
        }
        if (severity === 'Высокая') {
          formData.level = matrix[4]
        }
    }
  }
})

watch(
  () => formData.med_type_id,
  () => {
    if (!props.isEdit) {
      formData.process_id = null
      formData.action_category_id = null
    }

    fetchProcessVkkOptions(formData.med_type_id)
  }
)

watch(
  () => formData.process_id,
  () => {
    if (!props.isEdit) {
      formData.action_category_id = null
    }

    if (props.isEdit && !formData.process_id) {
      formData.process_id = Number(props.data.process_id)
    }
  }
)

const fetchProcessVkkOptions = async (med_type_id) => {
  try {
    const {
      data: { data }
    } = await api.get(`/organization/get-processes-by-med-id?id=${med_type_id}`)
    processVkkOptions.value = data.map((el) => ({
      ...el,
      label: el.process.text,
      value: el.process.id
    }))
  } catch (error) {
    console.log(error)
  }
}

const responsibleUsersOptions = computed(() => {
  let array = []

  if (formData.subdivision_id && departmentList.value?.length) {
    const { members } = departmentList.value.find((subdiv) => subdiv.id === formData.subdivision_id)
    array = members.map((member) => ({
      value: Number(member.id),
      label: member.name
    }))
  }

  if (!formData.subdivision_id && formData.department_id && subdivisionList.value?.length) {
    const { members } = subdivisionList.value.find((department) => department.id === formData.department_id)
    array = members.map((member) => ({
      value: Number(member.id),
      label: member.name
    }))
  }

  if (!formData.department_id) {
    array = responsibleUsersList.value.map((member) => ({
      value: Number(member.id),
      label: member.name
    }))
  }

  return array
})

function createExportObject(values) {
  return values
}

function departmentClear() {
  formData.subdivision_id = null
}

defineExpose({
  validate(cb) {
    formRef.value?.validate((errors) => {
      cb?.(errors, createExportObject(formData))
    })
  }
})
</script>

<template>
  <NForm ref="formRef" :model="formData" :rules="rules" label-placement="left" label-width="220px">
    <p style="padding-left: 220px; font-weight: 500">Идентификация риска</p>

    <NFormItem label="Подразделение" path="department_id">
      <NSelect
        v-model:value="formData.department_id"
        :options="subdivisionList"
        size="large"
        clearable
        :on-clear="departmentClear"
      />
    </NFormItem>

    <NFormItem label="Отдел" path="subdivision_id">
      <NSelect v-model:value="formData.subdivision_id" :options="departmentList" size="large" clearable />
    </NFormItem>

    <NFormItem label="Направление" path="med_type_id">
      <EntrySelect v-model:value="formData.med_type_id" request="/organization/get-organization-medtypes" />
    </NFormItem>

    <NFormItem label="Раздел ВКК" path="process_id">
      <!-- <EntrySelect
        v-model:value="formData.process_id"
        label-field="process.text"
        value-field="process_id"
        :request="formData.med_type_id ? `/organization/get-processes-by-med-id?id=${formData.med_type_id}` : null"
        multirow
      /> -->
      <EntrySelect v-model:value="formData.process_id" :options="processVkkOptions" multirow />
    </NFormItem>

    <NFormItem label="Наименование риска" path="action_category_id">
      <EntrySelect
        v-if="actionCategoryRequest"
        v-model:value="formData.action_category_id"
        label-field="name"
        :request="actionCategoryRequest"
        multirow
      />
    </NFormItem>

    <NFormItem label="Вид риска" path="type">
      <!-- <NInput v-model:value="formData.type" size="large" /> -->
      <NSelect v-model:value="formData.type" :options="riskTypesList" size="large" />
    </NFormItem>

    <NFormItem label="Владелец риска" path="owner">
      <NInput v-model:value="formData.owner" size="large" />
    </NFormItem>

    <NFormItem label="Описание" path="description">
      <NInput v-model:value="formData.description" type="textarea" />
    </NFormItem>

    <NFormItem label="Причины возникновения риска" path="causes">
      <NInput v-model:value="formData.causes" type="textarea" />
    </NFormItem>

    <NFormItem label="Последствия риска" path="consequence">
      <NInput v-model:value="formData.consequence" type="textarea" />
    </NFormItem>

    <p style="padding-left: 220px; padding-top: 20px; font-weight: 500">Оценка риска</p>

    <NFormItem label="Вероятность риска" path="probability">
      <!-- <NInput v-model:value="formData.probability" /> -->
      <NRadioGroup v-model:value="formData.probability">
        <NRadioButton
          v-for="prop in probabilityList"
          :key="prop.value"
          :value="prop.value"
          :label="prop.label"
          style="width: 110px"
        />
      </NRadioGroup>
    </NFormItem>
    <NFormItem label="Тяжесть риска" path="severity">
      <!-- <NInput v-model:value="formData.severity" /> -->

      <NRadioGroup v-model:value="formData.severity">
        <NRadioButton
          v-for="severity in severityList"
          :key="severity.value"
          :value="severity.value"
          :label="severity.label"
          style="width: 110px"
        />
      </NRadioGroup>
    </NFormItem>
    <NFormItem label="Уровень риска" path="level">
      <NInput v-model:value="formData.level" disabled />
    </NFormItem>

    <p style="padding-left: 220px; padding-top: 20px; font-weight: 500">Управление риском</p>

    <NFormItem label="Мероприятия по управлению риском" path="activities">
      <!-- <NInput v-model:value="formData.activities" /> -->
      <NSelect v-model:value="formData.activities" :options="riskActivitiesList" size="large" />
    </NFormItem>
    <NFormItem label="План мероприятий по управлению риском" path="activities_plan">
      <NInput v-model:value="formData.activities_plan" type="textarea" />
    </NFormItem>
    <NFormItem label="Ответственный за управление риском" path="responsible_id">
      <EntrySelect
        v-model:value="formData.responsible_id"
        :options="responsibleUsersOptions"
        label-field="name"
        sortable
        filterable
      />
    </NFormItem>
    <NFormItem label="Периодичность мониторинга" path="frequency">
      <NInput v-model:value="formData.frequency" />
    </NFormItem>
  </NForm>
</template>

<style scoped>
label {
  margin-bottom: auto;
}
</style>
