<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { NButton, NH1 } from 'naive-ui'
import { storeToRefs } from 'pinia'
import moment from 'moment'
import { useChecklistsStore } from '@/store/checklists'

import { useIncidentsStore } from '@/store/incidents'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import CreateForm from './CreateForm'

const route = useRoute()
const router = useRouter()

const incidentsStore = useIncidentsStore()
const { isSaving } = storeToRefs(incidentsStore)
const checklistsStore = useChecklistsStore()
const { isLoading, isError, error } = storeToRefs(checklistsStore)

const formRef = ref(null)
const action = ref({})
const isCreatedFromChecklist = ref(!!action.value?.checklist || !!route?.query?.checklist)
const isDisabledSaveButton = ref(false)

async function save() {
  formRef.value?.validate((errors, values) => {
    console.log('%c LOG: ', 'background: #9488ea; color: #f1f1f1; font-size: 12px; border-radius: 4px', errors)
    if (errors) return

    const payload = {
      ...values,
      registration_date: moment(values.registration_date).format('YYYY-MM-DD HH:mm:ss'),
      sending_notice_date: moment(values.sending_notice_date).format('YYYY-MM-DD HH:mm:ss'),
      sending_date_rkn: values.sending_date_rkn ? moment(values.sending_date_rkn).format('YYYY-MM-DD HH:mm:ss') : null,
      // @TODO
      // subdivision_id: 1,
    }

    isDisabledSaveButton.value = true

    incidentsStore
      .create(payload)
      .then(() => {
        let redirect = '/incidents/'

        if (payload.risk_id) {
          redirect = `/risks/${payload.risk_id}?tab=incidents`
        }

        router.push(redirect)
      })
      .finally(() => isDisabledSaveButton.value = false)
  })
}

function cancel() {
  router.back()
}
</script>

<template>
  <div class="max-w-6xl mx-auto">
    <div class="page-header">
      <div class="page-header__left">
        <NH1 class="mb-0">Регистрация нежелательного события</NH1>
      </div>
      <div class="page-header__right">
        <div class="page-header__controls">
          <NButton type="primary" :loading="isSaving" :disabled="isDisabledSaveButton" @click="save">Сохранить</NButton>
          <NButton :disabled="isSaving" strong secondary @click="cancel">Отмена</NButton>
        </div>
      </div>
    </div>
    <div class="content">
      <NextAlert v-if="isError && !isLoading" class="mb-4" type="error" :text="error" />
      <SpinLoader v-else-if="isLoading" class="py-5" size="2xl" />
      <div v-else class="max-w-200">
        <div v-if="isCreatedFromChecklist" class="mb-4">
          <div class="mb-3">
            <div class="font-weight-bold">Выявленное несоответствие</div>
            <p class="text-secondary">{{ action?.commentary || '—' }}</p>
          </div>
          <div class="d-flex align-items-center mb-3">
            <div class="font-weight-bold mr-2">Отчёт:</div>
            <RouterLink v-if="action?.checklist?.number" :to="`/checklist/${action?.checklist?.number}`">
              {{ action?.checklist?.number }}
            </RouterLink>
            <div v-else class="text-secondary">—</div>
          </div>
        </div>
        <CreateForm ref="formRef" :data="action" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  padding: 40px;
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
}
</style>
