<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { NButton, NH1 } from 'naive-ui'
import { storeToRefs } from 'pinia'
import { useChecklistsStore } from '@/store/checklists'
import { useEventsStore } from '@/store/events'
import { useRisksStore } from '@/store/risks'

import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import CreateForm from './CreateForm'

const router = useRouter()

const eventsStore = useEventsStore()
const { isSaving } = storeToRefs(eventsStore)
const checklistsStore = useChecklistsStore()
const { isLoading, isError, error } = storeToRefs(checklistsStore)

const risksStore = useRisksStore()

const formRef = ref(null)
const action = ref({})
const isDisabledSaveButton = ref(false)

async function save() {
  formRef.value?.validate((errors, values) => {
    console.log('%c LOG: ', 'background: #9488ea; color: #f1f1f1; font-size: 12px; border-radius: 4px', errors)
    if (errors) return

    isDisabledSaveButton.value = true

    risksStore
      .create((values))
      .then((data) => router.push('/risks/' + data.id))
      .finally(() => isDisabledSaveButton.value = false)
  })
}

function cancel() {
  router.back()
}
</script>

<template>
  <div class="max-w-6xl mx-auto">
    <div class="page-header">
      <div class="page-header__left">
        <NH1 class="mb-0">Паспорт риска</NH1>
      </div>
      <div class="page-header__right">
        <div class="page-header__controls">
          <NButton type="primary" :loading="isSaving" :disabled="isDisabledSaveButton" @click="save">Сохранить</NButton>
          <NButton :disabled="isSaving" strong secondary @click="cancel">Отмена</NButton>
        </div>
      </div>
    </div>
    <div class="content">
      <NextAlert v-if="isError && !isLoading" class="mb-4" type="error" :text="error" />
      <SpinLoader v-else-if="isLoading" class="py-5" size="2xl" />
      <div v-else class="max-w-200">
        <!-- <div v-if="isCreatedFromChecklist" class="mb-4">
          <div class="mb-3">
            <div class="font-weight-bold">Выявленное несоответствие</div>
            <p class="text-secondary">{{ action?.commentary || '—' }}</p>
          </div>
          <div class="d-flex align-items-center mb-3">
            <div class="font-weight-bold mr-2">Отчёт:</div>
            <RouterLink v-if="action?.checklist?.number" :to="`/checklist/${action?.checklist?.number}`">
              {{ action?.checklist?.number }}
            </RouterLink>
            <div v-else class="text-secondary">—</div>
          </div>
        </div> -->
        <CreateForm ref="formRef" :data="action" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  padding: 40px;
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
}
</style>
