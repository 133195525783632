<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useStore } from 'vuex'
import { useDialog, NAlert } from 'naive-ui'

import { useIncidentsStore } from '@/store/incidents'
import useTarifExtension from '@/composables/useTarifExtension'

import ViewTabMain from '@/components/pages/Incidents/ViewTabMain'
import Documents from '@/components/widgets/custom-components/tabs/organization-tabs/Documents'

import ViewTabActions from '@/components/pages/Incidents/ViewTabActions'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import SelectProcessModal from '@/components/pages/Actions/actionComponents/selectProcessModal'

const { hasPermission } = useTarifExtension()
const route = useRoute()
const router = useRouter()
const dialog = useDialog()

const vuexStore = useStore()
const incidentsStore = useIncidentsStore()
const { isLoading, isError, item: incident } = storeToRefs(incidentsStore)

const currentTab = ref('main')
const selectProcessModalRef = ref(null)

const incidentId = computed(() => route.params.id)

onMounted(() => {
  vuexStore.dispatch('DOCUMENTS_REFRESH_ALL_CATEGORIES')

  incidentsStore.fetchOne(incidentId.value)
  // incidentsStore.fetchDocs(incidentId.value)
})

function changeTab(tab = 'main') {
  currentTab.value = tab
}

function getToWork() {
  const d = dialog.success({
    title: 'Подтвердите действие',
    positiveText: 'Да',
    negativeText: 'Отмена',
    onPositiveClick: () => {
      d.loading = true
      return incidentsStore.updateStatus(incidentId.value, 2)
    }
  })
}

function closeHandler() {
  const d = dialog.error({
    title: 'Подтвердите действие',
    positiveText: 'Да',
    negativeText: 'Отмена',
    onPositiveClick: () => {
      d.loading = true
      return incidentsStore.updateStatus(incidentId.value, 3)
    }
  })
}

function deleteHandler() {
  const d = dialog.error({
    title: 'Подтвердите действие',
    positiveText: 'Да',
    negativeText: 'Отмена',
    onPositiveClick: () => {
      d.loading = true
      return incidentsStore.deleteById(incidentId.value).then((res) => {
        if (res.status === 200) {
          router.push('/incidents')
        }
      })
    }
  })
}

const showCloseButton = computed(
  () => incident.value.status == 2 && incident.value.responsible.id === vuexStore.getters.currentUser.id
)

const showEditButton = computed(() => incident.value.status !== 3)
</script>

<template>
  <SpinLoader v-if="isLoading && !isError" class="py-5" size="2xl" />
  <template v-else-if="!isLoading && !isError && incident">
    <div class="page-header">
      <div class="page-header__left">
        <div class="page-header__text">
          <div class="page-header__heading">Нежелательное событие #{{ incidentId }}</div>
          <div class="page-header__second-heading">{{ incident?.medtype?.text || '' }}</div>
        </div>
      </div>
      <div class="page-header__right">
        <div class="page-header__controls">
          <!-- если статус НОВОЕ -->
          <NextButton v-if="incident.status == 1" type="next" text="Взять в работу" @click="getToWork" />

          <!-- если статус В_РАБОТЕ -->
          <NextButton v-if="showCloseButton" type="next" text="Закрыть" @click="closeHandler" />

          <NextButton
            v-if="showEditButton"
            :to="`/incidents/${incidentId}/edit`"
            type="edit"
            text="Редактировать"
            color="white"
          />

          <NextButton v-if="incident.status != 3" type="close" text="Удалить" color="red" @click="deleteHandler" />
        </div>
      </div>
    </div>
    <div class="tabs">
      <div class="tab pointer" :class="{ active: currentTab === 'main' }" @click="changeTab('main')">
        Общая информация
      </div>
      <div class="tab pointer" :class="{ active: currentTab === 'actions' }" @click="changeTab('actions')">
        Мероприятия
      </div>
      <div class="tab pointer" :class="{ active: currentTab === 'documents' }" @click="changeTab('documents')">
        Документы
      </div>
    </div>
    <div class="tab-content">
      <ViewTabMain v-if="currentTab === 'main'" :data="incident" />
      <ViewTabActions v-if="currentTab === 'actions'" :data="incident" />

      <div v-else-if="currentTab === 'documents'">
        <NAlert v-if="!hasPermission(18)" class="mb-5" :bordered="false" title="Доступен просмотр" type="info">
          Для получения полного доступа необходимо сменить тариф
        </NAlert>
        <div :class="{ 'disabled-by-tarif': !hasPermission(18) }">
          <Documents type="inevent" :document-id="incidentId" />
        </div>
      </div>
    </div>
  </template>
  <NextAlert v-if="isError && !isLoading" type="error" :text="isError" />
  <SelectProcessModal ref="selectProcessModalRef" />
</template>
