<template>
  <div class="item" :class="{full: openFullName}">
    <div class="id-column list-column text" @click="openFullName = !openFullName">
      <router-link :to="'/actions/' + item.id">{{ item.id }}</router-link>
    </div>
    <div class="action-column list-column text" @click="openFullName = !openFullName">{{ item.text }}</div>
    <div class="type-column list-column text" @click="openFullName = !openFullName">{{ item.type.text }}</div>
    <div class="commentary-column list-column text" @click="openFullName = !openFullName">{{ item.object.text }}</div>
    <div class="owner-column list-column text" @click="openFullName = !openFullName">{{ item?.responsible?.name }}</div>
    <div class="date-column list-column text" @click="openFullName = !openFullName">
      {{ $helper.makeDate(item.plan_date) }}
    </div>
    <div class="date-column list-column text" @click="openFullName = !openFullName">
{{ $helper.makeDate(item.finish_date) }}
    </div>
    <div class="status-column list-column text" @click="openFullName = !openFullName">{{ item.status.text }}</div>
  </div>
</template>

<script>
export default {
  name: 'ActionListItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      openFullName: false
    }
  },
}
</script>

<style scoped>
.item.gray {
  background-color: #F2F2F2;
}

.full .text {
  white-space: normal;
}

.text {
  white-space: nowrap; /* Текст не переносится */
  overflow: hidden; /* Обрезаем всё за пределами блока */
  text-overflow: ellipsis;
  cursor: pointer;
}

.list-column {
  border: 1px solid #CCCCCC;
  width: 100%;
  font-size: 14px;
  padding: 10px 10px;
  color: white;
  text-align: left;
}

.date-column {
  width: 160px;
  flex: none;
  text-align: center;
}

.status-column {
  width: 130px;
  flex: none;
}

.owner-column {
  width: 175px;
  flex: none;
}

.commentary-column {
  width: 175px;
  flex: none;
}

.type-column {
  width: 175px;
  flex: none;
}

.action-column {
  width: 100%;
}

.item {
  display: flex;
}

.item .list-column {
  color: #333333;
}

.id-column {
  width: 75px;
  flex: none;
}
</style>