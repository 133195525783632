<template>
  <div v-if="isLoadingActions" class="flex">
    <SpinLoader style="display: inline-block;" class="w-8" />
    <p class="h5">Загрузка данных раздела Мероприятия...</p>
  </div>

  <div v-if="!isLoadingActions && actionsReportData" class="mb-5">
    <NDivider>
      <h2 style="font-size: 28px; text-align: center;">
        Отчёт Мероприятия
      </h2>
    </NDivider>

    <div class="mb-4">
      <h5>Мероприятия по типу за период</h5>
      <div v-for="data in Object.entries(actionsReportTypesCount)" :key="data">
        {{ data[0] }}: <strong>{{ data[1] }}</strong>
      </div>
    </div>

    <!-- <div class="flex" style="gap: 5px; border-bottom: 1px solid #ccc;" :style="{ borderTop: '1px solid #ccc' }">
      <span class="px-2 py-1 h-cell" style="width: 30%;">Мероприятия</span>
      <span class="px-2 py-1 h-cell" style="width: 8%;">
        Дата начала
      </span>
      <span class="px-2 py-1 h-cell" style="width: 8%;">
        Плановая дата закрытия
      </span>
      <span class="px-2 py-1 h-cell" style="width: 8%;">
        Дата закрытия
      </span>
      <span class="px-2 py-1 h-cell" style="width: 14%;">
        Ответственный
      </span>
      <span class="px-2 py-1 h-cell" style="width: 10%;">
        Тип мероприятия
      </span>
      <span class="px-2 py-1 h-cell" style="width: 10%;">
        Объект учета
      </span>
      <span class="px-2 py-1 h-cell" style="width: 10%;">
        Статус
      </span>
    </div> -->

    <div v-for="[key, val] in Object.entries(matchedActionsGroupByDepartmentResponsible)" :key="key" class="mb-1">
      <h4 class="my-3" style="text-align: center;">Направление "{{ key }}"</h4>

      <div v-for="[k, dataArray] in Object.entries(val)" :key="k">
        <div v-if="dataArray.length">
          <p class="my-2" style="font-size: 16px; font-weight: bold;">{{ k }}</p>

          <NextTable
            :columns="[
              { key: 'text', title: 'Мероприятия', width: '30%' },
              { key: 'start_date', title: 'Дата начала', width: '8%' },
              { key: 'plan_date', title: 'Плановая дата закрытия', width: '8%' },
              { key: 'finish_date', title: 'Дата закрытия', width: '8%' },
              { key: 'responsible', title: 'Ответственный', width: '14%' },
              { key: 'type', title: 'Тип мероприятия', width: '10%' },
              { key: 'object', title: 'Объект учета', width: '10%' },
              { key: 'status', title: 'Статус', width: '10%' },
            ]" 
            :table-data="dataArray"
            cellpadding="8"
            border="1"
            style="border-collapse: collapse;"
          >
            <template #text="{ row }">
              <RouterLink :to="`/actions/${row.id}`">{{ row.text }}</RouterLink>
            </template>
            <template #start_date="{ value }">
              {{ moment(value).format('DD.MM.YYYY') }}
            </template>
            <template #plan_date="{ value }">
              {{ moment(Number(value)).format('DD.MM.YYYY') }}
            </template>
            <template #finish_date="{ value }">
              {{ value ? moment(Number(value)).format('DD.MM.YYYY') : '' }}
            </template>
            <template #responsible="{ value }">
              {{ value.name }}
            </template>
            <template #type="{ value }">
              {{ value.text }}
            </template>
            <template #object="{ value }">
              {{ value.text }}
            </template>
            <template #status="{ value }">
              {{ value.text }}
            </template>
          </NextTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from 'vue'
import { RouterLink } from 'vue-router';
import moment from 'moment';
import { NDivider } from 'naive-ui';
import { cloneDeep } from 'lodash';

import NextTable from '@/components/widgets/simple-components/table/NextTable'
import useActionsReport from '@/composables/useActionsReport'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'

const props = defineProps({
  range: {
    type: Array,
    default: () => ['', ''],
  },
  department: {
    type: Object,
  },
  subdivision: {
    type: Object,
  },
})

const actionsReport = useActionsReport()

const isLoadingActions = ref(true)
const actionsReportData = ref(null)
const actionsReportTypesCount = ref(null)

onMounted(() => {
  fetchData()
})

const params = {
  offset: 0,
  limit: 99999,
  sort_type: 'id',
  sort_direction: 'desc',
  date_from: '2024-01-01',
  date_to: '2024-12-31',
};

const fetchData = async () => {
  isLoadingActions.value = true

  actionsReportData.value = await actionsReport.fetch(params)
  actionsReportTypesCount.value = actionsReport.getCountByTypes()

  isLoadingActions.value = false
}

const matchedActionsGroupByDepartmentResponsible = computed(() => {
  if (!props.department) {
    return actionsReportData.value;
  }

  const actionsReportDataValue = cloneDeep(actionsReportData.value);

  Object.entries(actionsReportDataValue).forEach(([medtypeKey, medtypeValue]) => {
    Object.entries(medtypeValue).forEach(([processName, actionsGroupArray]) => {
      const matchedActionsByDepartmentResponsibleUser = actionsGroupArray.filter((action) => {
        const responsibleId = Number(action.responsible.id);

        if (props.subdivision) {
          return props.subdivision.members.some((memberUser) => Number(memberUser.id) === responsibleId);
        }

        return props.department.members.some((memberUser) => Number(memberUser.id) === responsibleId);
      });

      actionsReportDataValue[medtypeKey][processName] = matchedActionsByDepartmentResponsibleUser;
    });
  });

  return actionsReportDataValue;
});

watch(
  () => props.range,
  (newRange) => {
    if (newRange[0] && newRange[1]) {
      params.date_from = moment(new Date(newRange[0]).getTime()).format('YYYY-MM-DD');
      params.date_to = moment(new Date(newRange[1]).getTime()).format('YYYY-MM-DD');
      fetchData();
    }
  },
  { immediate: false }
)
</script>

<style scoped lang="scss">
.h-cell {
  font-weight: bold;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-right: 1px solid #ccc;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}

.cell {
  font-size: 13px;
  text-align: center;
  border-right: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 4px 8px;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}
</style>
