<template>
  <div>
    <div v-if="activeTab === 'file-manager'" class="content" style="width: 90vw">
      <FilesManager :key="activeTab" :current-type="type" :type="'modal'" @click-handler="selectFile" />
      <div class="back-button" @click="activeTab = 'main'">
        <div class="arrow-icon" />
        Вернуться
      </div>
    </div>
    <div
      v-if="activeTab === 'main'"
      class="content"
      :class="{ big: (type === 'process' || type === 'requirement') && id == '' }"
    >
      <input ref="uploadFile" type="file" hidden @change="appendFile" />
      <div class="select">
        <div
          v-if="file === ''"
          ref="uploadArea"
          class="upload-area"
          :class="{ big: file === '' && publicUpload }"
          @click="openUpload"
        >
          <div class="upload-icon" />
          <div class="upload-header">Загрузить документ</div>
          <div class="upload-description">Перетащите ваш файл в эту область или выберите файл</div>
        </div>
        <div v-if="file === '' && !publicUpload" class="upload-area" @click="activeTab = 'file-manager'">
          <div
            class="file-icon big-icon"
            style="width: 40px; height: 40px; background-size: contain; background-repeat: no-repeat"
          />
          <div class="upload-header">Выбрать документ</div>
          <div class="upload-description">Выберите файл из загруженных ранее</div>
        </div>
      </div>
      <div class="document-info">
        <div v-if="file !== ''" class="info">
          <div class="file-text">
            <div class="file-icon" />
            {{ file.name }}
          </div>
          <div v-if="!file.id" class="file-size">({{ (file.size / 1024).toFixed(2) }} КБ)</div>
          <div v-else class="file-info-date">
            Загружен:
            <span style="color: #00cccc">{{ $helper.makeDateTime(file.upload_date) }}</span>
          </div>
          <div class="file-delete" @click="clearFile">
            Удалить
            <div class="file-delete-icon icon" />
          </div>
        </div>
        <div v-if="(type === 'process' || type === 'requirement') && id == ''" class="document-append">
          <div class="document-header">Загрузить файл для: <span style="color: #e72222">*</span></div>
          <div class="accordion-list">
            <div v-if="type === 'process'" class="content-info" style="padding-bottom: 10px">
              <dropdownListWithHeader
                :key="medType"
                :start-item="medType"
                placeholder="Выберите тип мед. организации"
                :search="false"
                :list="organizationMedTypes"
                @select-handler="medTypeSelect"
              />
            </div>
            <template v-if="medType !== '' && type === 'process'">
              <template v-for="item in organizationMedTypes" :key="item.id">
                <div v-if="medType.id === item.id" class="accordion-item">
                  <OrgAccordion
                    :can-select-all="true"
                    :forever-opened="true"
                    :selected-items="selectedProcesses"
                    :items="item.processes"
                    :header="item.text"
                    @item-click="selectProcess"
                  />
                </div>
              </template>
            </template>
            <template v-if="type === 'requirement'">
              <OrgAccordion
                :can-select-all="true"
                :forever-opened="true"
                :selected-items="selectedProcesses"
                :items="requirements"
                :header="processName"
                @itemClick="selectProcess"
              />
            </template>
          </div>
        </div>
        <div class="document-header">Данные о файле:</div>
        <div class="file-info">
          <div class="file-item">
            <inputWithHeader
              v-if="!file.id"
              header="Название документа"
              :required="true"
              type="text"
              placeholder="Введите название документа"
              @inputHandler="setName"
            />
            <inputWithHeader
              v-else
              :disabled="true"
              :start-text="file.name"
              header="Название документа"
              :required="true"
              type="text"
              placeholder="Введите название документа"
            />
          </div>
          <div class="file-item">
            <dropdownListWithHeader
              v-if="!file.id"
              :search="false"
              :required="true"
              placeholder="Выберите категорию"
              header="Категория документа"
              :autoclose="true"
              :list="$store.getters.documentsCategory"
              @selectHandler="selectCategory"
            />
            <inputWithHeader
              v-else
              :disabled="true"
              :start-text="file.text"
              header="Категория документа"
              :required="true"
              type="text"
              placeholder="Выберите категорию"
            />
          </div>
        </div>

        <div class="document-text" style="padding-bottom: 15px">
          <span>*</span> Поля отмеченные звёздочкой являются обязательными
        </div>
      </div>

      <div class="content-buttons">
        <nextButton v-if="validate" type="next" color="green" text="ДОБАВИТЬ" @click-handler="uploadHandler" />
        <nextButton v-else type="next" color="green" class="inactive" text="ДОБАВИТЬ" />
        <nextButton type="close" color="white" text="ОТМЕНИТЬ" @click-handler="close" />
      </div>

      <div v-if="isLoading" class="pt-4 pb-2 flex">
        <NSpin size="small" class="mr-4" />
        <span class="pt-1">Выполняется загрузка...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { NSpin } from 'naive-ui'
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import OrgAccordion from '@/components/widgets/custom-components/accordion/OrgAccordion'
// import fileManager from '@/components/widgets/custom-components/modalWindow/modalComponents/fileManager/fileManager'
import FilesManager from '@/components/widgets/custom-components/modalWindow/modalComponents/fileManager/FilesManager'

export default {
  name: 'UploadDocuments',
  components: { inputWithHeader, dropdownListWithHeader, nextButton, OrgAccordion, FilesManager, NSpin },
  props: {
    publicUpload: {
      type: Boolean,
      default() {
        return false
      }
    },
    process: {
      default() {
        return ''
      }
    },
    type: {
      type: String,
      requirement: true
    },
    id: {
      default() {
        return ''
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dragAndDropCapable: false,
      file: '',
      name: '',
      category: '',
      medType: '',
      activeTab: 'main',
      accordionItems: [],
      organizationMedTypes: [],
      selectedProcesses: [],
      processes: [],
      requirements: [],
      processName: ''
    }
  },
  computed: {
    validate() {
      if (this.file !== '' && this.name !== '' && this.category !== '') {
        if (this.type === 'requirement' || this.type === 'process') {
          if (this.selectedProcesses.length === 0) {
            return false
          }
        }
      } else {
        return false
      }
      return true
    }
  },
  beforeMount() {
    if (this.type === 'requirement') {
      if (this.id === '') {
        this.$api.get('process/get-process-requirements?process_id=' + this.process.id).then((res) => {
          this.requirements = res.data.data
          this.processName = this.process.text
        })
      } else {
        this.$api.get('requirements/get-requirement-process?requirement_id=' + this.id).then((res) => {
          this.processName = res.data.data.text
          this.$api.get('process/get-process-requirements?process_id=' + res.data.data.id).then((res) => {
            this.requirements = res.data.data
            this.initRequirement()
          })
        })
      }
    }
    if (this.type === 'process') {
      this.$api.get('organization/get-organization-medtypes-with-processes').then((res) => {
        if (res.data.ok === 1) {
          this.organizationMedTypes = res.data.data
          if (this.id != '') {
            this.initProcess()
          }
        }
      })
    }
  },
  mounted() {
    this.dropUploadInit()
    this.$store.dispatch('DOCUMENTS_REFRESH_ALL_CATEGORIES')
  },
  methods: {
    selectFile(file) {
      this.file = file
      this.name = this.file.name
      this.category = this.file.category_id
      this.activeTab = 'main'
    },
    initRequirement() {
      this.requirements.map((item) => {
        if (item.organization_requirement.id === this.id) {
          item.active = true
          this.selectProcess(item)
        }
      })
    },
    initProcess() {
      let allProcesses = []
      this.organizationMedTypes.map((item) => (allProcesses = [...allProcesses, ...item.processes]))
      allProcesses.map((item) => {
        if (item.id === this.id) {
          this.organizationMedTypes.map((medType) => {
            if (medType.id === item.medtype_id) {
              this.medTypeSelect(medType)
            }
          })
          item.active = true
          this.selectProcess(item)
        }
      })
    },
    medTypeSelect(val) {
      this.selectedProcesses.map((item) => (item.active = false))
      this.selectedProcesses.splice(0, this.selectedProcesses.length)
      this.medType = val
    },
    selectProcess(val) {
      val.pause = false
      if (val.active) {
        this.selectedProcesses.push(val)
      } else {
        this.selectedProcesses.splice(this.selectedProcesses.indexOf(val), 1)
      }
    },
    determineDragAndDropCapable() {
      var div = document.createElement('div')
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      )
    },
    dropUploadInit() {
      this.dragAndDropCapable = this.determineDragAndDropCapable()
      if (this.dragAndDropCapable) {
        const events = ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']
        events.forEach(
          function (evt) {
            this.$refs.uploadArea.addEventListener(
              evt,
              function (e) {
                e.preventDefault()
                e.stopPropagation()
              }.bind(this),
              false
            )
          }.bind(this)
        )
        this.$refs.uploadArea.addEventListener(
          'drop',
          function (e) {
            this.file = e.dataTransfer.files[0]
          }.bind(this)
        )
      }
    },
    close() {
      this.$emit('closeHandler')
    },
    clearFile() {
      this.file = ''
      this.$refs.uploadFile.value = null
    },
    selectCategory(val) {
      this.category = val.id
    },
    setName(val) {
      this.name = val
    },
    uploadHandler() {
      var ids = this.id

      if (this.type === 'requirement' || this.type === 'process') {
        ids = []
        for (let i = 0; i < this.selectedProcesses.length; i++) {
          if (this.type === 'requirement') {
            ids.push(this.selectedProcesses[i].organization_requirement.id)
          } else {
            ids.push(this.selectedProcesses[i].id)
          }
        }
      }

      let formData = new FormData()
      if (this.file.id) {
        formData.append('old_file', this.file.id)
      } else {
        formData.append('file', this.file)
      }
      formData.append('fileName', this.name)
      formData.append('category', this.category)
      formData.append('documentId', ids)
      this.$emit('uploadHandler', formData)
    },
    removeOrganizationProcess(item) {
      this.selectedProcesses = this.selectedProcesses.filter((process) => process !== item)
    },
    appendFile(e) {
      let files = e.target.files
      this.file = files[0]
    },
    openUpload() {
      this.$refs.uploadFile.click()
    }
  }
}
</script>

<style scoped>
.file-info-date {
  width: 250px;
  flex: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}
.back-button {
  padding: 20px 40px;
  border-radius: 6px;
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
  color: white;
  cursor: pointer;
  display: flex;
  width: fit-content;
  margin-bottom: 20px;
}
.arrow-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='11' viewBox='0 0 6 11' fill='none'%3E%3Cg filter='url(%23filter0_d_332_93)'%3E%3Cpath d='M0.209113 1.42248C0.0738297 1.25599 -1.81198e-05 1.04801 -1.81198e-05 0.833484C-1.81198e-05 0.61896 0.0738297 0.410975 0.209113 0.244484C0.269514 0.168465 0.346298 0.107064 0.433738 0.0648581C0.521179 0.0226525 0.61702 0.000732422 0.714113 0.000732422C0.811207 0.000732422 0.907047 0.0226525 0.994488 0.0648581C1.08193 0.107064 1.15871 0.168465 1.21911 0.244484L4.79111 4.41048C4.9264 4.57697 5.00024 4.78496 5.00024 4.99948C5.00024 5.21401 4.9264 5.42199 4.79111 5.58848L1.21911 9.75548C1.15871 9.8315 1.08193 9.8929 0.994488 9.93511C0.907047 9.97732 0.811207 9.99924 0.714113 9.99924C0.61702 9.99924 0.521179 9.97732 0.433738 9.93511C0.346298 9.8929 0.269514 9.8315 0.209113 9.75548C0.0738297 9.58899 -1.81198e-05 9.38101 -1.81198e-05 9.16648C-1.81198e-05 8.95196 0.0738297 8.74397 0.209113 8.57748L3.27611 4.99948L0.209113 1.42248Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_332_93' x='0' y='0.000732422' width='6.00024' height='10.9985' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='1' dy='1'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.0117647 0 0 0 0 0.533333 0 0 0 0 0.52549 0 0 0 0.5 0'/%3E%3CfeBlend mode='multiply' in2='BackgroundImageFix' result='effect1_dropShadow_332_93'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_332_93' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
  width: 10px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  margin-right: 10px;
}
.accordion-item {
  padding-bottom: 10px;
}
.accordion-list {
  height: 250px;
  overflow-y: auto;
  padding: 10px;
  border: 1px dashed #cccc;
}
.big .file-info {
  flex-direction: row;
}
.info {
  display: flex;
  min-width: 650px;
}
.file-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.med-item__text {
  font-size: 12px;
}
.med-item {
  display: flex;
  padding: 10px;
  align-items: center;
}
.document-append {
  padding-bottom: 15px;
}
.content-list {
  max-height: 145px;
  overflow-y: auto;
  flex-wrap: wrap;
  display: flex;
  max-width: 500px;
  width: 500px;
}
.content-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.file-icon {
  margin-right: 10px;
  flex: none;
  width: 10px;
  height: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_536_6715)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 4.95831L9.9585 4.99982L10 4.99982L10 9.49982L10 10.4998L-4.37597e-07 10.4998L-1.34756e-06 9.49982L-4.41942e-08 1.49982L-4.82844e-10 0.499815L5.5 0.499816L5.5 0.519228L5.53046 0.488769L10 4.95831ZM1 9.49982L9 9.49982L9 5.99982L4.5 5.99982L4.5 4.99982L5.5 4.99982L8.62729 4.99982L5.5 1.87253L5.5 4.99982L4.5 4.99982L4.5 1.49981L1 1.49981L1 9.49982Z' fill='%231CC8AB'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_536_6715'%3E%3Crect width='10' height='10' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}
.file-delete-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.281997 8.87647L3.65724 5.50123L0.281998 2.12599C0.113436 1.94548 0.0226469 1.70643 0.0268716 1.45949C0.0310964 1.21255 0.130552 0.977391 0.305191 0.802752C0.47983 0.628114 0.714988 0.527437 0.961928 0.523212C1.20887 0.518987 1.44792 0.610997 1.62843 0.779559L5.00367 4.14747L8.37891 0.772235C8.55863 0.596346 8.80004 0.498429 9.05151 0.500019C9.30298 0.501609 9.54419 0.602633 9.72168 0.78078C9.89917 0.958928 9.99934 1.20069 10 1.45217C10.0007 1.70364 9.90189 1.94448 9.72534 2.12355L6.3501 5.49879L9.72534 8.87403C9.8939 9.05454 9.98591 9.29359 9.98169 9.54053C9.97746 9.78747 9.87678 10.0226 9.70215 10.1973C9.52751 10.3719 9.29235 10.4714 9.04541 10.4756C8.79847 10.4798 8.55942 10.389 8.37891 10.2205L5.00367 6.84522L1.62843 10.2205C1.54025 10.3092 1.43512 10.3787 1.31959 10.4268C1.20407 10.4748 1.08095 10.5 0.955824 10.5C0.830695 10.5 0.706358 10.4748 0.590835 10.4268C0.475311 10.3787 0.370179 10.3092 0.281997 10.2205C0.193022 10.1328 0.122557 10.0281 0.0744787 9.91284C0.0263992 9.79758 0.00213201 9.67396 0.00245722 9.54907C0.00265655 9.42408 0.0276552 9.29948 0.0756994 9.18408C0.123744 9.06869 0.193431 8.96467 0.281997 8.87647Z' fill='%23D65959'/%3E%3C/svg%3E%0A");
  width: 10px;
  height: 10px;
}
.file-size {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  padding-left: 5px;
  text-align: left;
  color: #666666;
  width: 120px;
  flex: none;
}
.big .file-item {
  width: 45%;
  padding-bottom: 0;
}
.file-item {
  width: 100%;
  padding-bottom: 10px;
}
.file-delete {
  cursor: pointer;
  width: 80px;
  color: #d65959;
  flex: none;
}
.file-text {
  display: flex;
  font-size: 14px;
  width: 100%;
  align-items: center;
}
.file-info {
  border: 1px dashed #cccccc;
  padding: 10px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.document-text span {
  color: #1cc8ab;
}
.document-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #666666;
}
.document-header {
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  padding-bottom: 13px;
  color: #333333;
}
.red-christ-icon {
  margin-left: 5px;
}
.select {
  display: flex;
  justify-content: space-between;
}
.upload-area.big {
  width: 100%;
}
.upload-area {
  color: #1cc8ab;
  cursor: pointer;
  margin-bottom: 12px;
  min-height: 50px;
  width: 49%;
  border: 1px dashed #37c389;
  padding: 20px 40px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.upload-header {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  padding-bottom: 7px;
  color: #1cc8ab;
}
.upload-description {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1cc8ab;
}
.file-icon.big-icon {
  width: 40px;
  height: 32px;
  margin-bottom: 10px;
}
.upload-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='32' viewBox='0 0 40 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.4143 13L19 17.5857V0H21V17.7277L25.7279 12.9998L27.1421 14.4141L21.4852 20.0709L21.4854 20.0711L20.0712 21.4853L20.071 21.4851L18.6568 20.0709L13.0001 14.4142L14.4143 13ZM2 30L2 12H0V30V32H2H38H40V30V12H38V30H2Z' fill='%231CC8AB'/%3E%3C/svg%3E%0A");
  width: 40px;
  height: 32px;
  margin-bottom: 10px;
}
.content.big {
  width: calc(100vw - 200px);
}
.content {
  width: 100%;
  padding: 20px;
}
</style>
