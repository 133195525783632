<template>
  <component :is="isMobile ? HeaderMobile : HeaderDesktop" />
</template>

<script setup>
import { inject, computed } from 'vue'
import HeaderDesktop from './HeaderDesktop.vue'
import HeaderMobile from './HeaderMobile.vue'

const isMobileValue = inject('isMobile')
const isMobile = computed(() => isMobileValue.value)
</script>
