<template>
  <div>
    <div class="flex justify-content-end mb-4">
      <NButton class="" type="primary" @click="showAddModal = true"> Создать подразделение </NButton>
    </div>

    <div v-for="department in departments" :key="department.id" class="mb-4 card p-4">
      <div class="flex align-items-center justify-content-between mb-3">
        <div class="flex">
          <div class="mr-5"><strong>Название:</strong> {{ department.name }}</div>
          <NButton size="small" secondary @click="onOpenDepartment(department)">Подробнее</NButton>
        </div>

        <NPopconfirm v-if="userIsSuperAdmin" @positive-click="onDeleteDepartment(department)">
          <template #trigger>
            <NButton size="small" secondary type="error">Удалить</NButton>
          </template>
          Подтвердите удаление подразделения
        </NPopconfirm>
      </div>
      <div class="mb-2"><strong>Описание:</strong> {{ department.description }}</div>

      <!-- <div>
        <div><strong>Отделы:</strong></div>
        <div v-if="department.organizationSubdivisions?.length === 0" class="pl-4 mt-1">Нет данных</div>

        <div v-for="(subdivision, i) in department.organizationSubdivisions" :key="subdivision.id" class="pl-4 mt-1">
          {{ `${i + 1}. ${subdivision.name}` }}
        </div>
      </div> -->
    </div>

    <NModal v-model:show="showAddModal">
      <NCard
        style="width: 800px"
        title="Создание подразделения"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
      >
        <NForm ref="formRef" :model="formValue" :rules="rules">
          <NFormItem label="Название" path="name">
            <NInput v-model:value="formValue.name" size="large" />
          </NFormItem>

          <NFormItem label="Описание" path="description">
            <NInput v-model:value="formValue.description" size="large" type="textarea" />
          </NFormItem>

          <NFormItem label="Руководитель" path="head_id">
            <EntrySelect
              v-model:value="formValue.head_id"
              request="responsible/get"
              label-field="name"
              sortable
              filterable
            />
          </NFormItem>

          <NFormItem>
            <NButton type="primary" @click="handleValidateClick">Сохранить</NButton>
          </NFormItem>
        </NForm>
      </NCard>
    </NModal>

    <NModal v-model:show="showDepartmentModal" :on-after-leave="clearDepartmentModal">
      <NCard style="width: 1100px" :bordered="false" size="huge" role="dialog" aria-modal="true">
        <template #header>
          <span>Подразделение {{ currentDepartment.name }}</span>
        </template>
        <NSpin :show="Object.keys(currentDepartment).length === 0">
          <div class="mb-4">
            <div class="font-weight-bold mb-2">Название</div>
            <NInput v-model:value="currentNameAtDepartment" class="mb-4" size="large" />

            <div class="font-weight-bold mb-2">Описание</div>
            <NInput v-model:value="currentDescriptionAtDepartment" class="mb-4" type="textarea" size="large" />

            <div class="font-weight-bold mb-2">Руководитель</div>
            <NSelect
              v-model:value="currentHeadIdAtDepartment"
              filterable
              size="large"
              class="mb-4"
              :options="responsibleUsers"
            />

            <div class="font-weight-bold mb-2">Сотрудники подразделения</div>
            <NSelect
              v-model:value="currentMembersAtDepartment"
              multiple
              filterable
              size="large"
              :options="responsibleUsers"
            />
          </div>

          <div class="flex justify-content-end mb-2">
            <NButton type="primary" @click="saveDepartment">Сохранить</NButton>
          </div>

          <div>
            <div style="font-size: 18px"><strong>Отделы:</strong></div>
            <div v-if="currentDepartment.organizationSubdivisions?.length === 0" class="pl-4 mt-1">Нет данных</div>

            <div
              v-for="(subdivision, i) in currentDepartment.organizationSubdivisions"
              :key="subdivision.id"
              class="mt-2 py-1 flex"
            >
              <div class="mr-4" style="min-width: 100px">
                {{ `${i + 1}. ${subdivision.name}` }}
              </div>
              <NButton size="small" secondary @click="onOpenSubdivision(subdivision)">Подробнее</NButton>
              <NPopconfirm @positive-click="onDeleteSubdivision(subdivision.id)">
                <template #trigger>
                  <NButton class="ml-1" size="small" type="error" secondary>Удалить</NButton>
                </template>
                <div class="mb-1">Подтвердите действие</div>
              </NPopconfirm>
            </div>

            <NButton class="mt-3" type="primary" @click="onAddSubdivision">Добавить отдел</NButton>
          </div>
        </NSpin>
      </NCard>
    </NModal>

    <NModal v-model:show="showAddSubdivisionModal">
      <NCard
        style="width: 800px"
        :title="`Добавить новый отдел для подразделения ${currentDepartment.name}`"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
      >
        <NForm ref="formRefSubdivision" :model="formValueSubdivision" :rules="rules">
          <NFormItem label="Название" path="name">
            <NInput v-model:value="formValueSubdivision.name" size="large" />
          </NFormItem>

          <NFormItem label="Описание" path="description">
            <NInput v-model:value="formValueSubdivision.description" size="large" type="textarea" />
          </NFormItem>

          <NFormItem label="Руководитель" path="head_id">
            <EntrySelect
              v-model:value="formValueSubdivision.head_id"
              request="responsible/get"
              label-field="name"
              sortable
              filterable
            />
          </NFormItem>

          <NFormItem>
            <NButton type="primary" @click="submitCreateSubdivision">Сохранить</NButton>
          </NFormItem>
        </NForm>
      </NCard>
    </NModal>

    <NModal v-model:show="showSubdivisionModal" :on-after-leave="clearSubdivisionModal">
      <NCard style="width: 900px" :bordered="false" size="huge" role="dialog" aria-modal="true">
        <NSpin :show="Object.keys(currentSubdivision).length === 0">
          <template #header> Отдел {{ currentSubdivision.name }} </template>
          <div class="mb-4">
            <div class="font-weight-bold mb-2">Название</div>
            <NInput v-model:value="currentSubdivisionData.name" class="mb-4" size="large" />

            <div class="font-weight-bold mb-2">Описание</div>
            <NInput v-model:value="currentSubdivisionData.description" class="mb-4" type="textarea" size="large" />

            <div class="font-weight-bold mb-2">Руководитель</div>
            <NSelect
              v-model:value="currentSubdivisionData.head_id"
              filterable
              size="large"
              class="mb-4"
              :options="responsibleUsers"
            />

            <div class="font-weight-bold mb-2">Сотрудники отдела</div>
            <NSelect
              v-model:value="currentSubdivisionData.members"
              multiple
              filterable
              size="large"
              :options="responsibleUsersForSubdivision"
            />
          </div>

          <div class="flex justify-content-end mb-2">
            <NButton type="primary" @click="saveSubdivision">Сохранить</NButton>
          </div>
        </NSpin>
      </NCard>
    </NModal>
  </div>
</template>

<script>
import { ref } from 'vue'
import {
  NButton,
  NAlert,
  NSelect,
  NInput,
  NIcon,
  useMessage,
  NModal,
  NCard,
  NForm,
  NFormItem,
  NPopconfirm,
  NSpin
} from 'naive-ui'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'
import EntrySelect from '@/components/common/EntitySelect'
import { useOrganizationStore } from '@/store/organization'
import { storeToRefs } from 'pinia'
import { reactive } from 'vue'

export default {
  name: 'DepartmentsTab',
  components: {
    NextButton,
    NButton,
    NAlert,
    NSelect,
    NInput,
    NIcon,
    NModal,
    NCard,
    NForm,
    NFormItem,
    NPopconfirm,
    EntrySelect,
    NSpin
  },
  props: {},
  setup() {
    const userIsSuperAdmin = [1].includes(JSON.parse(localStorage.user).role_id)

    const {
      createDepartment,
      getDepartments,
      getDepartmentById,
      updateDepartment,
      updateSubdivision,
      createSubdivision,
      deleteSubdivision,
      deleteDepartment,
      getSubdivisionById,
      clearDepartment,
      clearSubdivision
    } = useOrganizationStore()
    const { departments, currentDepartment, currentSubdivision } = storeToRefs(useOrganizationStore())

    const showAddModal = ref(false)
    const showAddSubdivisionModal = ref(false)

    const showDepartmentModal = ref(false)
    const showSubdivisionModal = ref(false)

    const message = useMessage()
    const formRef = ref(null)
    const formRefSubdivision = ref(null)

    const formValue = ref({
      name: '',
      description: '',
      head_id: null
    })
    const formValueSubdivision = ref({
      name: '',
      description: '',
      head_id: null
    })

    const currentNameAtDepartment = ref('')
    const currentDescriptionAtDepartment = ref('')
    const currentHeadIdAtDepartment = ref('')
    const currentMembersAtDepartment = ref([])

    let currentSubdivisionData = reactive({
      name: '',
      description: '',
      head_id: '',
      members: []
    })

    const responsibleUsers = ref([])

    return {
      userIsSuperAdmin,
      showAddModal,
      showDepartmentModal,
      showSubdivisionModal,
      showAddSubdivisionModal,
      currentDepartment,
      currentSubdivision,
      formRef,
      formRefSubdivision,
      departments,
      message,
      formValue,
      formValueSubdivision,
      currentNameAtDepartment,
      currentDescriptionAtDepartment,
      currentHeadIdAtDepartment,
      currentMembersAtDepartment,
      currentSubdivisionData,
      responsibleUsers,
      rules: {
        name: {
          required: true,
          message: 'Обязательное поле',
          trigger: 'blur'
        },
        description: {
          required: true,
          message: 'Обязательное поле',
          trigger: 'blur'
        }
        // head_id: {
        //   required: true,
        //   message: 'Обязательное поле',
        //   trigger: 'click'
        // },
      },
      getDepartments,
      handleValidateClick(e) {
        e.preventDefault()
        formRef.value?.validate((errors) => {
          if (!errors) {
            createDepartment(formValue.value).then((response) => {
              if (response.status === 200 && response.data.status === 'ok') {
                message.success('Успешно создано')
                showAddModal.value = false
                formValue.value = {
                  name: '',
                  description: '',
                  head_id: null
                }
                getDepartments()
              }
            })
          } else {
            console.log(errors)
          }
        })
      },
      submitCreateSubdivision(e) {
        e.preventDefault()
        formRefSubdivision.value?.validate((errors) => {
          if (!errors) {
            const payload = {
              ...formValueSubdivision.value,
              organization_department_id: currentDepartment.value.id
            }

            createSubdivision(payload).then((response) => {
              if (response.status === 200 && response.data.status === 'ok') {
                message.success('Успешно создано')

                formValueSubdivision.value = {
                  name: '',
                  description: '',
                  head_id: null
                }
                showAddSubdivisionModal.value = false
                getDepartments()
              }
            })
          } else {
            console.log(errors)
          }
        })
      },
      onOpenDepartment(department) {
        showDepartmentModal.value = true
        getDepartmentById(department.id).then((response) => {
          if (response.status === 200 && response?.data) {
            currentNameAtDepartment.value = department.name
            currentDescriptionAtDepartment.value = department.description
            currentHeadIdAtDepartment.value = Number(department.head_id)
            currentMembersAtDepartment.value = response.data.members?.map((user) => Number(user.id))
          }
        })
      },
      onOpenSubdivision(subdivision) {
        showSubdivisionModal.value = true

        getSubdivisionById(subdivision.id).then((response) => {
          if (response.status === 200 && response?.data) {
            currentSubdivisionData.name = response.data.name
            currentSubdivisionData.description = response.data.description
            currentSubdivisionData.head_id = Number(response.data.head_id)
            currentSubdivisionData.members = response.data.members?.map((user) => Number(user.id))
          }
        })
      },
      async saveDepartment() {
        const payload = {
          id: currentDepartment.value.id,
          name: currentNameAtDepartment.value,
          description: currentDescriptionAtDepartment.value,
          head_id: currentHeadIdAtDepartment.value,
          members: currentMembersAtDepartment.value
        }

        const response = await updateDepartment(payload)

        if (response.status === 200) {
          // showDepartmentModal.value = false
        }
      },
      async saveSubdivision() {
        const payload = {
          id: currentSubdivision.value.id,
          ...currentSubdivisionData
        }

        const response = await updateSubdivision(payload)

        if (response.status === 200) {
          // showSubdivisionModal.value = false
        }
      },
      onAddSubdivision() {
        showAddSubdivisionModal.value = true
      },
      async onDeleteDepartment(department) {
        const response = await deleteDepartment({ id: department.id })

        if (response.status === 200) {
          message.success('Подразделение удалено')
          getDepartments()
        }
      },
      async onDeleteSubdivision(id) {
        const response = await deleteSubdivision({ id })

        if (response.status === 200) {
          message.success('Отдел удален')
          // showDepartmentModal.value = false
          formValueSubdivision.value = {
            name: '',
            description: '',
            head_id: null
          }
          getDepartments()
        }
      },
      clearDepartmentModal() {
        clearDepartment()

        // currentNameAtDepartment.value = ''
        // currentDescriptionAtDepartment.value = ''
        // currentHeadIdAtDepartment.value = ''
        // currentMembersAtDepartment.value = []
      },
      clearSubdivisionModal() {
        // currentSubdivisionData = {
        //   name: '',
        //   description: '',
        //   head_id: '',
        //   members: []
        // }
        clearSubdivision()
      }
    }
  },
  computed: {
    responsibleUsersForSubdivision: (v) => {
      const array = v.responsibleUsers.filter((user) => v.currentMembersAtDepartment.includes(user.id))
      return array
    }
  },
  mounted() {
    this.getDepartments()
    this.getResponsibleUsers()
  },
  methods: {
    getResponsibleUsers() {
      this.$api.get('/responsible/get').then((resp) => {
        if (resp.status === 200) {
          this.responsibleUsers = resp.data.data.map((user) => ({
            id: Number(user.id),
            label: user.name,
            value: user.id
          }))
        }
      })
    }
  }
}
</script>

<style scoped>
.content-list {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.content-info .information-item {
  padding-bottom: 30px;
}

.med-item {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f2f2f2;
  box-shadow: 1px 1px 0px #cccccc;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}

.content-block {
  border-bottom: 1px solid #cccccc;
  padding: 24px 0;
}

.information-icon {
  flex: none;
}

.content-header {
  display: flex;
  justify-content: space-between;
}

.content-info {
  display: flex;
  flex-wrap: wrap;
}
</style>
