<template>
  <SpinLoader v-if="loading === true || $helper.isEmpty(checklist)" class="py-5" size="2xl" />
  <template v-else>
    <div class="page-head">
      <div class="header mr-5">
        <div class="header__top">
          <div class="header__text">№{{ checklistNumber }}</div>
          <div class="header__medtype">{{ checklist.medtype?.text }}</div>
        </div>
        <div class="header__subheader">
          {{ checklist.process?.text }}
        </div>
      </div>
      <div class="page-controls">
        <template v-if="showFinishButton">
          <NextButton v-if="checkStatus" type="done" text="ЗАВЕРШИТЬ ПРОВЕРКУ" @click-handler="closeCheklist" />
          <NextButton
            v-else
            type="done"
            text="ЗАВЕРШИТЬ ПРОВЕРКУ"
            class="inactive"
            title='Найдены требования со статусом "Не проверено"'
          />
        </template>
      </div>
    </div>
    <div :key="checklistNumber" class="content-block">
      <div class="row mb-3">
        <div class="col">
          <OrganizationInformationItem
            header="Аудитор"
            icon="white-avatar-icon"
            :text="checklist?.assurance_user?.name"
          />
          <div class="flex align-items-center">
            <OrganizationInformationItem
              v-if="!isEditPlanDate"
              header="Начало проверки"
              :icon="'white-clock-icon'"
              :text="planDateFormated"
            />

            <NDatePicker
              v-if="isEditPlanDate"
              :value="planDateValue"
              class="mb-4"
              format="dd.MM.yyyy"
              aria-required
              type="date"
              @change="changePlanDate"
            />
            <NextButton
              v-if="isEditPlanDate"
              text="Сохранить"
              type="none"
              color="white"
              class="fs6 p-1 mb-4 ml-3"
              @click-handler="onSavePlanDate"
            />
            <NextButton
              v-if="showEditPlanDate"
              type="edit"
              color="white"
              class="p-2 pl-3 mb-4 ml-3"
              @click-handler="toggleEdit"
            />
          </div>
        </div>
        <div class="col">
          <OrganizationInformationItem
            header="Вид проверки"
            icon="white-search-icon"
            :text="checklist.assurance?.text"
          />
          <OrganizationInformationItem header="Завершение" :icon="'white-clock-icon'" :text="stopDate" />
        </div>
        <div class="col">
          <OrganizationInformationItem header="Статус" icon="white-checkmark-icon" :text="checklist.status" />
          <OrganizationInformationItem header="Подписан" icon="white-clock-icon" :text="signDate" />
        </div>

        <div class="col">
          <OrganizationInformationItem
            v-if="checklist.subdivision"
            :text="checklist.subdivision"
            header="Подразделение/отдел"
            icon="white-plus-icon"
          />
          <OrganizationInformationItem header="Состав «Комиссии ВКК»" icon="white-avatar-icon" :text="vkkMembers" />
        </div>

        <div v-if="checklist.requirements" class="col mb-4">
          <DonutChart :values="chartValues" size="104" />
        </div>
      </div>
      <RequirementList
        v-if="loading === false && checklist?.requirements?.length"
        :type="checklist.status_id === 3 ? 'read' : 'edit'"
        :checklist="checklist"
        :items="checklist.requirements"
      />
    </div>
    <div v-if="checklist.status_id !== 3" class="button-block">
      <div v-if="showFinishButton">
        <NextButton v-if="checkStatus" type="done" text="ЗАВЕРШИТЬ ПРОВЕРКУ" @click-handler="closeCheklist" />
        <NextButton
          v-else
          type="done"
          text="ЗАВЕРШИТЬ ПРОВЕРКУ"
          class="inactive"
          title='Найдены требования со статусом "Не проверено"'
        />
      </div>
      <NPopconfirm :show-icon="false" positive-text="Да" @positive-click="onDeleteCheklist">
        <template #activator>
          <NextButton v-if="showDeleteButton" class="red ml-4" type="none" text="Удалить" />
        </template>
        Подтвердите действие
      </NPopconfirm>
    </div>
  </template>
</template>

<script>
import { mapGetters } from 'vuex'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'
import RequirementList from '@/components/widgets/custom-components/lists/requirementList'
import OrganizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import DonutChart from '@/components/widgets/custom-components/DonutChart/DonutChart'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import { NPopconfirm, NDatePicker } from 'naive-ui'

export default {
  name: 'CheckList',
  components: {
    NextButton,
    RequirementList,
    OrganizationInformationItem,
    DonutChart,
    SpinLoader,
    NPopconfirm,
    NDatePicker
  },
  data() {
    return {
      loading: false,
      isEditPlanDate: false,
      planDateValue: null
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    number: (vm) => vm.$router.currentRoute.value.params.number,
    showDeleteButton: (vm) => ['Проводится проверка', 'План'].includes(vm.checklist.status),
    checklistNumber: (vm) => vm.number.replace('_', '-'),
    checklist: (vm) => vm.$store.getters.checklist,
    vkkMembers: (vm) => vm.$store.getters.checklist.vkk_members.map((user) => user.name).join(', '),
    showEditPlanDate: (vm) => vm.checklist.status === 'План',
    startItem: (vm) => (!vm.$helper.isEmpty(vm.$store.getters.allAssurance) ? vm.$store.getters.allAssurance[0] : ''),
    stopDate: (vm) => vm.$helper.makeDate(vm.checklist.finish_date),
    planDateFormated: (vm) => vm.$helper.makeDate(vm.checklist.plan_date * 1000),
    signDate: (vm) => vm.$helper.makeDate(vm.checklist.sign_date),
    chartValues() {
      let requirementsCount = this.checklist.requirements?.length
      const order = ['success', 'failed', 'unchecked']
      const statusesCount = this.checklist.requirements.reduce(
        (p, c) => {
          if (c.status_id === 1) p.unchecked++
          if (c.status_id === 2) p.success++
          if (c.status_id === 3) p.failed++
          if (c.status_id === 4) requirementsCount--

          return p
        },
        { failed: 0, success: 0, unchecked: 0 }
      )

      return order.map((key) => {
        let result = 100
        if (statusesCount[key] === 0 && key !== 'success') {
          result = 0
        }
        if (requirementsCount !== 0) {
          result = Math.round((statusesCount[key] / requirementsCount) * 100)
        }
        return {
          value: result,
          color: { unchecked: '#cbcbcb', success: '#37c4a3', failed: '#d65959' }[key],
          bolder: key === 'success'
        }
      })
    },
    checkStatus() {
      const requirements = this.checklist.requirements

      if (!this.$helper.isEmpty(requirements)) {
        for (let i = 0; i < requirements.length; i++) {
          if (requirements[i].status_id === 1) return false
        }
      } else {
        return false
      }

      return true
    },
    showFinishButton: (vm) => vm.checklist.status_id === 1 && vm.currentUser?.id === vm.checklist?.assurance_user?.id
  },
  watch: {
    async number() {
      if (this.number === undefined) {
        return
      }
      await this.$store.dispatch('CHECKLIST_REFRESH_ALL_ASSURANCE')
      this.$store.dispatch('CHECKLIST_GET_BY_NUMBER', this.number).then((res) => {
        if (res.data.ok === 1) {
          if (res.data.data.status_id === 1) {
            this.$store.commit('checklist_change', res.data.data)
          } else {
            this.$store.commit('checklist_change', res.data.data)
          }
        }
      })
    },
    checklist() {
      if (this.planDateValue === null) {
        this.planDateValue = this.checklist.plan_date * 1000
      }
    }
  },
  async mounted() {
    this.loading = true
    await this.$store.dispatch('CHECKLIST_REFRESH_ALL_ASSURANCE')
    this.$store
      .dispatch('CHECKLIST_GET_BY_NUMBER', this.number)
      .then((res) => {
        this.loading = false
        if (res.data.ok === 1) {
          this.$store.commit('checklist_change', res.data.data)

          if (![1, 4].includes(res.data.data.status_id)) {
            this.$router.push('/checklist/report/' + res.data.data.id)
          }
        }
      })
      .catch(() => {
        this.loading = false
      })
  },
  methods: {
    changeAssurance(val) {
      this.$store.dispatch('CHECKLIST_CHANGE_ASSURANCE', {
        checklist_id: this.checklist.id,
        assurance_id: val.id
      })
    },
    closeCheklist() {
      this.$store.dispatch('CHECKLIST_FINISH', this.checklist.id).then((res) => {
        if (res.data.ok === 1) {
          this.$router.push('/checklist/report/' + this.checklist.id)
        }
      })
    },
    onDeleteCheklist() {
      this.$store.dispatch('CHECKLIST_DELETE', this.checklist.id).then((res) => {
        if (res?.data?.ok === 1) {
          this.$router.push('/audition')
        }
      })
    },
    toggleEdit() {
      this.isEditPlanDate = !this.isEditPlanDate
    },
    changePlanDate(date) {
      this.planDateValue = date
    },
    async onSavePlanDate() {
      await this.$store
        .dispatch('CHECKLIST_PLAN_DATE_EDIT', {
          id: this.checklist.id,
          plan_date: this.planDateValue / 1000
        })
        .then(() => {
          this.toggleEdit()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.button-block {
  display: flex;
}

.button-block {
  padding-top: 40px;
}

.checklist-info {
  display: flex;
  flex-wrap: wrap;
}

.content-block {
  background-color: white;
  margin-top: 30px;
  padding: 40px;
}

.information-item {
  width: auto;
  margin-right: 0;
  margin-bottom: 24px;
  box-sizing: border-box;
}

.page-head {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.page-controls {
  display: flex;
}

.header {
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  &__text {
    margin-right: 2rem;
    font-weight: 400;
    font-size: 36px;
    line-height: 49px;
    color: #333333;
  }

  &__medtype {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__subheader {
    font-weight: 400;
    font-size: 20px;
    color: #666666;
  }
}
</style>

<!-- АДАПТИВ -->
<style scoped lang="scss">
@media (max-width: 1200px) {
  .content-block {
    padding: 20px 15px;
  }

  .header__text {
    font-size: 24px;
  }
}

@media (max-width: 800px) {
  .header__top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .page-head {
    display: flex;
    flex-direction: column;
  }

  .header {
    margin-right: 0 !important;
    width: 100%;
    margin-bottom: 30px;
  }

  .button-block {
    flex-direction: column;

    .green-next-button {
      margin-bottom: 20px;
      margin-left: 0 !important;
      width: 270px;
    }
  }
}
</style>
