<template>
  <SpinLoader v-if="loading === true || $helper.isEmpty(checklist)" class="py-5" size="2xl" />
  <template v-else>
    <div class="page-head">
      <div class="header">
        <div class="header__top">
          <div class="header__text">№{{ checklistNumber }}</div>
          <div class="header__medtype">{{ checklist.medtype?.text }}</div>
        </div>
        <div class="header__subheader">
          {{ checklist.process?.text }}
        </div>
      </div>
      <div class="page-controls">
        <NextButton
          v-if="showChecklistSignButton"
          :loading="pause"
          color="white"
          type="redact"
          :disabled="!check"
          text="ПОДПИСАТЬ"
          @click="signChecklist"
        />
      </div>
    </div>
    <div class="content-block">
      <div class="row mb-3">
        <div class="col">
          <OrganizationInformationItem
            v-if="checklist.assurance_user"
            header="Аудитор"
            icon="white-avatar-icon"
            :text="checklist.assurance_user.name"
          />

          <OrganizationInformationItem header="Начало проверки" :icon="'white-clock-icon'" :text="planDate" />
        </div>
        <div class="col">
          <OrganizationInformationItem
            header="Вид проверки"
            icon="white-search-icon"
            :text="checklist.assurance?.text"
          />

          <div class="flex align-items-center">
            <OrganizationInformationItem
              v-if="!isEditFinishDate"
              header="Завершение"
              :icon="'white-clock-icon'"
              :text="stopDate"
            />
            <!-- <NDatePicker v-if="isEditFinishDate" :value="finishDateValue" class="mb-4" format="dd.MM.yyyy" aria-required type="date" @change="changeFinishDate" />
            <NextButton v-if="isEditFinishDate" text="Сохранить" type="none" color="white" class="fs6 p-1 mb-4 ml-3" @click-handler="onSaveFinishDate" />
            <NextButton v-if="showEditPlanDate" :type="isEditFinishDate ? 'close' : 'edit'" color="white" class="p-2 pl-3 mb-4 ml-3" @click-handler="toggleEdit" /> -->
          </div>
        </div>
        <div class="col">
          <OrganizationInformationItem header="Статус" icon="white-checkmark-icon" :text="checklist.status" />
          <OrganizationInformationItem header="Подписан" icon="white-clock-icon" :text="signDate" />
        </div>

        <!-- <div v-if="checklist.subdivision" class="col">
          <OrganizationInformationItem
            header="Подразделение/отдел"
            icon="white-plus-icon"
            :text="checklist.subdivision"
          />
        </div> -->

        <div class="col">
          <OrganizationInformationItem
            v-if="checklist.subdivision"
            :text="checklist.subdivision"
            header="Подразделение/отдел"
            icon="white-plus-icon"
          />
          <OrganizationInformationItem header="Состав «Комиссии ВКК»" icon="white-avatar-icon" :text="vkkMembers" />
        </div>

        <div v-if="checklist.requirements" class="col mb-4">
          <DonutChart :values="chartValues" size="104" />
        </div>
        <div class="col mb-4" style="max-width: 228px">
          <NextButton
            v-if="checklist.finish_date"
            class="w-100 h-11 mb-3"
            type="file"
            text="ОТЧЁТ"
            :loading="isGenReport"
            :disabled="isGenProtocol"
            @click="generateReport"
          />
          <NextButton
            v-if="checklist.status_id === 3"
            class="w-100 h-11"
            type="file"
            text="ПРОТОКОЛ ВКК"
            :loading="isGenProtocol"
            :disabled="isGenReport"
            @click="generateProtocol"
          />
        </div>
      </div>
      <RequirementList :type="checklistType" :items="checklist.requirements" :checklist="checklist" />
    </div>
    <div class="button-block">
      <NextButton
        v-if="checklist.finish_date"
        class="mr-3"
        type="file"
        text="ОТЧЁТ"
        :loading="isGenReport"
        :disabled="isGenProtocol"
        @click="generateReport"
      />
      <NextButton
        v-if="checklist.status_id === 3"
        class="mr-3"
        type="file"
        text="ПРОТОКОЛ ВКК"
        :loading="isGenProtocol"
        :disabled="isGenReport"
        @click="generateProtocol"
      />
      <NextButton
        v-if="showChecklistSignButton"
        :loading="pause"
        :disabled="!check"
        color="white"
        type="redact"
        text="ПОДПИСАТЬ"
        @click="signChecklist"
      />
    </div>
  </template>
</template>

<script>
import { NDatePicker } from 'naive-ui'
import Pdf from '@/utils/pdf'
import { mapGetters } from 'vuex'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'
import OrganizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import RequirementList from '@/components/widgets/custom-components/lists/requirementList'
import DonutChart from '@/components/widgets/custom-components/DonutChart/DonutChart'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'

export default {
  name: 'CheckListReportPage',
  components: {
    NextButton,
    OrganizationInformationItem,
    RequirementList,
    DonutChart,
    SpinLoader,
    NDatePicker
  },
  data() {
    return {
      loading: false,
      isGenReport: false,
      isGenProtocol: false,
      users: null,
      pause: false,
      isEditFinishDate: false,
      finishDateValue: null
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    check() {
      for (let i = 0; i < this.checklist.requirements.length; i++) {
        if (this.checklist.requirements[i].status_id === 1) {
          return false
        }
      }
      return true
    },
    checklist: (vm) => vm.$store.getters.checklist,
    vkkMembers: (vm) => vm.$store.getters.checklist.vkk_members.map((user) => user.name).join(', '),
    checklistNumber: (vm) => vm.checklist.number?.replace('_', '-'),
    checklistType: (vm) => (vm.checklist.status_id === 3 ? 'signed' : 'finished'),
    showEditPlanDate: (vm) => vm.checklist.status === 'Проверка завершена',
    stopDate: (vm) => vm.$helper.makeDate(vm.checklist.finish_date),
    planDate: (vm) => vm.$helper.makeDate(vm.checklist.plan_date * 1000 || vm.checklist.create_date),
    signDate: (vm) => vm.$helper.makeDate(vm.checklist.sign_date),
    chartValues() {
      let requirementsCount = this.checklist.requirements?.length
      const order = ['success', 'failed', 'unchecked']
      const statusesCount = this.checklist.requirements.reduce(
        (p, c) => {
          if (c.status_id === 1) p.unchecked++
          if (c.status_id === 2) p.success++
          if (c.status_id === 3) p.failed++
          if (c.status_id === 4) requirementsCount--

          return p
        },
        { failed: 0, success: 0, unchecked: 0 }
      )

      return order.map((key) => {
        let result = 100

        if (statusesCount[key] === 0 && key !== 'success') {
          result = 0
        }

        if (requirementsCount !== 0) {
          result = Math.round((statusesCount[key] / requirementsCount) * 100)
        }

        return {
          value: result,
          color: { unchecked: '#cbcbcb', success: '#37c4a3', failed: '#d65959' }[key],
          bolder: key === 'success'
        }
      })
    },
    userIsSuperAdmin: () => JSON.parse(localStorage.user).role_id === 1,
    showChecklistSignButton: (vm) => vm.checklist.status_id === 2 && (vm.currentUser?.vkk === 1 || vm.userIsSuperAdmin)
  },
  watch: {
    checklist() {
      if (this.finishDateValue === null) {
        this.finishDateValue = this.checklist.finish_date
      }
    }
  },
  async mounted() {
    this.loading = true
    await this.$store.dispatch('CHECKLIST_UPDATE_CURRENT_CHECKLIST', this.$router.currentRoute.value.params.id)
    this.loading = false
    const res = await this.getResponsibleUsers()
    this.users = res
  },
  methods: {
    signChecklist() {
      if (!this.check) {
        return
      }
      this.pause = true
      this.$store.dispatch('CHECKLIST_SIGN', this.checklist).then((res) => {
        if (res.data.ok === 1) {
          this.$store.dispatch('CHECKLIST_UPDATE_CURRENT_CHECKLIST', this.$router.currentRoute.value.params.id)
        }
      })
    },
    async getResponsibleUsers() {
      try {
        const res = await this.$api.get('responsible/get')

        if (res.data.ok === 1) {
          return res.data.data
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getSnapshot() {
      try {
        const res = await this.$api.get(`checklists/get-snapshot?id=${this.checklist.id}`)

        if (res.data.ok === 1) {
          return JSON.parse(res.data.data.data)
        }
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async generateProtocol() {
      this.isGenProtocol = true

      const {
        data: { data: checklist }
      } = await this.$api.get(`checklists/get-proto?number=${this.checklist.number}`)

      const doc = new Pdf()

      const tableData = checklist.requirements?.filter((item) => item.status_id === 3 || item?.action?.type_id === 2)

      await doc.generate({
        template: {
          name: 'CheckListProtocolTemplate',
          props: {
            sourceData: {
              ...checklist,
              responsibleUsers: checklist?.vkk_members || []
            },
            tableData,
            pageNumber: 1,
            initialEmpty: Boolean(tableData.length === 0)
          }
        }
      })

      this.isGenProtocol = false
    },
    async generateReport() {
      this.isGenReport = true

      let reportData = await this.getSnapshot()
      const doc = new Pdf()

      if (!reportData) {
        reportData = this.checklist
      }

      await doc.generate({
        template: {
          name: 'CheckListReportTemplate',
          props: {
            sourceData: reportData,
            tableData: reportData.requirements?.filter((item) => item.status_id === 3 || item?.action?.type_id === 2),
            pageNumber: 1
          }
        }
      })

      this.isGenReport = false
    },
    toggleEdit() {
      this.isEditFinishDate = !this.isEditFinishDate
    },
    changeFinishDate(date) {
      this.finishDateValue = date
    },
    async onSaveFinishDate() {
      await this.$store
        .dispatch('CHECKLIST_FINISH_DATE_EDIT', {
          id: this.checklist.id,
          finish_date: this.finishDateValue
        })
        .then(() => {
          this.toggleEdit()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.button-block {
  display: flex;
}

.passed-text {
  font-size: 15px;
  line-height: 22px;
  color: #333333;
}

.button-block {
  padding-top: 40px;
}

.checklist-info {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 60%;
  width: 60%;
}

.content-block {
  background-color: white;
  margin-top: 30px;
  padding: 40px;
}

.information-item {
  width: auto;
  margin-right: 0;
  margin-bottom: 24px;
  box-sizing: border-box;
}

.page-head {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.page-controls {
  display: flex;
}

.header {
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  &__text {
    margin-right: 2rem;
    font-weight: 400;
    font-size: 36px;
    line-height: 49px;
    color: #333333;
  }

  &__medtype {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__subheader {
    font-weight: 400;
    font-size: 20px;
    color: #666666;
  }
}
</style>

<!-- АДАПТИВ -->
<style scoped lang="scss">
@media (max-width: 1200px) {
  .content-block {
    padding: 20px 15px;
  }

  .header__text {
    font-size: 24px;
  }
}

@media (max-width: 800px) {
  .header__top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .page-head {
    display: flex;
    flex-direction: column;
  }

  .header {
    margin-right: 0 !important;
    width: 100%;
    margin-bottom: 30px;
  }

  .button-block {
    flex-direction: column;

    .green-next-button {
      margin-bottom: 20px;
    }
  }
}
</style>
