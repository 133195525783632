<template>
  <div class="donut-chart" :style="{ width: size + 'px' }">
    <svg :height="size" :width="size" viewBox="0 0 140 140">
      <circle
        cx="70"
        cy="70"
        r="64"
        fill="transparent"
        stroke-width="12"
        stroke="#f5f5f5"
      />
      <circle
        v-for="(item, i) in values"
        :key="`circle_${i}`"
        cx="70"
        cy="70"
        r="64"
        fill="transparent"
        stroke-width="12"
        :stroke-dasharray="getDashArray(item.value)"
        :stroke-dashoffset="getDashOffset(item.value, i)"
        :stroke="item.color"
      />
    </svg>
    <div class="donut-chart__values" :style="`transform: scale(${Number(size) / 140})`">
      <div>
        <div
          v-for="(item, i) in sortedValues"
          :key="`value_${i}`"
          class="donut-chart__value"
          :class="{ bolder: item.bolder }"
        >
          <div class="donut-chart__dot" :style="`background: ${item.color}`" />
          <div class="donut-chart__text">{{ item.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DonutChart',
  props: {
    values: {
      type: Array,
      required: true
    },
    size: {
      type: [Number, String],
      default: 140
    }
  },
  computed: {
    sortedValues() {
      const fromIndex = this.values.findIndex((item) => item.bolder)
      const toIndex = Math.round(this.values.length / 2) - 1

      if (fromIndex === -1) return this.values

      return this.$helper.arrayMove(this.values, fromIndex, toIndex)
    },
    circum: () => 2 * Math.PI * 64
  },
  methods: {
    getDashArray(val) {
      return `${this.getPercent(val)} ${this.circum - this.getPercent(val)}`
    },
    getDashOffset(val, idx) {
      const offset = this.getPercent(25)
      const prevOffsets = this.values.reduce((p, c, i) => {
        return i < idx ? p + this.getPercent(c.value) : p
      }, 0)

      return this.circum - prevOffsets + offset
    },
    getPercent(val) {
      return (val / 100) * this.circum
    }
  }
}
</script>

<style lang="scss" scoped>
.donut-chart {
  position: relative;
  display: block;

  &__values {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
  }

  &__value {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 22px;
    font-weight: bold;
    line-height: 1;

    &.bolder {
      font-size: 1.25em;
    }
  }

  &__dot {
    position: absolute;
    top: calc(50% - 3px);
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  &__text {
    width: 36px;
    text-align: center;
  }
}
</style>
