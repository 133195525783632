<template>
  <div class="login">
    <form class="login-form">
      <div class="login-form__header">
        <div class="background__lines" />
        <div class="login-form__header_text">Вход в систему</div>
      </div>
      <div v-if="errorMessage" class="message pt-3 alert alert-warning" role="alert" style="padding-left: 40px">
        {{ errorMessage }}
      </div>
      <div class="input-block">
        <inputWithHeader
          class="custom-input"
          placeholder="Введите ваш Email"
          required
          name="login"
          header="Ваш E-mail"
          @enter-handler="login"
          @input-handler="emailInput"
        />
        <inputWithHeader
          class="custom-input"
          placeholder="Введите ваш пароль"
          required
          name="password"
          header="Ваш пароль"
          type="password"
          @enter-handler="login"
          @input-handler="passwordInput"
        />
      </div>
    </form>
    <div class="login-buttons">
      <nextButton :class="{ inactive: email === '' || password === '' }" text="ВОЙТИ" @click-handler="login" />
      <nextButton v-if="showRegisterButton" :color="'white'" text="РЕГИСТРАЦИЯ" @click-handler="registration" />
    </div>
    <router-link to="/restore" class="password-restore">ЗАБЫЛИ ПАРОЛЬ?</router-link>
  </div>
</template>

<script>
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import whiteLogo from '@/components/widgets/simple-components/logo/whiteLogo'
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'

export default {
  name: 'LoginForm',
  components: {
    nextButton,
    whiteLogo,
    inputWithHeader
  },
  data() {
    return {
      email: '',
      password: '',
      errorMessage: ''
    }
  },
  computed: {
    showRegisterButton: () => location.host !== 'dev-front.vkkmed.ru'
  },
  methods: {
    passwordInput(val) {
      this.password = val
    },
    emailInput(val) {
      this.email = val
    },
    registration() {
      this.$router.push('/registration')
    },
    login() {
      if (this.email === '' || this.password === '') {
        return
      }

      this.loading = true
      const payload = { email: this.email, password: this.password }
      this.$store.dispatch('auth/login', payload).then(async (res) => {
        if (res.access_token) {
          localStorage.setItem('access_token', res.access_token)
          localStorage.setItem('refresh_token', res.refresh_token)

          await this.$store.dispatch('USER_GET')
          await this.$router.push('/statistic')
        } else {
          this.errorMessage = res.message
        }
      })
    }
  }
}
</script>

<style scoped>
.password-restore {
  color: #1cc8ab;
  font-weight: 400;
  font-size: 15px;
  margin-left: 40px;
  text-decoration: underline dotted #1cc8ab;
}
.input__header {
  font-size: 14px;
  line-height: 19px;
  color: #666666;
}
.login-form__header_text {
  position: relative;
  font-size: 23px;
}
.login-buttons {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
.login-form__header {
  position: relative;
  padding-left: 40px;
  padding-top: 35px;
  padding-bottom: 20px;
}

.login {
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
  border-radius: 3px;
  padding-bottom: 55px;
}
.input-block {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.custom-input {
  padding-bottom: 21px;
}
.background__lines {
  background: url('@/assets/images/background_lines.png');
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 0.35;
  background-position: top;
  left: 0;
  z-index: 0;
  top: 0;
}
</style>
