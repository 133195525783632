<template>
  <div class="container">
    <div class="content">
      <div class="informations">{{ message }}</div>
      <div class="link">
        <div><router-link :to="'/login'" class="enter-text">Вход</router-link></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistrationConfirm",
  data() {
    return {
      status: 0,
      registration_key: '',
      message: 'Подождите, Ваш аккаунт подтверждается',
      confirmed: false,
    }
  },
  mounted() {
    this.registration_key = this.$router.currentRoute.value?.query?.key;
    this.$store.dispatch('auth/registerConfirm', this.registration_key).then(res => {
      this.confirmed = res.ok;
      if(res.ok === 1) {
        this.message = 'Ваш аккаунт подтверждён. Пожалуйста, войдите в аккаунт используя Ваши логин и пароль';
        this.status = 1;
      } else {
        this.message = res.message;
      }
    })
  },
  methods: {

  }
}
</script>

<style scoped>
.enter-text {
  font-size: 26px;
}
 .informations {
   font-size: 26px;
   text-align: center;
 }
 .link {
   padding-top: 30px;
   display: flex;
   justify-content: center;
   font-size: 16px;
 }
 .content {
   background-color: white;
   border-radius: 8px;
   padding: 20px;
 }
  .container {
    z-index: 9;
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>